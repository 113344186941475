import { PromiseExtended, liveQuery } from "dexie";
import { Observable, Subject } from "rxjs";

/**
 * Cette requête liveQuery est réévaluée à chaque fois que l'une des dépendances change.
 * @param query La requête liveQuery à réévaluer
 * @param dependances Les dépendances de la requête (Observable)
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function liveQueryReactif<Q>(query: () => PromiseExtended<Q>, dependances: any[]): Observable<Q> {
    const lq = liveQuery(query);
    const dependancesChangees = new Subject<void>();
    dependances.forEach(dependance => {
        dependance.subscribe(() => dependancesChangees.next())
    });

    return new Observable<Q>(_ => {
        dependancesChangees.pipe().subscribe(() => {
            lq.subscribe(resultats => _.next(resultats));
        });

        lq.subscribe(resultats => _.next(resultats));
    });
}