import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { LoginService } from './login.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PartitionnementModule } from 'src/app/tools/directives/partitionnement/partitionnement.module';
import { MotDePasseOublieService } from '../motdepasse-oublie/motdepasse-oublie.service';

@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PartitionnementModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public formulaire = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  public numeroAssistance: string = "tel:+33339310071";
  public utilisateur: string | undefined;

  public chargement: boolean = false;
  public loginEchoue: boolean = false;
  public appareilAutorise: boolean = true;

  constructor(
    private router: Router,
    private dbService: DbService,
    private loginService: LoginService,
    private activeModal: NgbActiveModal,
    private motDePasseOublieService: MotDePasseOublieService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loginService.authentificationEnCours = true;

    await this.dbService.dexie.parametres_application.get(ParametreApplicationId.UTILISATEUR)
      .then((parametre?: ParametreApplication<unknown>) => {
        if (!parametre) return;
        this.utilisateur = parametre.valeur as string;
        this.formulaire.controls.username.setValue(this.utilisateur ?? "");
      });
  }

  public async passerLogin(): Promise<void> {
    this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.UTILISATEUR_CONNECTE, valeur: false as never });
    this.loginService.authentificationEnCours = false;
    this.activeModal.close();
    this.loginService.supprimerToken();
    this.router.navigate([""]);
  }

  public async login() {
    this.loginEchoue = false;
    this.appareilAutorise = true;
    this.chargement = true;
    try {
      await this.loginService.login({
        // username: this.formulaire.get('username')?.value as string,
        username: this.formulaire.controls.username.value ?? "",
        password: this.formulaire.controls.password.value ?? "",
        userAgent: navigator.userAgent,
      });
      this.activeModal.close();
      this.chargement = false;
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      if (error instanceof HttpErrorResponse && error.status === 400) {
        // Si c'est une HttpErrorResponse avec un statut 400, cela indique un problème d'autorisation sur l'appareil
        this.appareilAutorise = false;
      } else {
        // Sinon, c'est probablement une erreur de connexion due à un mauvais nom d'utilisateur ou mot de passe
        this.loginEchoue = true;
      }
      this.chargement = false;
    }
  }

  public motDePasseOublie(): void {
    this.motDePasseOublieService.ouvrirModale();
  }

}
