import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kalTms',
  standalone: true
})
export class KalTimestampPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {

  }

  transform(value: Date | string, arg1: string): string | null {
    if (!value){
      return "";
    }
    const datePipe = new DatePipe(this.locale)
    switch (arg1) {
      case "date":
        return datePipe.transform(value, "dd/MM/y", "fr-FR" );
      case "date2":
        return datePipe.transform(value, "dd/MM/yy", "fr-FR" );
      case "dateCourt":
        return datePipe.transform(value, "dd/MM/y", "fr-FR" );
      case "dateLong":
        return datePipe.transform(value, "EEE dd/MM/yy", "fr-FR" );

      case "tms":
        return datePipe.transform(value, "dd MMMM y à HH:mm:ss", "fr-FR" );

      case "tmsPrecis":
        return datePipe.transform(value, "dd/MM/y à HH:mm:ss", "fr-FR" );

      case "tmsMoyen":
        return datePipe.transform(value, "le dd/MM/y à HH'h'mm", "fr-FR" );

      // TmsCourt avec le jour -> lun, mar...
      case "tmsCourt":
        return datePipe.transform(value, "EEE dd/MM/yy à HH:mm", "fr-FR" );

      // TmsCourt sans le jour -> lun, mard...
      case "tmsCourtNoJ":
        return datePipe.transform(value, "dd/MM/yy à HH:mm", "fr-FR" );

      case "tmsheure" :
        return datePipe.transform(value, "à HH:mm", "fr-FR" );

      case "tmsheureSansA" :
        return datePipe.transform(value, "HH:mm", "fr-FR" );

      case "tmsEntier" :
        return datePipe.transform(value, "dd MMMM yyyy à HH:mm");

      case "tmsAnneeEntier" :
        return datePipe.transform(value, "dd MMMM yyyy");

      case "jourMois" :
        return datePipe.transform(value, "EEEE dd MMMM", "fr-FR");

      case "jourMoisAnnee" :
        return datePipe.transform(value, "EEEE dd MMMM yyyy", "fr-FR");

      default:
        break;
    }
    return datePipe.transform(value, "EEEE, dd MMMM y à HH:mm:ss", "fr" );

  }

}
