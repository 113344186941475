import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { MotDePasseOublieService } from './motdepasse-oublie.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { PartitionnementModule } from 'src/app/tools/directives/partitionnement/partitionnement.module';

@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PartitionnementModule],
  templateUrl: './motdepasse-oublie.component.html',
  styleUrls: ['./motdepasse-oublie.component.scss'],
})
export class MotDePasseOublieComponent implements OnInit {
  public finalisation: boolean = false;
  public cle: string = '';

  public formulaireDemande = new FormGroup({
    username: new FormControl('', [Validators.required]),
  });
  public formulaireFinalisation = new FormGroup({
    newPassword: new FormControl('', [Validators.required]),
    newPasswordAgain: new FormControl('', [Validators.required]),
  });

  public utilisateur: string | undefined;

  public chargement: boolean = false;
  public cleValide: boolean | null = null;
  public erreur: boolean = false;
  public erreurCorrespondance: boolean = false;

  constructor(
    private router: Router,
    private dbService: DbService,
    private motDePasseOublieService: MotDePasseOublieService,
    private activeModal: NgbActiveModal
  ) {}

  async ngOnInit(): Promise<void> {
    this.motDePasseOublieService.modaleOuverte = true;

    if (this.finalisation) {
      this.cleValide = await this.motDePasseOublieService.verifierReinitialisation(this.cle);
    } else {
      await this.dbService.dexie.parametres_application
        .get(ParametreApplicationId.UTILISATEUR)
        .then((parametre?: ParametreApplication<unknown>) => {
          if (!parametre) return;
          this.utilisateur = parametre.valeur as string;
          this.formulaireDemande.controls.username.setValue(this.utilisateur ?? '');
        });
    }
  }

  public async passerLogin(): Promise<void> {
    this.dbService.dexie.parametres_application.put({
      id: ParametreApplicationId.UTILISATEUR_CONNECTE,
      valeur: false as never,
    });
    this.activeModal.close();
    this.router.navigate(['']);
  }

  public async demanderReinitialisation() {
    this.chargement = true;
    try {
      await this.motDePasseOublieService.demanderReinitialisation(this.formulaireDemande.controls.username.value ?? '');
      this.chargement = false;
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialiser le mot de passe :', error);
      this.chargement = false;
      this.erreur = true;
    }
  }

  public async finaliserReinitialisation() {
    if (
      this.formulaireFinalisation.controls.newPassword.value !==
      this.formulaireFinalisation.controls.newPasswordAgain.value
    ) {
      this.erreurCorrespondance = true;
      return;
    }

    this.chargement = true;
    try {
      await this.motDePasseOublieService.finaliserReinitialisation({
        cle: this.cle,
        nouveauMotDePasse: this.formulaireFinalisation.controls.newPassword.value ?? '',
      });
      this.chargement = false;
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialiser le mot de passe :', error);
      this.chargement = false;
      this.erreur = true;
    }
  }

  public demanderANouveau() {
    this.finalisation = false;
  }
}
