import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VerifIPhoneService {

  estIPhone(): boolean {
    return /iPhone|iPod|iPad/.test(navigator.userAgent);
  }

  estIPhoneDixPlus(): boolean {
    const userAgent = navigator.userAgent;
    const iPhoneVersionMatch = userAgent.match(/iPhone\sOS\s(\d+)/);
    if (iPhoneVersionMatch && iPhoneVersionMatch.length > 1) {
      const iPhoneVersion = parseInt(iPhoneVersionMatch[1], 10);
      return iPhoneVersion > 10;
    }
    return false;
  }

}
