import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalService: NgbModal,
    private config: NgbModalConfig,
  ) {
    config.backdrop = 'static';
    config.centered = true;
    config.keyboard = false;
    config.animation = true;
    config.scrollable = true;
  }

  public open(content: unknown, options?: NgbModalOptions): NgbModalRef {
    return this.modalService.open(content, options);
  }
}
