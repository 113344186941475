import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SynchronisationContactClient } from 'src/app/services/synchronisation/synchronisation';
import { DbService } from 'src/app/services/db/db.service';
import { Router } from '@angular/router';
import { EnvironnementApplicationService } from 'src/app/services/environnement-application/environnement-application.service';
import { ParametreApplicationId } from 'src/app/services/db/parametre-application';
import { ContactsService } from 'src/app/services/contacts/contacts.service';

@Component({
  selector: 'app-anniversaires-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './anniversaires-modal.component.html',
  styleUrls: ['./anniversaires-modal.component.scss']
})
export class AnniversairesModalComponent implements OnInit{

  public contacts: SynchronisationContactClient[] = [];

  public nbJoursAnniversaire: number = 7;

  private aujourdhui: Date = new Date();
  public chargement: boolean = true;

  constructor(
    private router: Router,
    private dbService: DbService,
    private activeModal: NgbActiveModal,
    private environnementApplicationService: EnvironnementApplicationService,
    public contactsService: ContactsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.chargement = true;

    const aujourdhui = new Date(this.aujourdhui);
    aujourdhui.setHours(0, 0, 0, 0);

    const dateFin = new Date(aujourdhui);
    dateFin.setDate(dateFin.getDate() + this.nbJoursAnniversaire);

    const tousContacts = await this.dbService.dexie.synchronisation_contacts_clients.toArray();

    this.contacts = tousContacts
      .filter((contact) => {
        if (!contact.anniversaire) return false;

        const dateAnniversaire = new Date(contact.anniversaire);
        dateAnniversaire.setHours(0, 0, 0, 0);

        dateAnniversaire.setFullYear(aujourdhui.getFullYear());

        if (dateAnniversaire < aujourdhui) {
          dateAnniversaire.setFullYear(aujourdhui.getFullYear() + 1);
        }

        return dateAnniversaire >= aujourdhui && dateAnniversaire <= dateFin;
      })
      .sort((a, b) => {
        const dateA = new Date(a.anniversaire);
        dateA.setHours(0, 0, 0, 0);
        dateA.setFullYear(aujourdhui.getFullYear());
        if (dateA < aujourdhui) {
          dateA.setFullYear(aujourdhui.getFullYear() + 1);
        }

        const dateB = new Date(b.anniversaire);
        dateB.setHours(0, 0, 0, 0);
        dateB.setFullYear(aujourdhui.getFullYear());
        if (dateB < aujourdhui) {
          dateB.setFullYear(aujourdhui.getFullYear() + 1);
        }

        return dateA.getTime() - dateB.getTime();
      });

      this.chargement = false;
  }


  public fermer(): void {
    this.activeModal.dismiss();
  }

  public async saisirPanier(tiers: string): Promise<void> {
    if (!tiers) return;

    const parametreClientActif: string | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.CLIENT_ACTIF) ?? "";
    if (parametreClientActif !== tiers) {
      localStorage.setItem('avertissementMontant', "");
    }

    try {
      await this.dbService.dexie.transaction('rw', this.dbService.dexie.parametres_application, async () => {
        await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.CLIENT_ACTIF, valeur: tiers as never });
        await this.dbService.dexie.parametres_application.delete(ParametreApplicationId.PANIER_ACTIF);
      });
    }
    finally {
      this.fermer();
      this.router.navigate(['crm', 'produits'], {
        queryParams: {
          utiliserReferencementClient: true
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  public versClient(tiers: string) {
    this.fermer();
    this.router.navigate(["/crm/clients", tiers, "contacts"]);
  }
}
