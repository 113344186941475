import { EventEmitter, Injectable } from '@angular/core';
import { SynchronisationArticle, SynchronisationClient, SynchronisationRemisesClient } from '../synchronisation/synchronisation';
import { DbService } from '../db/db.service';
import { ReplaySubject } from 'rxjs';
import { SynchronisationRemiseClientEtendue } from 'src/app/modules/fragments/remises/remises.component';

@Injectable({
  providedIn: 'root'
})
export class RemisesService {

  public listeRemises: SynchronisationRemiseClientEtendue[] = [];
  public listeRemisesQuantite: SynchronisationRemiseClientEtendue[] = [];
  public listeRistournes: SynchronisationRemiseClientEtendue[] = [];
  public listeOdrds: SynchronisationRemiseClientEtendue[] = [];

  public listeRemisesMap: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
  public listeRemisesQuantiteMap: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
  public listeRistournesMap: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
  public listeOdrdsMap: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();

  public listeRemisesChange: ReplaySubject<SynchronisationRemiseClientEtendue[]> = new ReplaySubject(1);
  public listeRemisesQuantiteChange: ReplaySubject<SynchronisationRemiseClientEtendue[]> = new ReplaySubject(1);
  public listeRistournesChange: ReplaySubject<SynchronisationRemiseClientEtendue[]> = new ReplaySubject(1);
  public listeOdrdsChange: ReplaySubject<SynchronisationRemiseClientEtendue[]> = new ReplaySubject(1);

  private remisesCharges: EventEmitter<void> = new EventEmitter<void>();
  public _remises: SynchronisationRemiseClientEtendue[] = [];

  public famillesStat1: string[] = [];
  public famillesStat2: string[] = [];
  public famillesStat3: string[] = [];

  public articles: SynchronisationArticle[] = [];

  constructor(
    private dbService: DbService,
  ) {
    this.init();
  }

  public get remises(): SynchronisationRemiseClientEtendue[] {
    return this._remises;
  }

  public set remises(value: SynchronisationRemiseClientEtendue[]) {
    this._remises = value;
    this.remisesCharges.emit();
  }

  private async init(typeRemise?: string): Promise<void> {
    let chargementRemisesClient;
    if (!typeRemise || typeRemise === "R") {
      chargementRemisesClient = this.dbService.dexie.synchronisation_remises.toArray()
        .then((remises: SynchronisationRemisesClient[]) => {
          const remisesEtendues: SynchronisationRemiseClientEtendue[] = remises.map(remise => ({ ...remise, estPlie: false }));

          this.listeRemises = remisesEtendues;
          const mapArticles: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
          for (const remise of remisesEtendues) {
            mapArticles.set(remise.articleRef, remise);
          }
          this.listeRemisesMap = mapArticles;
          this.listeRemisesChange.next(remisesEtendues);
        });
    }

    let chargementRemisesQuantite;
    if (!typeRemise || typeRemise === "Q") {
      chargementRemisesQuantite = this.dbService.dexie.synchronisation_remises_quantite.toArray()
        .then((remises: SynchronisationRemisesClient[]) => {
          const remisesEtendues: SynchronisationRemiseClientEtendue[] = remises.map(remise => ({ ...remise, estPlie: false }));

          this.listeRemisesQuantite = remisesEtendues;
          const mapArticles: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
          for (const remise of remisesEtendues) {
            mapArticles.set(remise.articleRef, remise);
          }
          this.listeRemisesQuantiteMap = mapArticles;
          this.listeRemisesQuantiteChange.next(remisesEtendues);
        });

    }

    let chargementRemisesRistournes;
    if (!typeRemise || typeRemise === "I") {
      chargementRemisesRistournes = this.dbService.dexie.synchronisation_ristournes.toArray()
        .then((remises: SynchronisationRemisesClient[]) => {
          const remisesEtendues: SynchronisationRemiseClientEtendue[] = remises.map(remise => ({ ...remise, estPlie: false }));

          this.listeRistournes = remisesEtendues;
          const mapArticles: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
          for (const remise of remisesEtendues) {
            mapArticles.set(remise.articleRef, remise);
          }
          this.listeRistournesMap = mapArticles;
          this.listeRistournesChange.next(remisesEtendues);
        });
    }

    let chargementRemisesOdrds;
    if (!typeRemise || typeRemise === "O") {
      chargementRemisesOdrds = this.dbService.dexie.synchronisation_odrd.toArray()
        .then((remises: SynchronisationRemisesClient[]) => {
          const remisesEtendues: SynchronisationRemiseClientEtendue[] = remises.map(remise => ({ ...remise, estPlie: false }));

          this.listeOdrds = remisesEtendues;
          const mapArticles: Map<string, SynchronisationRemiseClientEtendue> = new Map<string, SynchronisationRemiseClientEtendue>();
          for (const remise of remisesEtendues) {
            mapArticles.set(remise.articleRef, remise);
          }
          this.listeOdrdsMap = mapArticles;
          this.listeOdrdsChange.next(remisesEtendues);
        });
    }

    await Promise.all([chargementRemisesClient, chargementRemisesQuantite, chargementRemisesRistournes, chargementRemisesOdrds]);
  }

  public async obtenirRemisesDepuisClient(client: SynchronisationClient, typeRemise: string): Promise<SynchronisationRemiseClientEtendue[]> {

    await this.init(typeRemise);

    let listeRemisesCopie;
    if (typeRemise === "R") {
      listeRemisesCopie = [...this.listeRemises];
    }
    else if (typeRemise === "Q") {
      listeRemisesCopie = [...this.listeRemisesQuantite];
    }
    else if (typeRemise === "I") {
      listeRemisesCopie = [...this.listeRistournes];
    }
    else if (typeRemise === "O") {
      listeRemisesCopie = [...this.listeOdrds];
    }
    const aujourdhui: Date = new Date();
    aujourdhui.setHours(0, 0, 0, 0);

    let listeRemisesEnvoi = listeRemisesCopie?.filter((remise) => {
      const dateFin: Date = new Date(remise.dateFin);
      dateFin.setHours(0, 0, 0, 0);
      const dateEffet: Date = new Date(remise.dateEffet);
      dateEffet.setHours(0, 0, 0, 0);

      return (
        ((client.tiers && remise.tiers === client.tiers) ||
          (client.codeTarif && remise.codeTarif === client.codeTarif) ||
          (remise.familleClient && remise.familleClient === client.familleClient) ||
          (client.classeRemiseClient && remise.classeRemiseClient === client.classeRemiseClient)) &&
        (remise.dateEffet === null || dateEffet <= aujourdhui) &&
        (remise.dateFin === null || dateFin >= aujourdhui) &&
        (remise.typeRemise === typeRemise)
      );
    }) ?? [];

    listeRemisesEnvoi = await Promise.all(listeRemisesEnvoi.map(async (remise) => {
      if (remise.articleRef) {
        const article = await this.dbService.dexie.synchronisation_articles.where('articleRef').equals(remise.articleRef).first();
        if (article) {
          remise.libelle = article.designation;
        }
      }
      else if (remise.familleArticle) {
        const famille = await this.dbService.dexie.synchronisation_familles_stat_1.where('familleStatRef').equals(remise.familleArticle).first();
        if (famille) {
          remise.libelle = famille.familleStatLibelle;
        }
      }
      else if (remise.regroupementArticle) {
        const regroupement = await this.dbService.dexie.synchronisation_familles_stat_2.where('familleStatRef').equals(remise.regroupementArticle).first();
        if (regroupement) {
          remise.libelle = regroupement.familleStatLibelle;
        }
      }
      else if (remise.autreArticle) {
        const autre = await this.dbService.dexie.synchronisation_familles_stat_3.where('familleStatRef').equals(remise.autreArticle).first();
        if (autre) {
          remise.libelle = autre.familleStatLibelle;
        }
      }
      return remise;
    }));

    return listeRemisesEnvoi;
  }

  public async obtenirRemisesDepuisArticle(article: SynchronisationArticle, client: SynchronisationClient, typeRemise: string): Promise<SynchronisationRemiseClientEtendue[]> {

    await this.init(typeRemise);

    let listeRemisesCopie;
    if (typeRemise === "R") {
      listeRemisesCopie = [...this.listeRemises];
    }
    else if (typeRemise === "Q") {
      listeRemisesCopie = [...this.listeRemisesQuantite];
    }
    else if (typeRemise === "I") {
      listeRemisesCopie = [...this.listeRistournes];
    }
    else if (typeRemise === "O") {
      listeRemisesCopie = [...this.listeOdrds];
    }

    const aujourdhui: Date = new Date();
    aujourdhui.setHours(0, 0, 0, 0);

    const listeRemisesEnvoi = listeRemisesCopie?.filter((remise) => {
      const dateFin: Date = new Date(remise.dateFin);
      dateFin.setHours(0, 0, 0, 0);
      const dateEffet: Date = new Date(remise.dateEffet);
      dateEffet.setHours(0, 0, 0, 0);

      return this.estClient(client, remise) &&
        this.estArticleOuFournisseurOuFamilleOuRegroupementOuAutreOuClasse(article, remise) &&
        (remise.dateFin === null || dateFin >= aujourdhui) &&
        (remise.dateEffet === null || dateEffet <= aujourdhui) &&
        (remise.typeRemise === typeRemise) &&
        ((remise.typeRemise !== 'Q' && remise.remiseMontant !== 0) || (remise.typeRemise === 'Q' &&
          (article.uniteAchat === remise.uniteSeuil ||
            article.uniteDivers1 === remise.uniteSeuil ||
            article.uniteDivers2 === remise.uniteSeuil ||
            article.uniteReference === remise.uniteSeuil ||
            article.uniteStockage === remise.uniteSeuil ||
            article.unitePalette === remise.uniteSeuil ||
            article.uniteVente === remise.uniteSeuil)));
    }).sort((a: SynchronisationRemisesClient, b: SynchronisationRemisesClient): number => {

      const compareDates = (dateA: Date | null, dateB: Date | null): number => {
        if (!dateA && dateB) return 1;
        if (dateA && !dateB) return -1;
        if (!dateA && !dateB) return 0;
        if (dateB && dateA) {
          return dateB.getTime() - dateA.getTime();
        }
        else {
          return 0;
        }
      };

      const dateEffetComparison = compareDates(new Date(a.dateEffet), new Date(b.dateEffet));
      if (dateEffetComparison !== 0) return dateEffetComparison;

      const triDecroissant = (prop: keyof SynchronisationRemisesClient): number => {
        return b[prop].toString().localeCompare(a[prop].toString());
      };

      return (
        triDecroissant('tiers') ||
        triDecroissant('codeTarif') ||
        triDecroissant('familleClient') ||
        triDecroissant('classeRemiseClient') ||
        triDecroissant('categorieClient') ||
        triDecroissant('articleRef') ||
        triDecroissant('articleUnite') ||
        triDecroissant('fournisseurArticle') ||
        triDecroissant('classeRemiseArticle') ||
        triDecroissant('familleArticle') ||
        triDecroissant('regroupementArticle') ||
        triDecroissant('autreArticle') ||
        0
      );
    });

    const remise: SynchronisationRemiseClientEtendue | undefined = listeRemisesEnvoi ? listeRemisesEnvoi[0] : undefined;
    let remiseTrouvee: SynchronisationRemiseClientEtendue[] = [];
    if (remise !== undefined) {
      remiseTrouvee.push(remise);
      remiseTrouvee = await Promise.all(remiseTrouvee.map(async (remise) => {
        if (remise.tiers) {
          const client = await this.dbService.dexie.synchronisation_clients.where('tiers').equals(remise.tiers).first();
          if (client) {
            remise.libelle = client.nomClient;
          }
        }
        else if (remise.codeTarif) {
          const tarif = await this.dbService.dexie.synchronisation_libelles_tarifs.where('codeTarif').equals(remise.codeTarif).first();
          if (tarif) {
            remise.libelle = tarif.libelle;
          }
        }
        else if (remise.familleArticle) {
          const famille = await this.dbService.dexie.synchronisation_familles_stat_1.where('familleStatRef').equals(remise.familleArticle).first();
          if (famille) {
            remise.libelle = famille.familleStatLibelle;
          }
        }
        return remise;
      }));
    }
    return remiseTrouvee;
  }

  public async obtenirRemiseDepuisPanier(article: SynchronisationArticle, client: SynchronisationClient, typeRemise: string): Promise<SynchronisationRemisesClient | undefined> {

    await this.init(typeRemise);

    let listeRemisesCopie;
    if (typeRemise === "R") {
      listeRemisesCopie = [...this.listeRemises];
    }
    else if (typeRemise === "Q") {
      listeRemisesCopie = [...this.listeRemisesQuantite];
    }
    else if (typeRemise === "I") {
      listeRemisesCopie = [...this.listeRistournes];
    }
    const aujourdhui: Date = new Date();
    aujourdhui.setHours(0, 0, 0, 0);

    const listeRemisesEnvoi = listeRemisesCopie?.filter((remise) => {
      const dateFin: Date = new Date(remise.dateFin);
      dateFin.setHours(0, 0, 0, 0);
      const dateEffet: Date = new Date(remise.dateEffet);
      dateEffet.setHours(0, 0, 0, 0);

      return this.estClient(client, remise) &&
        this.estArticleOuFournisseurOuFamilleOuRegroupementOuAutreOuClasse(article, remise) &&
        (remise.dateFin === null || dateFin >= aujourdhui) &&
        (remise.dateEffet === null || dateEffet <= aujourdhui) &&
        (remise.typeRemise === typeRemise) &&
        ((remise.typeRemise === 'R' && remise.remiseMontant && remise.remiseMontantType) ||
          (remise.typeRemise === 'Q' && remise.quantiteSeuil && remise.uniteSeuil &&
            (article.uniteAchat === remise.uniteSeuil ||
              article.uniteDivers1 === remise.uniteSeuil ||
              article.uniteDivers2 === remise.uniteSeuil ||
              article.uniteReference === remise.uniteSeuil ||
              article.uniteStockage === remise.uniteSeuil ||
              article.unitePalette === remise.uniteSeuil ||
              article.uniteVente === remise.uniteSeuil)) ||
          (remise.typeRemise === 'I' && remise.remiseMontant && remise.remiseMontantType && remise.quantiteSeuil && remise.uniteSeuil));
    }).sort((a: SynchronisationRemisesClient, b: SynchronisationRemisesClient): number => {
      const triDecroissant = (prop: keyof SynchronisationRemisesClient): number => {
        return b[prop].toString().localeCompare(a[prop].toString());
      };

      return (
        triDecroissant('tiers') ||
        triDecroissant('codeTarif') ||
        triDecroissant('familleClient') ||
        triDecroissant('classeRemiseClient') ||
        triDecroissant('categorieClient') ||
        triDecroissant('articleRef') ||
        triDecroissant('articleUnite') ||
        triDecroissant('fournisseurArticle') ||
        triDecroissant('classeRemiseArticle') ||
        triDecroissant('familleArticle') ||
        triDecroissant('regroupementArticle') ||
        triDecroissant('autreArticle') ||
        0
      );
    });

    const remise: SynchronisationRemisesClient | undefined = listeRemisesEnvoi ? listeRemisesEnvoi[0] : undefined;
    return remise;
  }

  private estClient(client: SynchronisationClient, remise: SynchronisationRemisesClient): boolean {
    return (client.tiers !== "" && remise.tiers === client.tiers) ||
      (client.codeTarif !== "" && remise.codeTarif === client.codeTarif) ||
      (client.categorieClient !== "" && remise.categorieClient === client.categorieClient) ||
      (client.familleClient !== "" && remise.familleClient === client.familleClient) ||
      (client.classeRemiseClient !== "" && remise.classeRemiseClient === client.classeRemiseClient)
  }

  private estArticleOuFournisseurOuFamilleOuRegroupementOuAutreOuClasse(article: SynchronisationArticle, remise: SynchronisationRemisesClient): boolean {

    return article.articleRef !== "" && article.articleRef === remise.articleRef ||
      article.familleStat1Ref === remise.familleArticle && remise.familleArticle !== "" ||
      article.familleStat1N1Ref === remise.familleArticle && remise.familleArticle !== "" ||
      article.familleStat1N2Ref === remise.familleArticle && remise.familleArticle !== "" ||
      article.familleStat1N3Ref === remise.familleArticle && remise.familleArticle !== "" ||
      article.familleStat1N4Ref === remise.familleArticle && remise.familleArticle !== "" ||
      article.familleStat2Ref === remise.regroupementArticle && remise.regroupementArticle !== "" ||
      article.familleStat2N1Ref === remise.regroupementArticle && remise.regroupementArticle !== "" ||
      article.familleStat2N2Ref === remise.regroupementArticle && remise.regroupementArticle !== "" ||
      article.familleStat2N3Ref === remise.regroupementArticle && remise.regroupementArticle !== "" ||
      article.familleStat2N4Ref === remise.regroupementArticle && remise.regroupementArticle !== "" ||
      article.familleStat3Ref === remise.autreArticle && remise.autreArticle !== "" ||
      article.familleStat3N1Ref === remise.autreArticle && remise.autreArticle !== "" ||
      article.familleStat3N2Ref === remise.autreArticle && remise.autreArticle !== "" ||
      article.familleStat3N3Ref === remise.autreArticle && remise.autreArticle !== "" ||
      article.familleStat3N4Ref === remise.autreArticle && remise.autreArticle !== "" ||
      article.fournisseurHabituelRef === remise.fournisseurArticle && remise.fournisseurArticle !== "" ||
      article.classeRemise === remise.classeRemiseArticle && remise.classeRemiseArticle !== "";
  }



}
