import type { CommercialDTO, ParametresUtilisateurCRM, ProfilUtilisateur } from "../crm/crm";
import type { LogLevel } from "../log/level.model";

export class ParametreApplication<T> {
  public id!: ParametreApplicationId;
  public valeur!: T;
  public version?: T;

  constructor(id: ParametreApplicationId, valeur: T, horodatage?: T) {
    this.id = id;
    this.valeur = valeur;
    this.version = horodatage;
  }

  // constructor(id: ParametreApplicationId, valeur: T) {
  //   this.id = id;
  //   this.valeur = valeur;
  // }
}

export enum ParametreApplicationId {
  UTILISATEUR = 1,
  META_DERNIERE_SYNCHRO_VERSION = 8,
  META_DERNIERE_SYNCHRO_HORODATAGE = 9,
  DERNIERE_SYNCHRO_CLIENTS = 10,
  DERNIERE_SYNCHRO_CONTACTS_CLIENTS = 11,
  DERNIERE_SYNCHRO_EVENEMENTS = 12,
  DERNIERE_SYNCHRO_FAMILLES_STAT = 13,
  DERNIERE_SYNCHRO_ARTICLES = 14,
  DERNIERE_SYNCHRO_ADRESSES_CLIENTS = 15,
  DERNIERE_SYNCHRO_UNITES = 16,
  // DERNIERE_SYNCHRO_ENCOURS_VENTE = 17,
  // DERNIERE_SYNCHRO_ENCOURS_FACTURE = 18,
  // DERNIERE_SYNCHRO_INDICATEURS_STAT = 19,
  DERNIERE_SYNCHRO_DECONSIGNES_ATTENTE = 20,
  DERNIERE_SYNCHRO_STOCK_ARTICLES = 21,
  DERNIERE_SYNCHRO_TARIFS_SPECIAUX = 22,
  DERNIERE_SYNCHRO_CLASSES_REMISES = 23,
  DERNIERE_SYNCHRO_FICJOINTS = 24,
  DERNIERE_SYNCHRO_STAT = 25,
  DERNIERE_SYNCHRO_ENCOURS = 26,
  DERNIERE_SYNCHRO_TARIFS = 27,
  DERNIERE_SYNCHRO_DEFINITIONS = 28,
  DERNIERE_SYNCHRO_REFERENCEMENT = 29,
  DERNIERE_SYNCHRO_PLANNING = 30,
  DERNIERE_SYNCHRO_LIBELLES_TARIFS = 31,
  DERNIERE_SYNCHRO_EXCEPTIONS_TARIFAIRES = 32,
  DERNIERE_SYNCHRO_REMISES = 33,
  DERNIERE_SYNCHRO_REMISES_QUANTITE = 34,
  DERNIERE_SYNCHRO_RISTOURNES = 35,
  DERNIERE_SYNCHRO_TOURNEE = 36,
  DERNIERE_SYNCHRO_MOTIFS_GRATUITE = 37,
  DERNIERE_SYNCHRO_HISTORIQUE_ARTICLE = 38,
  DERNIERE_SYNCHRO_FRAIS_SUPPLEMENTAIRES = 39,
  DERNIERE_SYNCHRO_ODRD = 40,
  DERNIERE_SYNCHRO_ARTICLE_MEDIAS = 41,
  DERNIERE_SYNCHRO_MISES_EN_AVANT = 42,
  ACCES_API_DESACTIVE = 50,
  UTILISATEUR_CONNECTE = 70,
  COMMERCIAL_ACTIF = 100,
  PARAMETRES_UTILISATEUR_CRM = 101,
  PANIER_ACTIF = 102,
  CLIENT_ACTIF = 103,
  PROFIL_UTILISATEUR = 104,
  VUE_CATALOGUE_ACTIVE = 111,
  VUE_PANIER_ACTIVE = 112,
  MODE_TACTILE = 113,
  MODE_DROITIER = 114,
  CLAVIER_AGRANDI = 115,
  ENVIRONNEMENT_DEPOT = 150,
  ENVIRONNEMENT_COMMERCIAL = 151,
  AFFICHER_PANIER_AVEC_ERREUR = 300,
  LOG_LEVEL_APP = 1000,
  LOG_LEVEL_CONSOLE = 1001,
  LOG_LEVEL_WEB_HTTP = 1002,
}

export type ParametreApplicationType = {
  [ParametreApplicationId.UTILISATEUR]: string;
  [ParametreApplicationId.META_DERNIERE_SYNCHRO_VERSION]: string;
  [ParametreApplicationId.META_DERNIERE_SYNCHRO_HORODATAGE]: number;
  [ParametreApplicationId.DERNIERE_SYNCHRO_CLIENTS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_CONTACTS_CLIENTS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_EVENEMENTS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_FAMILLES_STAT]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_ARTICLES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_ADRESSES_CLIENTS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_UNITES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_STOCK_ARTICLES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS_SPECIAUX]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_CLASSES_REMISES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_DECONSIGNES_ATTENTE]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_FICJOINTS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_STAT]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_ENCOURS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_DEFINITIONS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_REFERENCEMENT]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_PLANNING]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_LIBELLES_TARIFS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_EXCEPTIONS_TARIFAIRES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_REMISES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_REMISES_QUANTITE]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_RISTOURNES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_TOURNEE]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_MOTIFS_GRATUITE]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_HISTORIQUE_ARTICLE]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_FRAIS_SUPPLEMENTAIRES]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_ODRD]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_ARTICLE_MEDIAS]: Date;
  [ParametreApplicationId.DERNIERE_SYNCHRO_MISES_EN_AVANT]: Date;
  [ParametreApplicationId.ACCES_API_DESACTIVE]: Date;
  [ParametreApplicationId.UTILISATEUR_CONNECTE]: boolean;
  [ParametreApplicationId.COMMERCIAL_ACTIF]: CommercialDTO;
  [ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM]: ParametresUtilisateurCRM;
  [ParametreApplicationId.PANIER_ACTIF]: number;
  [ParametreApplicationId.CLIENT_ACTIF]: string;
  [ParametreApplicationId.PROFIL_UTILISATEUR]: ProfilUtilisateur;
  [ParametreApplicationId.VUE_CATALOGUE_ACTIVE]: number;
  [ParametreApplicationId.VUE_PANIER_ACTIVE]: number;
  [ParametreApplicationId.MODE_TACTILE]: boolean;
  [ParametreApplicationId.MODE_DROITIER]: boolean;
  [ParametreApplicationId.CLAVIER_AGRANDI]: boolean;
  [ParametreApplicationId.ENVIRONNEMENT_DEPOT]: string;
  [ParametreApplicationId.ENVIRONNEMENT_COMMERCIAL]: string;
  [ParametreApplicationId.AFFICHER_PANIER_AVEC_ERREUR]: boolean;
  [ParametreApplicationId.LOG_LEVEL_APP]: LogLevel;
  [ParametreApplicationId.LOG_LEVEL_CONSOLE]: LogLevel;
  [ParametreApplicationId.LOG_LEVEL_WEB_HTTP]: LogLevel;
};
