<div class="conteneur-evenement barre-navigation" #conteneur (mousewheel)="scrollHorizontal($event, conteneur)">
  <ng-container *ngIf="(evenements.length > 0 || evenementsNonSynchronises.length > 0) else noData">
    <!-- Evenements non synchronises -->
    <div class="card conteneur-evenement_div mb-2" *ngFor="let evenement of evenementsNonSynchronises" (mousewheel)="scrollHorizontal($event, conteneur)">
      <span class="conteneur-evenement_date bg-info text-light p-1">
        <span>
          <span class="conteneur-evenement_date_texte fa fa-fw fa-calendar me-1 mb-1"></span>
          <span class="conteneur-evenement_date_texte">{{utilitaireGeneralService.formatDateStr(evenement.dateDebut) | kalTms:"date2"}}</span>
        </span>
        <br>
        <span>
          <span class="conteneur-evenement_date_texte fa fa-fw fa-clock me-1"></span>
          <span class="conteneur-evenement_date_texte">{{utilitaireGeneralService.formatHeureStrEvtNonSynchronise(evenement.dateDebut)}}</span>
        </span>
      </span>
      <span class="conteneur-evenement_tiers">{{evenement.tiersRef}}</span>
      <span class="conteneur-evenement_contexte">
        <span class="fa fa-fw fa-plug-circle-xmark text-warning" title="Évènement non synchronisé"></span>
        <span class="fa fa-fw fa-tag"></span>
        <span>{{evenement.libelle}}</span>
      </span>
    </div>

    <!-- Evenements -->
    <div class="card conteneur-evenement_div mb-2" *ngFor="let evenement of evenements"
      (mousewheel)="scrollHorizontal($event, conteneur)" [routerLink]="['crm/evenements', evenement.numeroEvenement]"
      routerLinkActive="actif" role="button">
      <span class="conteneur-evenement_date bg-info text-light p-1">
        <span>
          <span class="conteneur-evenement_date_texte fa fa-fw fa-calendar me-1 mb-1"></span>
          <span class="conteneur-evenement_date_texte">{{utilitaireGeneralService.formatDateStr(evenement.dateDebut) | kalTms:"date2"}}</span>
        </span>
        <br>
        <span>
          <span class="conteneur-evenement_date_texte fa fa-fw fa-clock me-1"></span>
          <span class="conteneur-evenement_date_texte">{{utilitaireGeneralService.formatHeureStr(evenement.heureDebut)}}</span>
        </span>
      </span>
      <span class="conteneur-evenement_tiers">{{evenement.tiersNom}}</span>
      <span class="conteneur-evenement_contexte">
        <span class="fa fa-fw fa-tag"></span>
        <span>{{evenement.libelleEvenement}}</span>
      </span>
    </div>
  </ng-container>
  <ng-template #noData>
    <div class="conteneur-evenement_aucun-evenement">
      <span class="fas fa-calendar-alt"></span>
      <span class="ms-1">Aucun évènement</span>
    </div>
  </ng-template>
</div>
