import { Injectable } from '@angular/core';
import { SynchronisationAdresseClient, SynchronisationArticle, SynchronisationClient } from '../synchronisation/synchronisation';
import { DbService } from '../db/db.service';
import { HttpService } from '../http/http.service';
import { firstValueFrom } from 'rxjs';
import { FavorisArticle, FavorisArticleServeur, FavorisClient, FavorisClientServeur, FavorisContactClient, FavorisContactClientServeur } from '../favoris/favoris';
import { CommercialDTO } from '../crm/crm';

@Injectable({
  providedIn: 'root'
})
export class UtilitaireCrmService {

  constructor(
    private dbService: DbService,
    private httpService: HttpService,
  ) {

  }

  public adresses: SynchronisationAdresseClient | undefined;

  public lienTelephone(telephone: string): string {
    const telSansEspaces = telephone.replace(/ */g, '')
    return `tel:${telSansEspaces}`;
  }

  public lienMail(mail: string): string {
    return `mailto:${mail}`
  }

  public clientLienTelephone(client: SynchronisationClient): string {
    const telSansEspaces = client.telephone.replace(/ */g, '');
    return `tel:${telSansEspaces}`;
  }

  public commercialLienTelephone(commercial: CommercialDTO): string {
    const telSansEspaces = commercial.telephone.replace(/ */g, '');
    return `tel:${telSansEspaces}`;
  }

  public contactLienTelephone(telephone: string): string {
    let telSansEspaces: string = '';
    telSansEspaces = telephone.replace(/ */g, '');
    return `tel:${telSansEspaces}`;
  }

  public adresseLienTelephone(adresse: SynchronisationAdresseClient): string {
    const telSansEspaces = adresse.telephone.replace(/ */g, '');
    return `tel:${telSansEspaces}`;
  }

  public clientLienAdresse(client: SynchronisationClient): string {
    const adresse: string = `${client.rue ? client.rue + ',' : ''} ${client.codePostal} ${client.ville}`.trim();
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(adresse)}`;
  }

  public clientLienAdresseIPhone(client: SynchronisationClient): string {
    const adresse: string = `${client.rue ? client.rue + ',' : ''} ${client.codePostal} ${client.ville}`.trim();
    const encodedAdresse = encodeURIComponent(adresse);
    return `http://maps.apple.com/?address=${encodedAdresse}`;
  }

  public clientLienSiteWebEpure(client: SynchronisationClient): string {
    return client.adresseWeb
      .replace(/https?:\/\//, '')
      .replace(/www\./, '');
  }

  public conversionUniteRefVersStock(article: SynchronisationArticle, qteRef: number): {
    unite: string,
    qte: number,
    coefficient: number
  } {
    if (!article.uniteStockage || article.uniteStockageConvertieEn !== article.uniteReference || !article.uniteStockageCoefficient) {
      return {
        unite: article.uniteReference,
        qte: qteRef,
        coefficient: 1
      }
    } else {
      return {
        unite: article.uniteStockage,
        qte: qteRef / article.uniteStockageCoefficient,
        coefficient: article.uniteStockageCoefficient
      }
    }
  }

  public async synchroniserAppli(): Promise<void> {
    const [
      favorisArticles,
      favorisClients,
      favorisContactsClients,
    ] = await Promise.all([
      this.dbService.dexie.favoris_articles.toArray(),
      this.dbService.dexie.favoris_clients.toArray(),
      this.dbService.dexie.favoris_contacts_clients.toArray(),
    ]);

    const dto = {
      FAVORIS_ARTICLES: favorisArticles,
      FAVORIS_CLIENTS: favorisClients,
      FAVORIS_CONTACTS_CLIENTS: favorisContactsClients,
    }

    const retour = await firstValueFrom(this.httpService.postFromApi<{
      FAVORIS_ARTICLES: FavorisArticleServeur[],
      FAVORIS_CLIENTS: FavorisClientServeur[],
      FAVORIS_CONTACTS_CLIENTS: FavorisContactClientServeur[],
    }>('business/crm/synchroniser-appli', dto));

    if (!retour.body) throw new Error("Pas de retour");

    const dtoRetour = retour.body;

    const favorisArticlesMap: FavorisArticle[] = dtoRetour.FAVORIS_ARTICLES.map(f => ({ articleRef: f.articleRef, statut: f.statut, dateModif: f.lastModifiedDate }));
    const favorisClientsMap: FavorisClient[] = dtoRetour.FAVORIS_CLIENTS.map(f => ({ tiers: f.tiers, statut: f.statut, dateModif: f.lastModifiedDate }));
    const favorisContactsClientsMap: FavorisContactClient[] = dtoRetour.FAVORIS_CONTACTS_CLIENTS.map(f => ({ tiers: f.tiers, contact: f.contact, statut: f.statut, dateModif: f.lastModifiedDate }));

    this.dbService.dexie.transaction('rw', this.dbService.dexie.favoris_articles, this.dbService.dexie.favoris_clients, this.dbService.dexie.favoris_contacts_clients, async () => {
      await Promise.all([
        this.dbService.dexie.favoris_articles.clear(),
        this.dbService.dexie.favoris_clients.clear(),
        this.dbService.dexie.favoris_contacts_clients.clear(),
      ]);

      await Promise.all([
        this.dbService.dexie.favoris_articles.bulkPut(favorisArticlesMap),
        this.dbService.dexie.favoris_clients.bulkPut(favorisClientsMap),
        this.dbService.dexie.favoris_contacts_clients.bulkPut(favorisContactsClientsMap),
      ]);
    });
  }

  public formatTelephone(telephone: string): string {
    if (!telephone) {
      return '';
    }
    const tel = telephone.replace(/[\s.,]/g, '');
    return tel.replace(/(.{2})/g, '$1 ').trim();
  }


}
