import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';

export async function addListeners() {
  await FirebaseMessaging.addListener('notificationReceived', ev => {
    console.log('Push notification received: ', JSON.stringify(ev.notification));
  });

  await FirebaseMessaging.addListener('notificationActionPerformed', ev => {
    console.log('Push notification action performed', ev.actionId, ev.inputValue, JSON.stringify(ev.notification));
  });
}

export async function demanderPermissionsEnvoiNotifications(): Promise<void> {
  let permStatus = await FirebaseMessaging.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await FirebaseMessaging.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('Permission refusée explicitement!');
  }
}

export async function recupererJetonFirebase(options?: GetTokenOptions): Promise<string> {
  const resultat = await FirebaseMessaging.getToken(options);
  return resultat.token;
}

export async function getDeliveredNotifications() {
  const notificationList = await FirebaseMessaging.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
}

export const CANAL_TEST_IMPORTANT = 'KAL_TEST_IMPORTANT'

export async function configurerCanauxDeNotification() {
  await FirebaseMessaging.createChannel({
    id: CANAL_TEST_IMPORTANT,
    name: 'Canal de test (important)',
    importance: 4,
    visibility: 1,
  })
}