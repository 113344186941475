import { Injectable } from '@angular/core';
import { DbService } from '../db/db.service';
import { ParametreApplicationType, ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { liveQuery } from 'dexie';
import { map, Observable, ReplaySubject } from 'rxjs';
import { LoggerService } from '../log/logger.service';
import { environment } from 'src/environments/environment';
import { SynchronisationClient } from '../synchronisation/synchronisation';
import { AppConfigService } from '../configuration/app-config.service';

type ParametreApplicationPour<P extends ParametreApplicationId> = ParametreApplication<ParametreApplicationType[P]>;

export type EnvironnementValeur = {
  [index in ParametreApplicationId]?: ParametreApplicationPour<index>;
};

export type EnvironnementReactif = {
  [index in ParametreApplicationId]: ReplaySubject<ParametreApplicationPour<index>>;
};

@Injectable({
  providedIn: 'root',
})
export class EnvironnementApplicationService {
  private initialise: boolean = false;
  private environnement: EnvironnementValeur = {};
  private clesEnvironnement: (keyof EnvironnementReactif)[];

  private environnementReactif: EnvironnementReactif;
  private clientActifMemoire?: SynchronisationClient;

  constructor(private dbService: DbService, private loggerService: LoggerService, private appConfig: AppConfigService) {
    this.clesEnvironnement = [];
    const environnementReactif: Partial<EnvironnementReactif> = {};
    for (const cle of Object.values(ParametreApplicationId)) {
      if (typeof cle === 'number') {
        (environnementReactif[cle] as ReplaySubject<ParametreApplication<unknown>>) = new ReplaySubject(1);
        this.clesEnvironnement.push(cle);
      }
    }
    this.environnementReactif = environnementReactif as EnvironnementReactif;

    liveQuery(() => this.dbService.dexie.parametres_application.toArray()).subscribe((parametres) => {
      for (const cle of Object.keys(ParametreApplicationId)) {
        const id = +cle as ParametreApplicationId;
        if (Number.isNaN(id)) continue;
        const parametre = parametres.find((parametre) => parametre.id === id);
        if (this.initialise && JSON.stringify(this.environnement[id]?.valeur) === JSON.stringify(parametre?.valeur))
          continue;
        (this.environnement[id] as ParametreApplication<unknown> | undefined) = parametre;
        (this.environnementReactif[id] as ReplaySubject<ParametreApplication<unknown>>)?.next({
          id,
          valeur: parametre?.valeur,
        });
      }
      this.initialise = true;
    });

    for (const replaySubject of Object.values(this.environnementReactif)) {
      (replaySubject as ReplaySubject<ParametreApplication<unknown>>).subscribe((parametre) => {
        this.loggerService.verbose(
          () =>
            `EnvironnementApplicationService: le paramètre [${
              ParametreApplicationId[parametre.id]
            }] a été modifié en [${JSON.stringify(parametre)}].`
        );
      });
    }
  }

  public getParametre<P extends ParametreApplicationId>(id: P): ParametreApplicationType[P] | undefined {
    return this.environnement[id]?.valeur;
  }

  public setParametre<P extends ParametreApplicationId>(
    id: P,
    valeur: ParametreApplicationType[P] | undefined
  ): Promise<number> {
    return this.dbService.dexie.parametres_application.put({ id, valeur });
  }

  public getParametreReactif<P extends ParametreApplicationId>(id: P): Observable<ParametreApplicationType[P] | undefined> {
    return this.environnementReactif[id].pipe(map((p) => p?.valeur));
  }

  /** @deprecated */
  public getParametreReactifBrut<P extends ParametreApplicationId>(
    id: P
  ): ReplaySubject<ParametreApplication<ParametreApplicationType[P]>> {
    return this.environnementReactif[id];
  }

  public get estEnvironnementDev(): boolean {
    return !environment.production;
  }

  public async getClientActif(): Promise<SynchronisationClient | undefined> {
    const cli = this.getParametre(ParametreApplicationId.CLIENT_ACTIF) ?? '';
    if (!this.clientActifMemoire || this.clientActifMemoire.tiers != cli) {
      this.clientActifMemoire = await this.dbService.dexie.synchronisation_clients.get(cli);
    }
    return this.clientActifMemoire;
  }
}
