import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SynchronisationArticle, SynchronisationUnite } from 'src/app/services/synchronisation/synchronisation';
import { ArticleEtendu } from '../../crm/catalogue/liste-articles/liste-articles.component';
import { CrmPanierService } from 'src/app/services/crm/crm-panier/crm-panier.service';

@Component({
  selector: 'app-panachage-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './panachage-modal.component.html',
  styleUrls: ['./panachage-modal.component.scss']
})
export class PanachageModalComponent implements OnInit {

  public uniteRemise: string = "";
  public classeRemise: string = "";
  public articleRefGratuitChoisi: string = "";

  public articles: SynchronisationArticle[] = [];
  public articlesEtendus: ArticleEtendu[]= [];
  public unites: SynchronisationUnite[] | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private crmPanierService: CrmPanierService,
  ) {}

  async ngOnInit(): Promise<void> {
    if(this.uniteRemise && this.classeRemise) {
      this.articlesEtendus = await this.crmPanierService.trouverListeArticlesChoixGratuites(this.uniteRemise, this.classeRemise);
    }
  }

  public fermer(): void {
    this.activeModal.dismiss();
  }

  selectionnerArticle(article: ArticleEtendu): void {
    this.activeModal.close(article);
  }

}
