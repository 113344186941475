import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SynchronisationService } from '../../synchronisation/synchronisation.service';
import { SynchronisationQuotidienneService } from '../../synchronisation-quotidienne/synchronisation-quotidienne.service';

@Component({
  selector: 'app-login-chargement-synchros',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-chargement-synchros.component.html',
  styleUrls: ['./login-chargement-synchros.component.scss']
})
export class LoginChargementSynchrosComponent {

  constructor(
    public synchronisationService: SynchronisationService,
    public synchronisationQuotidienneService: SynchronisationQuotidienneService,
  ) { }

  avanceeSynchro(current: number, total: number): string {
    return (current / total * 100) + '%';
  }

}
