import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appToucheClavier]',
  standalone: true
})
export class toucheClavierDirective {

  @Output() toucheF1: EventEmitter<void> = new EventEmitter();
  @Output() toucheF2: EventEmitter<void> = new EventEmitter();
  @Output() toucheF7: EventEmitter<void> = new EventEmitter();
  @Output() toucheF8: EventEmitter<void> = new EventEmitter();
  @Output() toucheF9: EventEmitter<void> = new EventEmitter();
  @Output() toucheCtrlSuppr: EventEmitter<void> = new EventEmitter();
  @Output() toucheCtrlEntrer: EventEmitter<void> = new EventEmitter();

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'F1') {
      this.toucheF1.emit();
      event.preventDefault();
    } else if (event.key === 'F2') {
      this.toucheF2.emit();
      event.preventDefault();
    } else if (event.key === 'F7') {
      this.toucheF7.emit();
      event.preventDefault();
    } else if (event.key === 'F8') {
      this.toucheF8.emit();
      event.preventDefault();
    } else if (event.key === 'F9') {
      this.toucheF9.emit();
      event.preventDefault();
    } else if (event.key === 'Delete' && event.ctrlKey) {
      this.toucheCtrlSuppr.emit();
      event.preventDefault();
    } else if (event.key === 'Enter' && event.ctrlKey) {
      this.toucheCtrlEntrer.emit();
      event.preventDefault();
    }
  }
}
