import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CommercialDTO } from 'src/app/services/crm/crm';
import { DbService } from 'src/app/services/db/db.service';
import { liveQueryReactif } from 'src/app/services/db/liveQueryReactif';
import { ParametreApplication, ParametreApplicationId } from 'src/app/services/db/parametre-application';
import { DonneesService } from 'src/app/services/donnees/donnees.service';
import { EnvironnementApplicationService } from 'src/app/services/environnement-application/environnement-application.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import {
  SynchronisationInfoCommercial,
  SynchronisationInfoDepot,
} from 'src/app/services/synchronisation-quotidienne/synchronisation-quotidienne';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ModalAssistanceComponent } from '../../fragments/modal-assistance/modal-assistance.component';
import { SynchronisationService } from 'src/app/services/synchronisation/synchronisation.service';
import { PartitionnementModule } from 'src/app/tools/directives/partitionnement/partitionnement.module';
import { ArticleService } from 'src/app/services/articles/articles.service';
import { SynchronisationClient } from 'src/app/services/synchronisation/synchronisation';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { AideModalComponent } from '../../fragments/aide-modal/aide-modal.component';
import { AnniversairesModalComponent } from '../../fragments/anniversaires-modal/anniversaires-modal.component';

@Component({
  selector: 'app-entete',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, PartitionnementModule],
  templateUrl: './entete.component.html',
  styleUrls: ['./entete.component.scss'],
})
export class EnteteComponent implements OnInit, OnDestroy {
  private ngDestroy$ = new Subject<void>();

  public depotsDisponibles: SynchronisationInfoDepot[] = [];
  public commerciauxDisponibles: SynchronisationInfoCommercial[] = [];
  public clientsDisponibles: SynchronisationClient[] = [];
  public commercialActif: string = '';
  public depotActif: string = '';
  public montantTotalDus: number = 0;
  public afficherMontant: boolean = false;
  public utilisateurConnecte: ParametreApplication<boolean> | undefined;

  public commercial?: CommercialDTO;
  public chargement: boolean = false;

  public assistanceEnCours: boolean = false;
  public clientActif: string = '';

  constructor(
    private router: Router,
    private dbService: DbService,
    private modalService: ModalService,
    private toastService: ToastService,
    private appConfig: AppConfigService,
    private donneesService: DonneesService,
    private environnementApplicationService: EnvironnementApplicationService,
    public loginService: LoginService,
    public articleService: ArticleService,
    public synchronisationService: SynchronisationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.chargement = true;

    this.loginService.utilisateurConnecte = await this.dbService.dexie.parametres_application.get(
      ParametreApplicationId.UTILISATEUR_CONNECTE
    ) as ParametreApplication<boolean> | undefined;

    if (this.appConfig.modeCommercial) {
      await this.environnementApplicationService
        .getParametreReactifBrut(ParametreApplicationId.COMMERCIAL_ACTIF)
        .subscribe(() => {
          this.commercial = this.environnementApplicationService.getParametre(
            ParametreApplicationId.COMMERCIAL_ACTIF
          );
        });

      await this.environnementApplicationService
        .getParametreReactifBrut(ParametreApplicationId.ENVIRONNEMENT_DEPOT)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((parametre) => {
          this.depotActif = parametre?.valeur ?? '';
        });

      this.environnementApplicationService
        .getParametreReactifBrut(ParametreApplicationId.ENVIRONNEMENT_COMMERCIAL)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((parametre) => {
          this.commercialActif = parametre?.valeur ?? '';
        });

      liveQueryReactif(() => this.dbService.dexie.synchronisation_info_depots.toArray(), [])
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((depots) => {
          this.depotsDisponibles = depots;
        });

      liveQueryReactif(() => this.dbService.dexie.synchronisation_info_commerciaux.toArray(), [])
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((commerciaux) => {
          this.commerciauxDisponibles = commerciaux;
        });
    } else {
      await this.environnementApplicationService
        .getParametreReactifBrut(ParametreApplicationId.CLIENT_ACTIF)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((parametre) => {
          this.clientActif = parametre?.valeur ?? '';
        });

      liveQueryReactif(() => this.dbService.dexie.synchronisation_clients.toArray(), [])
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((clients) => {
          this.clientsDisponibles = clients;
        });
    }

    this.chargement = false;
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public obtenirMontant(): string {
    this.montantTotalDus = Number(JSON.parse(localStorage.getItem('montantDus') || '0'));
    const montantArrondi = Math.round(this.montantTotalDus);
    const montantFormate = montantArrondi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return montantFormate;
  }

  public mettreAJourDepotEnvironnement(depot: string): void {
    this.environnementApplicationService.setParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT, depot);
  }

  public mettreAJourCommercialEnvironnement(commercial: string): void {
    this.environnementApplicationService.setParametre(ParametreApplicationId.ENVIRONNEMENT_COMMERCIAL, commercial);
    this.dbService.dexie.parametres_application.delete(ParametreApplicationId.CLIENT_ACTIF);
  }

  public mettreAJourClientEnvironnement(client: string): void {
    this.environnementApplicationService.setParametre(ParametreApplicationId.CLIENT_ACTIF, client);
    this.environnementApplicationService.setParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT, this.clientsDisponibles.find(cli => cli.tiers === client)?.depotRef);
  }

  public get estEnvironnementDev(): boolean {
    return this.environnementApplicationService.estEnvironnementDev;
  }

  public retourArriere(): void {
    window.history.back();
  }

  public retourAvant(): void {
    window.history.forward();
  }

  public retourAccueil(): void {
    this.router.navigate(['/']);
  }

  public synchroniser(): Promise<void> {
    return this.donneesService
      .synchroniser()
      .then(() => {
        this.toastService.afficher({
          entete: 'Synchronisation incrémentielle',
          corps: 'Synchronisation terminée',
        });
      })
      .catch(() => {
        this.toastService.afficher({
          entete: 'Synchronisation incrémentielle',
          corps: 'Erreur lors de la synchronisation',
        });
      });
  }

  public synchroniserQtd(): Promise<void> {
    return this.donneesService
      .synchroniserQtd()
      .then(() => {
        this.toastService.afficher({
          entete: 'Synchronisation quotidienne',
          corps: 'Synchronisation terminée',
        });
      })
      .catch(() => {
        this.toastService.afficher({
          entete: 'Synchronisation quotidienne',
          corps: 'Erreur lors de la synchronisation',
        });
      });
  }

  public async synchroniserTout(): Promise<void> {
    this.synchronisationService.synchronisationTotaleEnCours = true;
    await this.synchroniser();
    await this.synchroniserQtd();
    this.synchronisationService.synchronisationTotaleEnCours = false;
  }

  public ouvrirAssistance(): void {
    this.modalService.open(ModalAssistanceComponent, { size: 'md' });
  }

  public ouvrirAide(): void {
    this.modalService.open(AideModalComponent, { size: "lg" });
  }

  public ouvrirAnniversaires(): void {
    this.modalService.open(AnniversairesModalComponent, { size: "lg" });
  }

  public estPortable(): boolean {
    if (window.innerWidth < 768) {
      return true;
    }
    return false;
  }

  public changerAffichageTarifs(): void {
    this.articleService.afficherTarifDansListe = !this.articleService.afficherTarifDansListe;
    localStorage.setItem('afficherTarifs', this.articleService.afficherTarifDansListe ? 'oui' : 'non');
  }
}
