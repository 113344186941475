<div class="modal-header bg-primary text-light">
  <h4 class="modal-title">
    <span class="me-2">Anniversaires</span>
    <span style="font-size: 15px; font-style: italic;">7 prochains jours</span>
  </h4>
</div>

<div class="modal-body">
  <!-- Vérifie si des contacts existent -->
  <div *ngIf="contacts.length > 0">
    <ul class="list-group">
      <!-- Liste des contacts -->
      <li class="list-group-item d-flex flex-column gap-2" *ngFor="let contact of contacts">
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="d-flex gap-1 mb-1">
              <span>{{contact.prenom}}</span>
              <span>{{contact.nom}}</span>
            </h5>
            <small class="text-muted">
              Anniversaire : {{contact.anniversaire | date}} ({{contactsService.calculerAge(contact.anniversaire)}} ans)
            </small>
          </div>
          <button class="btn btn-sm btn-secondary" (click)="saisirPanier(contact.tiers)" title="Saisir un panier">
            <span class="fa fa-fw fa-basket-shopping"></span>
          </button>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <small class="text-muted">
              Client : <strong>{{ contact.tiers }}</strong> - {{ contact.nomTiers }}
            </small>
          </div>
          <button class="btn btn-sm btn-outline-primary" (click)="versClient(contact.tiers)" title="Fiche client">
            <span class="fa fa-fw fa-user"></span>
          </button>
        </div>
      </li>
    </ul>
  </div>

  <!-- Message si aucun contact -->
  <div *ngIf="contacts.length === 0" class="text-center text-muted">
    <p>Aucun anniversaire dans les {{ nbJoursAnniversaire }} prochains jours.</p>
  </div>

  <div *ngIf="chargement" class="alert alert-info text-center">
    <span class="fa fa-fw fa-spinner fa-spin"></span>
    Chargement en cours, veuillez patienter..
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="fermer()">OK</button>
</div>
