import { Component, NgZone, OnInit } from '@angular/core';
import { liveQuery } from 'dexie';
import { ParametreApplicationId, ParametreApplication } from './services/db/parametre-application';
import { DbService } from './services/db/db.service';
import { LoginService } from './services/login/login.service';
import { LoggerService } from './services/log/logger.service';
import { Router } from '@angular/router';
import { NavigationStart } from '@angular/router';
import { VerifIPhoneService } from './services/verif-iphone/verif-iphone.service';
import { PlateformeService } from './services/plateforme/platforme.service';
import { AppConfigService } from './services/configuration/app-config.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AideModalComponent } from './modules/fragments/aide-modal/aide-modal.component';
import { ModalService } from './services/modal/modal.service';
import { UpdateService } from './services/service-worker/update.service';
import { SynchronisationAutoService } from './services/synchronisation-auto/synchronisation-auto.service';
import { MotDePasseOublieService } from './services/motdepasse-oublie/motdepasse-oublie.service';
import { ArticleService } from './services/articles/articles.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public modaleOuverte: boolean = false;

  constructor(
    private updateService: UpdateService, // ⚠️ NE PAS SUPPRIMER ⚠️ sert à attacher les listeners du service worker
    private router: Router,
    private dbService: DbService,
    private modalService: ModalService,
    private loginService: LoginService,
    private loggerService: LoggerService,
    private articleService: ArticleService,
    private verifIphoneService: VerifIPhoneService,
    private plateformeService: PlateformeService,
    private motDePasseOublieService: MotDePasseOublieService,
    private synchroAutoService: SynchronisationAutoService,
    private appConfigService: AppConfigService,
    private zone: NgZone
  ) {
    if (this.appConfigService.modeClientFinal) {
      document.title = 'Kalico Ma Boutique';
    } else {
      document.title = 'Kalico CRM';
    }

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            const slug = event.url.split("/#").pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
        });
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loggerService.info(`Navigation vers [${event.url}] depuis [${this.router.url}]`);

        if(event.url.startsWith("/motdepasse/")) {
          this.motDePasseOublieService.ouvrirModale(event.url);
        }
      }
    });

    const token: string | null = localStorage.getItem('token');
    if (!token) {
      this.loggerService.warn(`Aucun token enregistré`);
      this.loginService.deconnexion();
      this.loginService.ouvrirModale();
    } else {
      this.loggerService.info("AppComponent.ngOnInit");
    
      this.plateformeService.configurer();
      if (this.appConfigService.modeClientFinal) this.verifierClientEtDepotActif();

      // On va voir si le token est toujours OK
      // si le token n'est plus valide, la modale de login va s'ouvrir car l'erreur sera interceptée par le AuthInterceptor
      this.loginService.testVie().subscribe({
        next: () => {
          this.loggerService.info("Le token est toujours valide");
          if (this.appConfigService.modeClientFinal) this.plateformeService.enregistrerAppareil();
          // this.synchroAutoService.auLancementAppli(); désactivé car expulse l'utilisateur s'il n'est pas connecté à internet
        },
        error: () => this.loggerService.info("Le token n'est plus valide"),
      });

      liveQuery(() => this.dbService.dexie.parametres_application
        .where("id")
        .anyOf([ParametreApplicationId.CLIENT_ACTIF, ParametreApplicationId.PANIER_ACTIF])
        .toArray()).subscribe((parametres: ParametreApplication<unknown>[]) => {
          for (const param of parametres) {
            switch (param.id) {
              case ParametreApplicationId.CLIENT_ACTIF:
                this.verifierExistenceClientActif(param.valeur as string);
                break;
              case ParametreApplicationId.PANIER_ACTIF:
                this.verifierExistencePanierActif(param.valeur as number);
                break;
            }
          }
        });
    }
  }

  public verifierExistenceClientActif(clientRef: string): void {
    if (!clientRef) {
      this.dbService.dexie.parametres_application.delete(ParametreApplicationId.CLIENT_ACTIF);
      return;
    }

    liveQuery(() => this.dbService.dexie.synchronisation_clients.get(clientRef)).subscribe((client) => {
      if (!client) {
        this.dbService.dexie.parametres_application.delete(ParametreApplicationId.CLIENT_ACTIF);
      }
    });
  }

  public verifierExistencePanierActif(panierRef: number): void {
    if (!panierRef) {
      this.dbService.dexie.parametres_application.delete(ParametreApplicationId.PANIER_ACTIF);
      return;
    }

    liveQuery(() => this.dbService.dexie.paniers_entetes.get(panierRef)).subscribe((panier) => {
      if (!panier) {
        this.dbService.dexie.parametres_application.delete(ParametreApplicationId.PANIER_ACTIF);
      }
    });
  }

  public async verifierClientEtDepotActif(): Promise<void> {
    const parametreClientActif = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.CLIENT_ACTIF);
    if (!parametreClientActif || !parametreClientActif.valeur) {
      const premierClient = await this.dbService.dexie.synchronisation_clients.toCollection().first();
      if (premierClient) {
        await this.dbService.dexie.transaction('rw!', this.dbService.dexie.parametres_application, async () => {
          await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.CLIENT_ACTIF, valeur: premierClient.tiers });
          await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.ENVIRONNEMENT_DEPOT, valeur: premierClient.depotRef });
        });
      } else {
        this.loggerService.error('Aucun client pour cet utilisateur !');
      }
    } else {
      const clientActif = await this.dbService.dexie.synchronisation_clients.get(parametreClientActif.valeur);
      if (clientActif) {
        await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.ENVIRONNEMENT_DEPOT, valeur: clientActif.depotRef });
      } else {
        this.loggerService.error("Le client actif n'est pas synchronisé !");
      }
    }
  }

  public estIphone(): boolean {
    return this.verifIphoneService.estIPhoneDixPlus();
  }

  public ouvrirAide(): void {
    if (this.modaleOuverte) {
      return;
    }
    this.modaleOuverte = true;
    const modalAide = this.modalService.open(AideModalComponent, { size: "lg" });
    modalAide.result.finally(() => {
      this.modaleOuverte = false;
    });
  }

  public flouterInformations(): void {
    this.articleService.afficherTarifDansListe = !this.articleService.afficherTarifDansListe;
  }

}
