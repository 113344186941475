import { dateAvecHeureDiva } from "src/app/tools/Fonctions/fonctions";
import { SynchronisationEvenement } from "../synchronisation/synchronisation";

export class EvenementApp {
  public idIndexedDB!: number;
  public id?: number;
  public libelle!: string;
  public priorite!: number;
  public dateDebut!: string;
  public dateFin?: string;
  public evenementUser!: string; // Utilisateur Divalto
  public evenementCode!: string // codeTache ou codeRdv dans paramKalico
  public tiersRef?: string;
  public contact?: string;
  public valeurRdv!: number;
  public valeurRealise!: number;
  public valeurAnnule!: number;
  public synchronisationEvenement!: number;
  public evenementNumero?: number;

  public utilisateurCreation?: string;
  public utilisateurModif?: string;
  public dateCreation!: string;
  public dateModif!: string;
  public supprimee!: boolean;
  public texteDescriptionhtml?: string;
  public description?: string;
  public etablissement?: string;

  constructor(
    estRdv: boolean,
    libelle: string,
    priorite: number,
    dateDebut: Date,
    dateFin: Date,
    tiersRef: string,
    texteDescriptionhtml?: string,
    dateCreation?: string,
    evenementCode?: string,
    evenementUser?: string,
    utilisateurCreation?: string,
    contact?: string,
    evenementNumero?: number,
    etablissement?: string,
  ) {
    this.valeurRdv = estRdv ? 1 : 3;
    this.libelle = libelle;
    this.priorite = priorite;
    this.dateDebut = dateDebut.toISOString();
    this.dateFin = dateFin?.toISOString() ?? "";
    this.tiersRef = tiersRef ?? "";
    this.dateCreation = dateCreation ?? (new Date().toString());
    this.evenementCode = evenementCode ?? "";
    this.evenementUser = evenementUser ?? "";
    this.utilisateurCreation = utilisateurCreation ?? "";

    this.valeurRealise = estRdv ? 3 : 1;
    this.texteDescriptionhtml = texteDescriptionhtml ?? "";
    this.description = texteDescriptionhtml ?? "";
    this.contact = contact ?? "";
    this.evenementNumero = evenementNumero ?? 0;

    const date = new Date();
    this.dateCreation = date.toISOString();
    this.dateModif = date.toISOString();
    this.supprimee = false;
    this.etablissement = etablissement ?? "";
  }

  public static fromEvenementSynchro(tache: SynchronisationEvenement): EvenementApp {
    const cls = new EvenementApp(false,
      tache.libelleEvenement,
      tache.priorite,
      dateAvecHeureDiva(tache.dateDebut, tache.heureDebut),
      new Date(tache.finEvenement),
      tache.tiersRef,
      tache.noteBlob,
      tache.dateCreation,
      tache.codeEvenementRef,
      tache.utilisateurEvenementRef,
      tache.utilisateurEvenementRef,
      tache.contactRef,
      tache.numeroEvenement,
      tache.etablissement);

    cls.valeurRdv = tache.indicationRdv;
    cls.valeurRealise = tache.indicationRealise;
    cls.valeurAnnule = tache.indicationAnnule;
    cls.dateCreation = tache.dateCreation;

    return cls;
  }
}

export class EvenementServeur {
  public id?: number;
  public libelle?: string;
  public priorite?: number;
  public dateDebut?: Date;
  public dateFin?: Date;
  public evenementUser?: string;
  public evenementCode?: string;
  public tiersRef?: string;
  public contact?: string;
  public valeurRdv?: number;
  public valeurRealise?: number;
  public synchronisationEvenement?: number;
  public evenementNumero?: number;
  public etablissement?: string;
}

export class SynchronisationEvenementAppDTO {
  public evenements!: EvenementApp[];
}

export class SynchronisationEvenementServeurDTO {
  public evenements!: EvenementServeur[];
}

