import { TypeEvenement } from "src/app/modules/fragments/modal-gestion-planning/modal-gestion-planning.component";
import { ProfilUtilisateur, ParametresUtilisateurCRM } from "../crm/crm";
import { LibelleTarifSynchronise, SynchronisationAdresseClient, SynchronisationExceptionTarifaire, SynchronisationUnite } from "../synchronisation/synchronisation";

export class SynchronisationQuotidienne {
  public PARAMETRES_CRM_UTILISATEUR!: ElementSynchronise<ParametresUtilisateurCRM>;
  public PROFIL_UTILISATEUR!: ElementSynchronise<ProfilUtilisateur>;
  public ENCOURS_VENTE!: ElementSynchronise<SynchronisationEncoursVente>;
  public ENCOURS_FACTURE!: ElementSynchronise<SynchronisationEncoursFacture>;
  public INDICATEURS_STAT!: ElementSynchronise<SynchronisationIndicateursStat>;
  public INDICATEURS_STAT_PROGRESSION!: ElementSynchronise<SynchronisationIndicateursStatProgression>;
  public STOCK_ARTICLES!: ElementSynchronise<SynchronisationStockArticle>;
  public DEPOTS!: ElementSynchronise<SynchronisationInfoDepot>;
  public DEPOTS_VISU!: ElementSynchronise<SynchronisationInfoDepot>;
  public COMMERCIAUX!: ElementSynchronise<SynchronisationInfoCommercial>;
  public FICJOINT_ART!: ElementSynchronise<SynchronisationFichierJoint>;
  public FICJOINT_CLI!: ElementSynchronise<SynchronisationFichierJoint>;
  public STAT_SIMPLE!: ElementSynchronise<SynchronisationStatSimple>;
  public REFERENCEMENT!: ElementSynchronise<SynchronisationReferencement>;
  public PLANNING!: ElementSynchronise<SynchronisationPlanning>;
  public TOURNEE!: ElementSynchronise<SynchronisationTournee>;
  public MOTIFS_GRATUITE!: ElementSynchronise<SynchronisationMotifsGratuite>;
  public FRAIS_SUPPLEMENTAIRES!: ElementSynchronise<SynchronisationFraisSupplementaires>;
  public LIBELLES_TARIFS!: ElementSynchronise<LibelleTarifSynchronise>;
  public UNITES!: ElementSynchronise<SynchronisationUnite>;
  public ADRESSES_CLIENTS!: ElementSynchronise<SynchronisationAdresseClient>;
  public EXCEPTIONS_TARIFAIRES!: ElementSynchronise<SynchronisationExceptionTarifaire>;
  public HISTORIQUE_ARTICLE!: ElementSynchronise<SynchronisationHistoriqueArticle>;
  public CLASSES_REMISES!: ElementSynchronise<SynchronisationClasseRemise>;
  public DECONSIGNES_ATTENTE!: ElementSynchronise<SynchronisationDeconsignesAttente>;
  public MISES_EN_AVANT!: ElementSynchronise<SynchronisationMiseEnAvant>;

  public SUPPRESSION_ARTICLES!: ElementSynchronise<SynchronisationSuppression>;
  public SUPPRESSION_CLIENTS!: ElementSynchronise<SynchronisationSuppression>;
  public SUPPRESSION_REMISES!: ElementSynchronise<SynchronisationSuppression>;
  public SUPPRESSION_REMISES_QUANTITE!: ElementSynchronise<SynchronisationSuppression>;
  public SUPPRESSION_RISTOURNES!: ElementSynchronise<SynchronisationSuppression>;
  public SUPPRESSION_ODRD!: ElementSynchronise<SynchronisationSuppression>;
  public SUPPRESSION_TARIFS_SPECIAUX!: ElementSynchronise<SynchronisationSuppression>;
}

export type DemandeSynchronisation = Partial<{ [key in keyof SynchronisationQuotidienne]: Date | string | null }>

export class ElementSynchronise<T> {
  public horodatage!: string;
  public valeurs!: T[]
  public cleSupression!: unknown;
}

export class SynchronisationEncoursFacture {
  public readonly clientRef!: string;
  public readonly encours!: EncoursFactureSynchronise[];
}

export class EncoursFactureSynchronise {
  public readonly etablissement!: string;
  public readonly clientRef!: string;
  public readonly libelle!: string;
  public readonly numeroPiece!: string;
  public readonly dateFacture!: string;
  public readonly dateEcheance!: string;
  public readonly codeLettrage!: string;
  public readonly dateLettrage!: string;
  public readonly modeReglement!: string;
  public readonly montant!: number;
  public readonly sens!: string;
  public readonly origine!: string;
  public selectionnee: boolean = false;
}

export class SynchronisationEncoursVente {
  public readonly clientRef!: string;
  public readonly encours!: EncoursVenteSynchronise[];
}

export class EncoursVenteSynchronise {
  public readonly documentEdite!: string;
  public readonly etablissement!: string;
  public readonly typePiece!: number;
  public readonly clientRef!: string;
  public readonly typePieceLibelle!: string;
  public readonly numeroPiece!: string;
  public readonly datePiece!: string;
  public readonly dateCreation!: string;
  public readonly dateLivraison!: string;
  public readonly dateEcheance!: string;
  public readonly modeReglement!: string;
  public readonly montant!: number;
  public readonly montantTva!: number;
  public readonly sens!: number;
  public readonly heureCreation!: string;
  public readonly creationCRM!: boolean;
  public selectionnee: boolean = false;
}

export class SynchronisationIndicateursStat {
  public annee!: number;
  public mois!: number;
  public clientRef!: string;
  public montant!: number;
  public marge!: number;
}

export class SynchronisationIndicateursStatProgression {
  public client!: string;
  public montant60j!: number;
  public montant60jA1!: number;
  constructor(client: string, annee?: number, anneePrecedente?: number) {
    this.client = client;
    this.montant60j = annee ?? 0
    this.montant60jA1 = anneePrecedente ?? 0
  }
}

export class SynchronisationStockArticle {
  public readonly articleRef!: string;
  public readonly depotRef!: string;
  public readonly qteStock!: number;
  public readonly qteStockReference!: number;
  public readonly qteStockReserve!: number;
}

export class SynchronisationInfoDepot {
  public readonly depotRef!: string;
  public readonly depotLibelle!: string;
  public readonly rue!: string;
  public readonly codePostal!: string;
  public readonly ville!: string;
  public readonly email!: string;
  public readonly telephone!: string;
  public readonly codeTarif!: string;
  public readonly etablissement!: string;
  public readonly indiceValidite!: number;
}

export class SynchronisationInfoCommercial {
  public readonly commercialRef!: string;
  public readonly commercialNom!: string;
}

export class SynchronisationFichierJoint {
  public readonly idIndexedDB!: number;
  public readonly reference!: string;
  public readonly codeJoint!: string;
  public readonly libelleFichier!: string;
  public readonly nomFichier!: string;
  public readonly tailleOctet!: number;
}

export class SynchronisationStatSimple {
  public readonly clientRef!: string;
  public readonly statistiques: StatSimpleSynchronise[] = [];
}

export class StatSimpleSynchronise {
  public readonly clientRef!: string;
  public readonly articleRef!: string;
  public qteReference?: number;
  public chiffreAffaire?: number;
  public marge?: number;
  public qteReferenceA1?: number;
  public chiffreAffaireA1?: number;
  public margeA1?: number;
}

export class SynchronisationReferencement {
  public readonly clientRef!: string;
  public readonly referencements!: ReferencementSynchronise[];
}

export class ReferencementSynchronise {
  public readonly clientRef!: string;
  public readonly articleRef!: string;
}

export class SynchronisationPlanning {
  public readonly planningId!: number;
  public readonly clientRef!: string;
  public typeEvenement!: TypeEvenement;
  public readonly dateReference!: string;
  public readonly utilisateurCreation!: string;
  public readonly dateCreation!: string;
  public dateEffective!: string;
  public heureEvenement!: string;
  public statutEvenement!: string;
  public commentaireEvenement!: string;
  public clientOuvert: boolean = true;
  public clientFeu: number = 1;

  public utilisateurModification!: string;
  public dateModification!: string;
  public nomClient?: string;
  public enseigne?: string;
  public modifier: boolean = false;
}

export class SynchronisationTournee {
  public touneeId!: number;
  public numero!: string;
  public libelle!: string;
  public dateCreation!: string;
  public userCreation!: string;
  public joursLivraison!: string;
  public heureLivraison!: string;
  public codeCamion!: string;
  public livreurPrincipal!: string;
  public livreurSecondaire!: string;
  public libelleCamion!: string;
  public immatCamion!: string;
  public tonnageCamion!: string;
  public nomLivreurPrincipal!: string;
  public nomLivreurSecondaire!: string;
}

export class SynchronisationMotifsGratuite {
  public motifId!: number;
  public motif!: string;
  public libelle!: string;
  public dateCreation!: string;
  public userCreation!: string;
  public motifType!: string;
  public avisDemande!: string;
}

export class SynchronisationHistoriqueArticle {
  public tiers!: string;
  public articleRef!: string;
  public montantH1!: number;
  public quantiteH1!: number;
  public uniteH1!: string;
  public dateH1!: string;
  public montantH2!: number;
  public quantiteH2!: number;
  public uniteH2!: string;
  public dateH2!: string;
  public montantH3!: number;
  public quantiteH3!: number;
  public uniteH3!: string;
  public dateH3!: string;
  public montantH4!: number;
  public quantiteH4!: number;
  public uniteH4!: string;
  public dateH4!: string;
  public montantH5!: number;
  public quantiteH5!: number;
  public uniteH5!: string;
  public dateH5!: string;
  public montantH6!: number;
  public quantiteH6!: number;
  public uniteH6!: string;
  public dateH6!: string;
  public montantH7!: number;
  public quantiteH7!: number;
  public uniteH7!: string;
  public dateH7!: string;
  public montantH8!: number;
  public quantiteH8!: number;
  public uniteH8!: string;
  public dateH8!: string;
  public montantH9!: number;
  public quantiteH9!: number;
  public uniteH9!: string;
  public dateH9!: string;
  public montantH10!: number;
  public quantiteH10!: number;
  public uniteH10!: string;
  public dateH10!: string;
}

export class SynchronisationFraisSupplementaires {
  public codeFraisFacturation!: string;
  public libelle!: string;
  public montant!: number;
}

export class SynchronisationClasseRemise {
  public id!: number;
  public classeRef!: string;
  public libelle!: string;
}

export class SynchronisationDeconsignesAttente {
  public tiers!: string;
  public montantDeconsignes!: number;
}

export class SynchronisationSuppression {
  public id!: number;
  public idStr!: string;
}

export interface SynchronisationMiseEnAvant {
  id: number;
  depotRef: string;
  familleClient: string;
  tiers: string;
  codeTarif: string;
  classeRemiseClient: string;
  type: string;
  dateEffet: string;
  dateFin: string | null;
  texteCourt: string | null;
  imagePresente: boolean;
  url: string | null;
  promoId: number | null;
  articleRef: string | null;
}
