<form *ngIf="!finalisation" [formGroup]="formulaireDemande" class="bg-primary">
  <svg class="visible-mobile" width="100%" height="100vh" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-81.989" y="502.449" width="100" height="200%" transform="rotate(-139.445 -81.989 502.449)" fill="black" />
    <rect
      x="-15.2586"
      y="327.007"
      width="120"
      height="200%"
      transform="rotate(-35.6961 -15.2586 327.007)"
      fill="black"
    />
  </svg>

  <svg class="visible-desktop" width="100%" height="100vh" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.55267"
      y="882.512"
      width="186.312"
      height="200%"
      transform="rotate(-138.506 7.55267 882.512)"
      fill="black"
    />
    <rect
      x="260.538"
      y="413.161"
      width="223.927"
      height="200%"
      transform="rotate(-34.5289 260.538 413.161)"
      fill="black"
    />
  </svg>

  <div class="formulaire modal-body p-0 d-flex flex-column align-items-center" (submit)="demanderReinitialisation()">
    <div class="flex-grow-1"></div>

    <div class="mb-3">
      <h1 class="text-light fw-bold">Mot de passe oublié</h1>
    </div>

    <div class="conteneur-formulaire-clientfinal px-3 py-4 d-flex flex-column gap-3 ombre rounded">
      <div class="input-group">
        <div class="input-group-text">
          <span class="fa fa-fw fa-at"></span>
        </div>
        <input type="email" class="form-control" id="username" formControlName="username" placeholder="Adresse mail" />
      </div>

      <button
        tabindex="1"
        type="submit"
        [disabled]="!formulaireDemande.valid || chargement"
        class="btn btn-secondary text-light align-self-center mt-2"
        (click)="demanderReinitialisation()"
      >
        <span *ngIf="!chargement" class="fa fa-fw fa-paper-plane me-2"></span>
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin me-2"></span>
        <span>Réinitialiser mon mot de passe</span>
      </button>
    </div>

    <div class="flex-grow-1"></div>
  </div>
</form>
<form *ngIf="finalisation" [formGroup]="formulaireFinalisation" class="bg-primary">
  <svg class="visible-mobile" width="100%" height="100vh" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-81.989" y="502.449" width="100" height="200%" transform="rotate(-139.445 -81.989 502.449)" fill="black" />
    <rect
      x="-15.2586"
      y="327.007"
      width="120"
      height="200%"
      transform="rotate(-35.6961 -15.2586 327.007)"
      fill="black"
    />
  </svg>

  <svg class="visible-desktop" width="100%" height="100vh" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.55267"
      y="882.512"
      width="186.312"
      height="200%"
      transform="rotate(-138.506 7.55267 882.512)"
      fill="black"
    />
    <rect
      x="260.538"
      y="413.161"
      width="223.927"
      height="200%"
      transform="rotate(-34.5289 260.538 413.161)"
      fill="black"
    />
  </svg>

  <div class="formulaire modal-body p-0 d-flex flex-column align-items-center" (submit)="finaliserReinitialisation()">
    <div class="flex-grow-1"></div>

    <div class="mb-3">
      <h1 class="text-light fw-bold">Réinitialiser son mot de passe</h1>
    </div>


    <div *ngIf="cleValide === null" class="text-light">
      <span class="fa fa-spinner fa-spin"></span> Chargement...
    </div>

    <div *ngIf="cleValide === false" class="text-light text-center">
      <p class="my-3">Le lien que vous avez suivi semble avoir expiré.</p>
      <button
        tabindex="1"
        class="btn btn-secondary text-light align-self-center mt-2"
        (click)="demanderANouveau()"
      >
        <span>Recevoir un nouveau lien</span>
      </button>
    </div>

    <div *ngIf="cleValide === true" class="conteneur-formulaire-clientfinal px-3 py-4 d-flex flex-column gap-3 ombre rounded">
      <div>
        <label for="new-password" class="form-label text-light">Nouveau mot de passe</label>
        <input type="password" class="form-control" id="new-password" formControlName="newPassword" autocomplete="new-password"/>
      </div>
      <div>
        <label for="new-password-again" class="form-label text-light">Répétez le mot de passe</label>
        <input type="password" class="form-control" id="new-password-again" formControlName="newPasswordAgain" autocomplete="off"/>
      </div>

      <div *ngIf="erreurCorrespondance && !chargement" class="invalid-feedback d-block text-danger mt-2">
        Les mots de passe ne correpondent pas.
      </div>

      <button
        tabindex="1"
        type="submit"
        [disabled]="!formulaireFinalisation.valid || chargement"
        class="btn btn-secondary text-light align-self-center mt-2"
        (click)="finaliserReinitialisation()"
      >
        <span *ngIf="!chargement" class="fa fa-fw fa-paper-plane me-2"></span>
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin me-2"></span>
        <span>Modifier mon mot de passe</span>
      </button>
    </div>

    <div class="flex-grow-1"></div>
  </div>
</form>
