import { Component, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type BoutonSupplementaire = {
  texte?: string;
  classe?: string;
  icone?: string;
  action: (activeModal: NgbActiveModal) => void;
};

export type ConfirmationOption = {
  titre?: string;
  corps?: TemplateRef<unknown> | string;
  delaiConfirmation?: number;
  classeBoutonOui?: string;
  classeBoutonNon?: string;
  iconeBoutonOui?: string;
  iconeBoutonNon?: string;
  texteOui?: string;
  texteNon?: string;
  afficherOui?: boolean;
  afficherNon?: boolean;
  boutonsSupplementaires?: BoutonSupplementaire[];
  retournerReference?: boolean;
  boutonDanger?: boolean;
};

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  public titre?: string;
  public corps?: TemplateRef<unknown> | string;
  public delaiConfirmation?: number;
  public decompteConfirmation?: number;
  public texteOui?: string;
  public texteNon?: string;
  public afficherOui = true;
  public afficherNon = true;
  public boutonsSupplementaires?: BoutonSupplementaire[];
  public boutonDanger = false;

  public classeBoutonOui = 'btn btn-primary';
  public classeBoutonNon = 'btn btn-danger';
  public iconeBoutonOui = 'fa fa-check';
  public iconeBoutonNon = 'fa fa-x';

  private timeout?: number;

  constructor(public activeModal: NgbActiveModal) {}

  public fermerOui(): void {
    if (this.timeout) window.clearTimeout(this.timeout);
    this.activeModal.close(null);
  }

  public fermerNon(): void {
    if (this.timeout) window.clearTimeout(this.timeout);
    this.activeModal.dismiss();
  }

  public lancerDecompte(): void {
    if (this.delaiConfirmation !== undefined && this.delaiConfirmation > 0) {
      this.decompteConfirmation = Math.ceil(this.delaiConfirmation / 1000);
      const interval = window.setInterval(() => {
        if (this.decompteConfirmation) this.decompteConfirmation--;
      }, 1000);
      this.timeout = window.setTimeout(() => {
        window.clearInterval(interval);
        this.fermerOui();
      }, this.delaiConfirmation);
    }
  }
}
