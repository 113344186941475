import { AfterContentInit, Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Directive({
  selector: '[appCommercial]'
})
export class CommercialDirective implements AfterContentInit {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private appConfigService: AppConfigService
  ) { }

  ngAfterContentInit(): void {
    if (this.appConfigService.modeCommercial) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
