import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-assistance',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-assistance.component.html',
  styleUrls: ['./modal-assistance.component.scss']
})
export class ModalAssistanceComponent {

  public mailObjetAssistance: string = "mailto:assistance@kalico-informatique.fr" + "?subject=Demande d'assistance";
  public mailObjetStandard: string = "mailto:contact@kalico-informatique.fr" + "?subject=Demande d'information";

  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  public fermer(): void {
    this.activeModal.close();
  }

}
