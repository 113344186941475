<nav class="menu bg-primary" [class.iphone]="estIphone()" (click)="gererCLic($event)">
  <ul class="menu__nav">
    <li class="menu__btn bg-white position-relative" role="button" (click)="clicSurMaison()" title="Accueil">
      <span class="fa fa-fw fa-house text-menu fs-3"></span>
    </li>
    <li *appClientFinal
      class="menu__btn bg-white position-relative" role="button" (click)="clicSurCatalogue()" title="Catalogue">
      <span class="fa fa-fw fa-book text-menu fs-3"></span>
    </li>
    <li *appCommercial>
      <button class="menu__btn bg-white" role="button" (click)="clicSurMenu()" title="Menu">
        <span class="fa fa-fw fa-bars text-menu fs-3"></span>
      </button>
      <div class="sousmenuwrap" [class.iphone]="estIphone()" *ngIf="afficherMenu" (click)="fermerSousMenu()">
        <div class="espaceur"></div>
        <div class="sousmenu">
          <div class="sousmenu__btn" role="button" routerLink="/">
            <span class="fa fa-fw fa-house text-primary fs-5"></span>
            <span>Accueil</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/clients">
            <span class="fa fa-fw fa-user text-primary fs-5"></span>
            <span>Clients</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/catalogue">
            <span class="fa fa-fw fa-book text-primary fs-5"></span>
            <span>Catalogue</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/evenements">
            <span class="fa fa-fw fa-tasks text-primary fs-5"></span>
            <span>Évènements</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/calendrier">
            <span class="fa fa-fw fa-calendar text-primary fs-5"></span>
            <span>Calendrier</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/paniers">
            <span class="fa fa-fw fa-basket-shopping text-primary fs-5"></span>
            <span>Paniers</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/statistiques">
            <span class="fa fa-fw fa-table text-primary fs-5"></span>
            <span>Statistiques</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/montants-dus">
            <span class="fa fa-fw fa-file-invoice-dollar text-primary fs-5"></span>
            <span>Dus</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/historique">
            <span class="fa fa-fw fa-history text-primary fs-5"></span>
            <span>Historique</span>
          </div>
          <div class="sousmenu__btn" role="button" routerLink="/crm/mon-compte">
            <span class="fa fa-fw fa-user-gear text-primary fs-5"></span>
            <span>Paramètres</span>
          </div>
          <div class="sousmenu__btn visible-mobile" role="button" (click)="synchroniser()">
            <span *ngIf="!synchro" class="fa fa-fw fa-refresh text-primary fs-5"></span>
            <span *ngIf="synchro" class="fa fa-fw fa-spinner fa-spin text-secondary fs-3"></span>
            <span>Mettre à jour</span>
          </div>
        </div>
      </div>
    </li>
    <li *appCommercial>
      <button class="menu__btn bg-white" role="button" (click)="clicSurMenuAdd()" title="Ajouter">
        <span class="fa fa-fw fa-plus text-menu fs-3"></span>
      </button>
      <div class="sousmenuwrap" [class.iphone]="estIphone()" *ngIf="afficherMenuAdd" (click)="fermerSousMenu()">
        <div class="espaceur"></div>
        <div class="sousmenu">
          <div class="sousmenu__btn" role="button" (click)="ajouterPanier()">
            <span class="fa fa-fw fa-basket-shopping text-primary fs-5"></span>
            <span>Panier</span>
          </div>
          <div *ngIf="parametresCrm?.valeur?.modificationContact" class="sousmenu__btn" role="button" (click)="ajouterContact()">
            <span class="fa fa-fw fa-user-plus text-primary fs-5"></span>
            <span>Contact</span>
          </div>
          <div class="sousmenu__btn" role="button" (click)="ajouterTache()">
            <span class="fa fa-fw fa-calendar-plus text-primary fs-5"></span>
            <span>Évènement</span>
          </div>
        </div>
      </div>
    </li>
    <li class="menu__btn bg-white position-relative" role="button" (click)="clicSurPanier()" title="Paniers">
      <span class="fa fa-fw fa-basket-shopping text-menu fs-3"></span>
      <span class="badge bg-success rounded-pill position-absolute nombre-paniers" *ngIf="nbPaniers > 0">{{nbPaniers}}</span>
    </li>
    <li class="menu__btn bg-white position-relative" role="button" (click)="synchroniser()" title="Paniers" *appClientFinal>
      <span *ngIf="!synchro" class="fa fa-fw fa-refresh text-menu fs-5"></span>
      <span *ngIf="synchro" class="fa fa-fw fa-spinner fa-spin text-secondary fs-3"></span>
    </li>
    <li *appCommercial class="menu__btn bg-white" role="button" (click)="clicSurUtilisateur()" title="Paramètres">
      <span class="fa fa-fw fa-user-gear text-menu fs-4 align"></span>
    </li>
    <li *appClientFinal>
      <button class="menu__btn bg-white" role="button" (click)="clicSurMenuCompte()" title="Mon compte">
        <span class="fa fa-fw fa-user text-menu fs-3"></span>
      </button>
      <div class="sousmenuwrap client-final gap-3" [class.iphone]="estIphone()" *ngIf="afficherMenuCompte" (click)="fermerSousMenu()">
        <div class="sousmenu__liste">
          <div class="btn-hz p-2" role="button" (click)="selectionnerClient(client)" *ngFor="let client of clientsDisponibles" [class.actif]="client.tiers === clientActif">
            <span class="fa fa-fw fa-store fs-5"></span>
            <span>{{ client.nomClient }}</span>
          </div>
        </div>
        <div class="sousmenu__liste">
          <div class="btn-hz p-2" role="button" (click)="clicSurUtilisateur()">
            <span class="fa fa-fw fa-cog fs-5"></span>
            <span>Paramètres</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</nav>
