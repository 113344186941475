import { UtilitaireGeneralService } from 'src/app/services/utilitaire/utilitaire-general.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametresUtilisateurCRM } from 'src/app/services/crm/crm';
import { DbService } from 'src/app/services/db/db.service';
import { liveQuery } from 'dexie';
import { SynchronisationEvenement } from 'src/app/services/synchronisation/synchronisation';
import { ParametreApplication, ParametreApplicationId } from 'src/app/services/db/parametre-application';
import { RouterModule } from '@angular/router';
import { KalTimestampPipe } from 'src/app/tools/pipes/kal-timestamp/kal-timestamp.pipe';
import { EvenementApp } from 'src/app/services/evenements/evenements';
@Component({
  selector: 'app-carrousel-evenements',
  standalone: true,
  imports: [CommonModule, RouterModule, KalTimestampPipe],
  templateUrl: './carrousel-evenements.component.html',
  styleUrls: ['./carrousel-evenements.component.scss']
})
export class CarrouselEvenementsComponent implements OnInit {


  public evenements: SynchronisationEvenement[] = [];
  public evenementsNonSynchronises: EvenementApp[] = [];
  public parametresCrm: ParametreApplication<ParametresUtilisateurCRM> | undefined;

  constructor(
    private dbService: DbService,
    public utilitaireGeneralService: UtilitaireGeneralService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.parametresCrm = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as ParametreApplication<ParametresUtilisateurCRM> | undefined;

    liveQuery(() => this.dbService.dexie.synchronisation_evenements.toArray()).subscribe((evenements) => {
      const codeUtilisateur = this.parametresCrm?.valeur?.codeUtilisateur?.toLowerCase();
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const filteredEvenements: SynchronisationEvenement[] = evenements
        .filter((evenement) => {
          const dateDebut = new Date(evenement.dateDebut);
          const evenementUtilisateur = evenement.utilisateurEvenementRef?.toLowerCase();
          const estRdv = this.estRdv(evenement);
          return (
            dateDebut >= today &&
            estRdv &&
            evenementUtilisateur === codeUtilisateur &&
            evenement.indicationAnnule !== 2
          );
        })
        .sort((a: SynchronisationEvenement, b: SynchronisationEvenement) => {
          const dateA = new Date(a.dateDebut);
          const dateB = new Date(b.dateDebut);
          if (dateA.getTime() === dateB.getTime()) {
            const heureA = parseInt(a.heureDebut);
            const heureB = parseInt(b.heureDebut);
            return heureA - heureB;
          }
          return dateA.getTime() - dateB.getTime();
        })
        .slice(0, 10);
      this.evenements = filteredEvenements;
    });

    liveQuery(() =>
      this.dbService.dexie.evenements
        .filter((evenement) => {
          const aujourdhui = new Date();
          aujourdhui.setHours(0, 0, 0, 0);
          const dateDebut = new Date(evenement.dateDebut);
          dateDebut.setHours(0, 0, 0, 0);
          return (
            dateDebut.getTime() === aujourdhui.getTime() &&
            evenement.evenementCode === "kalRdv"
          );
        })
        .toArray()
    ).subscribe((listeEvenementNonSynchronises) => {
      this.evenementsNonSynchronises = listeEvenementNonSynchronises;
    })
  }

  public conversionMois(mois: number): string {
    switch (mois) {
      case 0: return "Janv.";
      case 1: return "Fév.";
      case 2: return "Mars";
      case 3: return "Avril";
      case 4: return "Mai";
      case 5: return "Juin";
      case 6: return "Juil.";
      case 7: return "Août";
      case 8: return "Sept.";
      case 9: return "Oct.";
      case 10: return "Nov.";
      default: return "Déc.";
    }
  }

  public zeroManquant(nombre: number): string {
    return nombre < 10 ? `0${nombre}` : `${nombre}`;
  }

  public scrollHorizontal(ev: Event, ct: HTMLElement): void {
    ev.preventDefault();
    const evenement = ev as WheelEvent;
    ct.scrollLeft += evenement.deltaY;
  }

  private estRdv(tache: SynchronisationEvenement): boolean {
    return (tache.indicationRealise === 3 && tache.indicationRdv === 1)
      || (tache.indicationRealise === 3 && tache.indicationRdv === 2);
  }

}
