import { Injectable } from '@angular/core';
import { ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { SynchronisationService } from '../synchronisation/synchronisation.service';
import { ToastService } from '../toast/toast.service';
import { DonneesService } from '../donnees/donnees.service';
import packageJson from 'package.json';

const DELAI_RESYNCHRO = 12 /* h */ * 60 /* min */ * 60 /* s */ * 1000; /* ms */

@Injectable({
  providedIn: 'root',
})
export class SynchronisationAutoService {
  public constructor(
    private dbService: DbService,
    private synchronisationService: SynchronisationService,
    private donneesService: DonneesService,
    private toastService: ToastService
  ) {}

  public async auLancementAppli(): Promise<void> {
    const parametreDerniereSynchroVersion = await this.dbService.dexie.parametres_application.get(
      ParametreApplicationId.META_DERNIERE_SYNCHRO_VERSION
    );
    const parametreDerniereSynchroHorodatage = await this.dbService.dexie.parametres_application.get(
      ParametreApplicationId.META_DERNIERE_SYNCHRO_HORODATAGE
    );

    const nouvelleVersion = parametreDerniereSynchroVersion?.valeur !== packageJson.version;
    const horodatageDerniereSynchro = parametreDerniereSynchroHorodatage ? Number(parametreDerniereSynchroHorodatage.valeur) : undefined;

    // jamais synchronisé OU horodatage invalide OU dernière synchro date de plus de 12h OU dernière synchro faite avant la derniere version
    if (
      horodatageDerniereSynchro === undefined || 
      Number.isNaN(horodatageDerniereSynchro) ||
      horodatageDerniereSynchro + DELAI_RESYNCHRO < Date.now() ||
      nouvelleVersion 
    ) {
      this.synchronisationService.synchronisationTotaleEnCours = true;

      try {
        await this.donneesService.synchroniser();
        this.toastService.afficher({
          entete: 'Synchronisation incrémentielle',
          corps: 'Synchronisation terminée',
        });
      } catch {
        this.toastService.afficher({
          entete: 'Synchronisation incrémentielle',
          corps: 'Erreur lors de la synchronisation',
        });
      }

      try {
        await this.donneesService.synchroniserQtd();

        this.toastService.afficher({
          entete: 'Synchronisation quotidienne',
          corps: 'Synchronisation terminée',
        });
      } catch {
        this.toastService.afficher({
          entete: 'Synchronisation quotidienne',
          corps: 'Erreur lors de la synchronisation',
        });
      }

      this.synchronisationService.synchronisationTotaleEnCours = false;
    }
  }
}
