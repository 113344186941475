import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../configuration/app-config.service';
import { AppCustomisationConfig } from '../configuration/AppCustomisationConfig';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public urlBase: string = environment.parametreSpecifique.urlBaseServeurERP;
  public urlBaseRessources: string = (environment.parametreSpecifique as AppCustomisationConfig).urlBaseServeurRessources ?? this.urlBase;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService,
  ) {
    this.urlBase = this.appConfigService.getConfig().urlBaseServeurERP;
    this.urlBaseRessources = this.appConfigService.getConfig().urlBaseServeurRessources ?? this.urlBase;
  }

  public getFromApi<T>(url: string): Observable<HttpResponse<T>> {
    return this.http.get<T>(this.urlBase + url, {
      observe: 'response'
    });
  }

  public getBlobFromApi(url: string, parametresQuery?: HttpParams): Observable<HttpResponse<Blob>> {
    return this.http.get(this.urlBase + url, {
      observe: 'response', responseType: 'blob', params: parametresQuery
    });
  }

  public postBlobFromApi(url: string, body: unknown, parametresQuery?: HttpParams): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(this.urlBase + url, body, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params: parametresQuery
    });
  }

  public postFromApi<T>(url: string, data: unknown): Observable<HttpResponse<T>> {
    return this.http.post<T>(this.urlBase + url, data, {
      observe: 'response'
    });
  }

  public async postFromApiDiscret<T>(url: string, data: unknown): Promise<T> {
    //utilisation directe de l'API fetch pour contourner Angular
    const headers = new Headers({'Content-Type': 'application/json'});
    
    const token = this.authService.recupererToken();
    if (token) headers.append('Authorization', 'Bearer ' + token);

    const response = await fetch(this.urlBase + url, {
      body: JSON.stringify(data),
      method: 'POST',
      headers,
    });
    if (!response.ok) throw response;
    try {
      return await response.json() as T;
    } catch (err) {
      return undefined as T;
    }
  }
}
