import { EventEmitter, Injectable } from '@angular/core';
import { DemandeSynchronisation, StatusTache, SynchronisationEvenement } from '../synchronisation/synchronisation';
import { EvenementApp, SynchronisationEvenementAppDTO, SynchronisationEvenementServeurDTO } from './evenements';
import { DbService } from '../db/db.service';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { CommercialDTO, ParametresUtilisateurCRM } from '../crm/crm';
import { HttpService } from '../http/http.service';
import { SynchronisationService } from '../synchronisation/synchronisation.service';
import { EnvironnementApplicationService } from '../environnement-application/environnement-application.service';
import { Subject, firstValueFrom, from, lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { liveQuery, Subscription } from 'dexie';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EvenementsService {

  public rechargementListe = new Subject<void>();

  private evenementsSubscription: Subscription | undefined;
  public evenements: SynchronisationEvenement[] = [];

  public afficherTousLesEvenements: boolean = false

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private httpService: HttpService,
    private synchronisationService: SynchronisationService,
    private environnementApplicationService: EnvironnementApplicationService,
  ) {}

  private async recupererInfosCRM(): Promise<ParametresUtilisateurCRM | undefined> {
    const parametresCrm = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as ParametreApplication<ParametresUtilisateurCRM> | undefined;
    return parametresCrm?.valeur;
  }

  public async validerEvenement(creationEvenement: EvenementApp, estRdv: boolean, bloquerEnvoi?: boolean): Promise<void> {
    const paramsCrm: ParametresUtilisateurCRM | undefined = await this.recupererInfosCRM();
    const paramsUtilisateur: CommercialDTO | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.COMMERCIAL_ACTIF);
    if (!paramsCrm || !paramsUtilisateur) return;
    const evenement: EvenementApp = {
      ...creationEvenement,
      evenementCode: estRdv ? paramsCrm.codeRdv : paramsCrm.codeTache,
      evenementUser: paramsCrm.codeUtilisateur,
      utilisateurCreation: paramsUtilisateur.utilisateurKali,
      utilisateurModif: paramsUtilisateur.utilisateurKali
    }
    await this.dbService.dexie.evenements.put(evenement);

    const token = this.authService.recupererToken();
    const estConnecte = navigator.onLine;
    if(token && estConnecte && !bloquerEnvoi) {
      this.synchroniserErp();
    }
  }

  public async enregistrerEvenement(tache: SynchronisationEvenement, emitter: EventEmitter<boolean>): Promise<void> {
    const paramsUtilisateur: CommercialDTO | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.COMMERCIAL_ACTIF);
    const evenement = EvenementApp.fromEvenementSynchro(tache);
    evenement.utilisateurModif = paramsUtilisateur?.utilisateurKali;
    evenement.dateModif = new Date().toISOString();
    evenement.evenementUser = tache.utilisateurEvenementRef;
    evenement.utilisateurCreation = tache.utilisateurEvenementRef;
    evenement.tiersRef = tache.tiersRef;

    await this.dbService.dexie.evenements.put(evenement);
    this.synchroniserErp(emitter);
  }

  public async synchroniserErp(emitter?: EventEmitter<boolean>): Promise<void> {
    if (this.synchronisationService.synchronisationEnCours) return;
    this.synchronisationService.synchronisationEnCours = true;

    const evenements: EvenementApp[] = await this.dbService.dexie.evenements.toArray();
    if (!evenements || evenements.length == 0) {
      const horodatageEvenements = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_EVENEMENTS) as ParametreApplication<string> | undefined;
      const dtoSynchro: DemandeSynchronisation = { EVENEMENTS: horodatageEvenements?.valeur ?? null };
      try {
        await this.synchronisationService.synchroniser(dtoSynchro);
        this.synchronisationService.synchronisationEnCours = false;
      } catch (error) {
        this.synchronisationService.synchronisationEnCours = false;
      }
      return;
    }

    const dto = new SynchronisationEvenementAppDTO();
    dto.evenements = evenements.concat().filter(event => !event.supprimee);

    try {
      const reponse: HttpResponse<SynchronisationEvenementServeurDTO> = await lastValueFrom(this.httpService.postFromApi<SynchronisationEvenementServeurDTO>(("business/crm/evenement"), dto));
      if (reponse.body) {
        this.suppressionEvenementTemporaire(dto);
        const horodatageEvenements = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_EVENEMENTS) as ParametreApplication<string> | undefined;
        const dtoSynchro: DemandeSynchronisation = { EVENEMENTS: horodatageEvenements?.valeur ?? null };
        await this.synchronisationService.synchroniser(dtoSynchro);

        this.synchronisationService.synchronisationEnCours = false;
        this.rechargementListe.next();
        if (emitter) emitter.emit(true);
      }
    } catch (e) {
      this.synchronisationService.synchronisationEnCours = false;
      if (emitter) emitter.emit(false);
    }
  }

  private suppressionEvenementTemporaire(dto: SynchronisationEvenementAppDTO) {
    dto.evenements.forEach(element => {
      this.dbService.dexie.evenements.delete(element.idIndexedDB);
    });

  }

  public getStatusLibelle(tache: SynchronisationEvenement | undefined): string | undefined {
    if (!tache) return;
    const rdv = tache.indicationRdv; const realise = tache.indicationRealise;

    if (realise === StatusTache["RDV_A_CONFIRMER"].realise && rdv === StatusTache["RDV_A_CONFIRMER"].rdv) {
      return StatusTache["RDV_A_CONFIRMER"].libelle;
    }
    if (realise === StatusTache["RDV_CONFIRMER"].realise && rdv === StatusTache["RDV_CONFIRMER"].rdv) {
      return StatusTache["RDV_CONFIRMER"].libelle;
    }
    if (realise === StatusTache["TACHE_A_REALISER"].realise && rdv === StatusTache["TACHE_A_REALISER"].rdv) {
      return StatusTache["TACHE_A_REALISER"].libelle;
    }
    if (realise === StatusTache["TACHE_REALISER"].realise && rdv === StatusTache["TACHE_REALISER"].rdv) {
      return StatusTache["TACHE_REALISER"].libelle;
    }
    if (realise === StatusTache["AUTRE"].realise && rdv === StatusTache["AUTRE"].rdv) {
      return StatusTache["AUTRE"].libelle;
    }

    return "Aucun status";
  }

  public getPrioriteClasse(priorite: number): string {
    switch (priorite) {
      case 1: // Élevée
        return "bg-danger";
      case 3: // Normal
        return "bg-secondary";
      case 5: // Faible
        return "bg-warning";
      case 7: // Très faible
        return "bg-success";
      case 9: // Aucune
        return "bg-info";
      default:
        return "bg-secondary";
    }
  }

  public getPrioriteLibelle(priorite: number): string {
    switch (priorite) {
      case 1:
        return "Élevée";
      case 3:
        return "Normale";
      case 5:
        return "Faible";
      case 7:
        return "Très faible";
      case 9:
        return "Aucune";
      default:
        return "Aucune priorité trouvé";
    }
  }

  public getTypeEvenementClasse(tache: SynchronisationEvenement): string {
    const rdv = tache.indicationRdv; const realise = tache.indicationRealise;

    if ((realise === StatusTache["RDV_A_CONFIRMER"].realise && rdv === StatusTache["RDV_A_CONFIRMER"].rdv)
      || (realise === StatusTache["RDV_CONFIRMER"].realise && rdv === StatusTache["RDV_CONFIRMER"].rdv)) {
      return "fa fa-fw fa-calendar";
    }
    if ((realise === StatusTache["TACHE_A_REALISER"].realise && rdv === StatusTache["TACHE_A_REALISER"].rdv)
      || (realise === StatusTache["TACHE_REALISER"].realise && rdv === StatusTache["TACHE_REALISER"].rdv)) {
      return "fa fa-fw fa-thumbtack";
    }
    return "fa fa-fw fa-list-check";
  }

  public getTypeEvenenementNonSynchronise(evenement: EvenementApp): string {
    if(evenement.evenementCode === "kalTache") {
      return "fa fa-fw fa-thumbtack";
    }
    else if (evenement.evenementCode === "kalRdv") {
      return "fa fa-fw fa-calendar"
    }
    else {
      return "fa fa-fw fa-list-check";
    }
  }

  public estTache(evenement: SynchronisationEvenement): boolean {
    return (evenement.indicationRealise === StatusTache["TACHE_A_REALISER"].realise && evenement.indicationRdv === StatusTache["TACHE_A_REALISER"].rdv)
      || (evenement.indicationRealise === StatusTache["TACHE_REALISER"].realise && evenement.indicationRdv === StatusTache["TACHE_REALISER"].rdv);
  }

  public estRdv(evenement: SynchronisationEvenement): boolean {
    return (evenement.indicationRealise === StatusTache["RDV_A_CONFIRMER"].realise && evenement.indicationRdv === StatusTache["RDV_A_CONFIRMER"].rdv)
      || (evenement.indicationRealise === StatusTache["RDV_CONFIRMER"].realise && evenement.indicationRdv === StatusTache["RDV_CONFIRMER"].rdv);
  }

  public estAutre(evenement: SynchronisationEvenement): boolean {
    return (evenement.indicationRealise === StatusTache["AUTRE"].realise && evenement.indicationRdv === StatusTache["AUTRE"].rdv);
  }

  public async recupererEvenements(tousLesEvenements: boolean, clientRef: string): Promise<SynchronisationEvenement[]> {
    if (this.evenementsSubscription) {
      this.evenementsSubscription.unsubscribe();
    }

    const observable = from(
      liveQuery(() =>
        this.dbService.dexie.synchronisation_evenements
          .where('tiersRef').equals(clientRef)
          .filter(evenement =>
            tousLesEvenements || (!tousLesEvenements
              && (
                (evenement.indicationRealise === 3 && evenement.indicationRdv === 1) ||
                (evenement.indicationRealise === 1 && evenement.indicationRdv === 3) ||
                (evenement.indicationRealise === 3 && evenement.indicationRdv === 3)
              )
              && evenement.indicationAnnule !== 2)
          )
          .toArray()

      )
    );

    return firstValueFrom(observable);
  }

  public async recupererEvenementsNonSynchronises(clientRef: string): Promise<EvenementApp[]> {
    if (this.evenementsSubscription) {
      this.evenementsSubscription.unsubscribe();
    }

    const observable = from(
      liveQuery(() =>
        this.dbService.dexie.evenements
          .where('tiersRef').equals(clientRef)
          .toArray()
      )
    );

    return firstValueFrom(observable);
  }



  public filtrerEvenements(evenements: SynchronisationEvenement[]): SynchronisationEvenement[] {
    return evenements.filter(evenement =>
      (
        (evenement.indicationRealise === 3 && evenement.indicationRdv === 1) ||
        (evenement.indicationRealise === 1 && evenement.indicationRdv === 3) ||
        (evenement.indicationRealise === 3 && evenement.indicationRdv === 3)
      ) && evenement.indicationAnnule !== 2
    );
  }

}
