import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, firstValueFrom } from 'rxjs';
import { SynchronisationClient } from 'src/app/services/synchronisation/synchronisation';
import { ClientService } from 'src/app/services/clients/clients.service';
import { EnvironnementApplicationService } from 'src/app/services/environnement-application/environnement-application.service';
import { UtilitaireGeneralService } from 'src/app/services/utilitaire/utilitaire-general.service';
import { ParametreApplicationId } from 'src/app/services/db/parametre-application';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';
import { RechercheClientModalComponent } from '../recherche-client-modal/recherche-client-modal.component';
import { DbService } from 'src/app/services/db/db.service';

@Component({
  selector: 'app-recherche-client',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RechercheClientModalComponent],
  templateUrl: './recherche-client.component.html',
  styleUrls: ['./recherche-client.component.scss']
})
export class RechercheClientComponent implements OnInit {
  @Input() public placeholder: string = "Rechercher un client";
  public _client: SynchronisationClient | null = null;
  @Output() public clientChange: EventEmitter<SynchronisationClient | null> = new EventEmitter<SynchronisationClient | null>();
  @Input() public set client(client: SynchronisationClient | null) {
    this._client = client;
    if (client) this.recherche.setValue(client.nomClient, { emitEvent: false });
    this.clientChange.emit(client);
  }
  public get client(): SynchronisationClient | null {
    return this._client;
  }

  public modalRecherche: NgbModalRef | undefined = undefined;

  public chargement: boolean = false;
  public recherche: FormControl<string | null> = new FormControl("");
  public rechercheChange = this.recherche.valueChanges.pipe(debounceTime(500));
  public elementsTrouvees: SynchronisationClient[] = [];

  constructor(
    private clientsService: ClientService,
    private environnementApplicationService: EnvironnementApplicationService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    private modalService: ModalService,
    private dbService: DbService,
  ) {  }

  ngOnInit(): void {
    this.rechercheChange.subscribe((recherche: string | null) => {
      const valeurRecherche: string | undefined = recherche?.trim();
      if (!valeurRecherche) {
        this.elementsTrouvees = [];
        this.client = null;
        return;
      }

      this.chargement = true;
      setTimeout(async () => {
        this.elementsTrouvees = [];
        const [clients, commercialActif] = await Promise.all([
          firstValueFrom(this.clientsService.clientsChange),
          firstValueFrom(this.environnementApplicationService.getParametreReactifBrut(ParametreApplicationId.ENVIRONNEMENT_COMMERCIAL)),
        ]);
        this.elementsTrouvees = clients.filter((client: SynchronisationClient) => {
          return (
            (valeurRecherche ? (
              this.utilitaireGeneralService.strContientSouple(client.nomClient, valeurRecherche) ||
              this.utilitaireGeneralService.strContientSouple(client.nomAbrege, valeurRecherche) ||
              this.utilitaireGeneralService.strContientSouple(client.enseigne, valeurRecherche) ||
              this.utilitaireGeneralService.strContientSouple(client.tiers, valeurRecherche)
            ) : true)
            && (
              commercialActif?.valeur ? (
                client.commercial1Ref === commercialActif?.valeur ||
                client.commercial2Ref === commercialActif?.valeur ||
                client.commercial3Ref === commercialActif?.valeur
              ) : true)
              && !client.dateFin && client.feu !== 3
          )
        }).sort((a, b) => {
          return `${a.titreRef} ${a.nomClient}`.localeCompare(`${b.titreRef} ${b.nomClient}`);
        });

        if (this.elementsTrouvees.length === 1) this.client = this.elementsTrouvees[0];
        else if (this.elementsTrouvees.length > 1) this.ouvrirSelectionClient();
        else this.client = null;
        this.chargement = false;
      }, 0);

      localStorage.setItem('avertissementMontant', "");
    });

  }

  public viderRecherche(): void {
    this.recherche.setValue("");
    localStorage.setItem('avertissementMontant', "");
    this.dbService.dexie.parametres_application.delete(ParametreApplicationId.CLIENT_ACTIF);
  }

  public fermer(client?: SynchronisationClient): void {
    this.modalRecherche?.close(client);
  }

  public ouvrirSelectionClient(): void {
    if (this.modalRecherche) return;
    const modalRef: NgbModalRef = this.modalService.open(RechercheClientModalComponent);
    modalRef.componentInstance.rechercheComposant = this.recherche.value;
    this.modalRecherche = modalRef;
    modalRef.result
      .then((client: SynchronisationClient | undefined) => {
        this.client = client || null;
        if(this.client == null ) this.recherche.reset();
        this.modalRecherche = undefined;
      })
  }
}
