// db.ts
import { Injectable } from '@angular/core';
import { SchemaBDD, db } from './db';
import Dexie from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  public async export(): Promise<string> {
    const tables = this.dexie.tables;
    const exportData: { [index: string]: unknown } = {};
    for (const table of tables) {
      exportData[table.name] = await table.toArray();
    }

    return JSON.stringify(exportData);
  }

  public async import(data: string, nettoyerAvant?: boolean): Promise<void> {
    const tables = this.dexie.tables;
    const importData = JSON.parse(data);
    for (const table of tables) {
      if (nettoyerAvant) {
        await table.clear();
      }
      await table.bulkAdd(importData[table.name]);
    }
  }

  public async vider(): Promise<Dexie> {
    localStorage.clear();
    return this.dexie.delete().then(() => this.dexie.open());
  }

  public async viderSansDeconnexion(): Promise<Dexie> {
    return this.dexie.delete().then(() => this.dexie.open());
  }

  public get dexie(): SchemaBDD {
    return db;
  }
}
