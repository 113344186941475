import { Injectable } from '@angular/core';

import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { ToastService } from '../toast/toast.service';
import { Toast } from '../toast/toast';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { LoggerService } from '../log/logger.service';
@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    constructor(
        private swUpdates: SwUpdate,
        private toastService: ToastService,
        private confirmationService: ConfirmationService,
        private loggerService: LoggerService,
    ) { this.init(); }

    public init(): void {
        if (!this.swUpdates.isEnabled) {
            this.loggerService.warn("Le service de mise à jour n'est pas activé/disponible.");
            return;
        } else {
            this.loggerService.info("Le service de mise à jour est activé/disponible.");
        }

        this.swUpdates.versionUpdates.subscribe((evenement: VersionEvent) => {
            switch (evenement.type) {
                case 'VERSION_DETECTED':
                    this.loggerService.info(`Downloading new app version: ${evenement.version.hash}`);
                    this.toastService.afficher(new Toast("Téléchargement de la nouvelle version", evenement.version.hash));
                    break;
                case 'VERSION_READY':
                    this.loggerService.info(`Current app version: ${evenement.currentVersion.hash}`);
                    this.loggerService.info(`New app version ready for use: ${evenement.latestVersion.hash}`);
                    this.toastService.afficher(new Toast("Nouvelle version prête à être installée", evenement.latestVersion.hash));
                    this.confirmationService.afficher({
                        titre: "Nouvelle version disponible",
                        corps: "Une nouvelle version de l'application est disponible, l'application va redémarrer pour installer la nouvelle version.",
                        delaiConfirmation: 10000,
                        texteOui: "OK",
                        texteNon: "Plus tard",
                        iconeBoutonOui: "",
                        iconeBoutonNon: "",
                    }).then(() => window.location.reload())
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    this.loggerService.error(`Failed to install app version '${evenement.version.hash}': ${evenement.error}`);
                    this.toastService.afficher(new Toast("Echec de l'installation de la nouvelle version", evenement.version.hash));
                    break;
            }
        });
    }
}
