import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoggerService } from 'src/app/services/log/logger.service';

@Injectable()
export class LoggerInterceptor implements HttpInterceptor {

  constructor(
    private loggerService: LoggerService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loggerService.info(`Requête HTTP(${request.method}) vers : ${request.urlWithParams}`);
    if (request.body != undefined) {
      if(request.headers.get('Content-Type')?.includes('application/json')) {
        this.loggerService.verbose(() => `Requête HTTP(${request.method}) vers : ${request.urlWithParams}; corps :\n${JSON.stringify(request.body)}`);
      }
    }

    const debut = Date.now();
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.loggerService.info(`Réponse HTTP(${request.method}) vers : ${request.urlWithParams} en ${Date.now() - debut}ms`);
          if(event.body != undefined) {
            if(event.headers.get('Content-Type')?.includes('application/json')) {
              this.loggerService.verbose(() => `Réponse HTTP(${request.method}) vers : ${request.urlWithParams}; corps :\n${JSON.stringify(event.body).substring(0, 5000)}`);
            }
          }
        }
      })
    );
  }
}
