import { Device, DeviceInfo } from '@capacitor/device';

export interface CRMEnregistrementAppareilDTO {
  identifiantAppareil: string;
  plateforme: number;
  infosComplementaires: InformationsComplementairesAppareil;
  versionCRM: string;
  jetonFirebaseMessaging?: string;
}

export interface InformationsComplementairesAppareil {
  typeSystemeExploitation?: string;
  versionSystemeExploitation?: string;
  fabricant?: string;
  modele?: string;
  versionSDKAndroid?: number;
}

export async function recupererIdentifiantAppareil(): Promise<string> {
  const resultat = await Device.getId();
  return 'CAP-' + resultat.identifier;
}

export async function recupererInformationsComplementairesAppareil(): Promise<InformationsComplementairesAppareil> {
  const resultat = await Promise.any([Device.getInfo(), new Promise((resolve) => window.setTimeout(() => resolve({}), 3000))]) as Partial<DeviceInfo>;
  return {
    typeSystemeExploitation: resultat.operatingSystem,
    versionSystemeExploitation: resultat.osVersion,
    fabricant: resultat.manufacturer,
    modele: resultat.model,
    versionSDKAndroid: resultat.androidSDKVersion,
  };
}
