import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../modal/modal.service';
import { ConfirmationComponent, ConfirmationOption } from './confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(
    private modalService: ModalService,
  ) { }

  // surcharge de la méthode afficher pour retourner une référence de la modale
  public afficher(options: ConfirmationOption & { retournerReference: true }): NgbModalRef;
  
  // surcharge de la méthode afficher pour retourner une promesse
  public afficher(options: ConfirmationOption): Promise<unknown>;

  // implémentation de la méthode afficher
  public afficher(options: ConfirmationOption): Promise<unknown> | NgbModalRef {
    const ref: NgbModalRef = this.modalService.open(ConfirmationComponent);
    for (const [cle, valeur] of Object.entries(options)) {
      ref.componentInstance[cle] = valeur;
    }

    ref.componentInstance.lancerDecompte();

    if (options.retournerReference) {
      return ref;
    } else {
      return ref.result;
    }
  }
}
