<form [formGroup]="formulaire" class="bg-primary">
  <svg *appCommercial class="visible-mobile" width="100%" height="100vh" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-81.989" y="502.449" width="100" height="200%" transform="rotate(-139.445 -81.989 502.449)" fill="black" />
    <rect
      x="-15.2586"
      y="327.007"
      width="120"
      height="200%"
      transform="rotate(-35.6961 -15.2586 327.007)"
      fill="black"
    />
  </svg>

  <svg *appCommercial class="visible-desktop" width="100%" height="100vh" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.55267"
      y="882.512"
      width="186.312"
      height="200%"
      transform="rotate(-138.506 7.55267 882.512)"
      fill="black"
    />
    <rect
      x="260.538"
      y="413.161"
      width="223.927"
      height="200%"
      transform="rotate(-34.5289 260.538 413.161)"
      fill="black"
    />
  </svg>

  <div *appClientFinal class="fond-vide"></div>

  <div *appCommercial class="formulaire modal-body p-0 d-flex flex-column align-items-center p-2" (submit)="login()">
    <div class="flex-grow-1"></div>

    <img src="./assets/logo/logotexte.png" alt="Kalico CRM" class="mb-3" />

    <div class="d-flex justify-content-center">
      <h4 class="bg-secondary text-center px-3 pt-1 rounded-top">CONNEXION</h4>
    </div>
    <div class="conteneur-formulaire px-3 py-4 d-flex flex-column gap-3 ombre rounded">
      <div class="input-group" [class.contour-rouge]="loginEchoue">
        <div class="input-group-text">
          <span class="fa fa-fw fa-user"></span>
        </div>
        <input type="text" class="form-control" id="username" formControlName="username" placeholder="Utilisateur" />
      </div>

      <div class="input-group" [class.contour-rouge]="loginEchoue">
        <div class="input-group-text">
          <span class="fa fa-fw fa-lock"></span>
        </div>
        <input
          type="password"
          class="form-control"
          id="password"
          formControlName="password"
          placeholder="Mot de passe"
        />
      </div>
    </div>

    <div *ngIf="loginEchoue" class="text-danger mt-2">Identifiant ou mot de passe incorrect.</div>
    <div *ngIf="!appareilAutorise" class="text-danger mt-2">Autorisation requise pour cet appareil.</div>
    <div *ngIf="!appareilAutorise" class="text-danger mt-2">Veuillez contacter votre administrateur.</div>

    <div class="mt-3">
      <button
        tabindex="1"
        type="submit"
        [disabled]="!formulaire.valid || chargement"
        class="btn btn-secondary text-light text-center"
        (click)="login()"
      >
        <span *ngIf="!chargement" class="fa fa-fw fa-paper-plane me-2"></span>
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin me-2"></span>
        <span>VALIDER</span>
      </button>
    </div>
    <div *ngIf="utilisateur" class="mt-3">
      <button class="btn btn-secondary text-light text-center" (click)="passerLogin()">
        <span class="fa fa-fw fa-laptop me-2"></span>
        <span>HORS LIGNE</span>
      </button>
    </div>

    <div class="flex-grow-1"></div>

    <div class="d-flex justify-content-center gap-2">
      <a [href]="numeroAssistance" class="btn btn-secondary text-light">
        <span class="fa fa-fw fa-phone"></span>
      </a>

      <a href="https://get.teamviewer.com/kalico" target="_blank" class="btn btn-secondary text-light">
        Assistance téléphonique
      </a>
    </div>
  </div>

  <div *appClientFinal class="formulaire modal-body p-0 d-flex flex-column align-items-center" (submit)="login()">
    <div class="flex-grow-1"></div>

    <div class="mb-3 px-3 formulaire-logo">
      <img src="./assets/maboutique/logo.png" alt="Kalico Ma Boutique" />
    </div>

    <div class="conteneur-formulaire-clientfinal px-3 py-4 d-flex flex-column gap-3 ombre rounded">
      <div class="input-group" [class.contour-rouge]="loginEchoue">
        <div class="input-group-text">
          <span class="fa fa-fw fa-at"></span>
        </div>
        <input type="email" class="form-control" id="username" formControlName="username" placeholder="Adresse mail" />
      </div>

      <div>
        <div class="input-group mb-0" [class.contour-rouge]="loginEchoue">
          <div class="input-group-text">
            <span class="fa fa-fw fa-lock"></span>
          </div>
          <input
            type="password"
            class="form-control"
            id="password"
            formControlName="password"
            placeholder="Mot de passe"
          />
        </div>

        <div *ngIf="loginEchoue" class="invalid-feedback d-block text-danger mt-2">
          Identifiant ou mot de passe incorrect.
        </div>
        <div *ngIf="!appareilAutorise" class="invalid-feedback d-block text-danger mt-2">
          Autorisation requise pour cet appareil. Veuillez contacter votre administrateur.
        </div>
      </div>

      <button
        tabindex="1"
        type="submit"
        [disabled]="!formulaire.valid || chargement"
        class="btn btn-secondary align-self-center mt-2"
        (click)="login()"
      >
        <span *ngIf="!chargement" class="fa fa-fw fa-right-to-bracket me-2"></span>
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin me-2"></span>
        <span>Connexion</span>
      </button>

      <button *ngIf="utilisateur" class="btn btn-secondary align-self-center" (click)="passerLogin()">
        <span>Mode hors ligne</span>
      </button>
    </div>

    <div class="flex-grow-1"></div>

    <button class="btn bouton-transparent text-secondary mb-3" (click)="motDePasseOublie()">
      <span>Mot de passe oublié</span>
    </button>
  </div>
</form>
