import { TypeEvenement } from "src/app/modules/fragments/modal-gestion-planning/modal-gestion-planning.component";
import { SynchronisationPlanning } from "../synchronisation-quotidienne/synchronisation-quotidienne";

export class PlanningApp {
  public readonly planningId: number;
  public dateModifier: string;
  public heureModifier: string;
  public statutModifier: string;
  public commentaireModifier: string;
  public dateModification: string;
  public typeEvenement: TypeEvenement;

  constructor(planning: SynchronisationPlanning) {
    this.planningId = planning.planningId;
    this.dateModifier = planning.dateEffective;
    this.heureModifier = planning.heureEvenement;
    this.statutModifier = planning.statutEvenement;
    this.commentaireModifier = planning.commentaireEvenement;
    this.dateModification = planning.dateModification;
    this.typeEvenement = planning.typeEvenement;
  }
}

export class PlanningServeur {

}

export class SynchronisationPlanningAppDTO {
  public plannings!: PlanningApp[];

  constructor(liste: SynchronisationPlanning[]) {
    this.plannings = liste.map(e => new PlanningApp(e));
  }
}

export class SynchronisationPlanningServeurDTO {
  public plannings!: PlanningServeur[];
}
