import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public recupererToken(): string | null {
    return localStorage.getItem('token');
  }

  public enregistrerToken(token: string | null): void {
    if(!token) localStorage.removeItem('token');
    else localStorage.setItem('token', token);
  }

}
