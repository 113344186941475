import { Injectable } from '@angular/core';
import { ParametreApplication } from '../db/parametre-application';
import { HttpService } from '../http/http.service';
import { ModalService } from '../modal/modal.service';
import { MotDePasseOublieComponent } from './motdepasse-oublie.component';
import { lastValueFrom } from 'rxjs';
import { ParametresUtilisateurCRM } from '../crm/crm';
import { Router, UrlSerializer } from '@angular/router';

export type ReinitialisationMotDePasseDTO = {
  cle: string;
  nouveauMotDePasse: string;
} | {
  email: string;
  ancienMotDePasse: string;
  nouveauMotDePasse: string;
};

@Injectable({
  providedIn: 'root',
})
export class MotDePasseOublieService {
  public modaleOuverte: boolean = false;
  public utilisateurConnecte: ParametreApplication<boolean> | undefined;
  public parametresCrm: ParametreApplication<ParametresUtilisateurCRM> | undefined;

  constructor(private http: HttpService, private modalService: ModalService, private router: Router, private urlSerializer: UrlSerializer) {
  }

  public ouvrirModale(url?: string) {
    if (this.modaleOuverte) return;

    let finalisation = false;
    let cle = undefined;
    if (url) {
      const [chemin, paramsString] = url.split('?', 2);
      const params = new URLSearchParams(paramsString);
      if (chemin === '/motdepasse/reinitialiser') {
        finalisation = true;
        cle = params.get('cle');
      }
    }

    const modaleRef = this.modalService
      .open(MotDePasseOublieComponent, { fullscreen: true });
    modaleRef.componentInstance.finalisation = finalisation;
    modaleRef.componentInstance.cle = cle;
    modaleRef
      .result.then(() => (this.modaleOuverte = false))
      .catch(() => (this.modaleOuverte = false));
    this.modaleOuverte = true;
  }

  public async demanderReinitialisation(utilisateur: string): Promise<void> {
    await lastValueFrom(this.http.postFromApi<void>('business/crm/client-final/reinit-mdp/demande', utilisateur));
  }

  public async verifierReinitialisation(cle: string): Promise<boolean | null> {
    return (await lastValueFrom(this.http.getFromApi<boolean>('business/crm/client-final/reinit-mdp/verification/' + cle))).body;
  }

  public async finaliserReinitialisation(reinit: ReinitialisationMotDePasseDTO) {
    await lastValueFrom(this.http.postFromApi<void>('business/crm/client-final/reinit-mdp/finalisation', reinit));
  }
}
