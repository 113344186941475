<ng-container [ngTemplateOutlet]="afficherVue()"></ng-container>

<ng-template #ajoutModifContact>
  <div class="modal-header bg-primary text-light">
    <h4 *ngIf="!contact" class="modal-title">Ajouter un contact</h4>
    <h4 *ngIf="contact" class="modal-title">Modifier un contact</h4>
  </div>
  <form [formGroup]="formContact" (ngSubmit)="valider()">
    <div class="modal-body">
      <div class="mb-2">
        <app-recherche-client placeholder="Rechercher un client"
          [client]="client.value ? client.value : clientRapide ? clientRapide : null"
          (clientChange)="mettreAJourClient($event, 'contact')" />
      </div>
      <!-- <div> {{contact.contact}} </div> -->
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Nom | prénom">
          <span class="fa fa-fw fa-signature"></span>
        </span>
        <input type="text" class="recherche form-control" placeholder="Nom*" formControlName="nomContact" />
        <input type="text" class="recherche form-control" placeholder="Prenom" formControlName="prenomContact" />
      </div>
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Téléphone fixe">
          <span class="fa fa-fw fa-phone"></span>
        </span>
        <input type="tel" pattern="[0-9]*" inputmode="numeric" maxlength="10" minlength="10"
          class="recherche form-control" placeholder="Téléphone fixe" formControlName="telContact" />
      </div>
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Téléphone portable">
          <span class="fa fa-fw fa-phone"></span>
        </span>
        <input type="tel" pattern="[0-9]*" inputmode="numeric" maxlength="10" minlength="10"
          class="recherche form-control" placeholder="Téléphone portable" formControlName="telMobileContact" />
      </div>
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Mail">
          <span class="fa fa-fw fa-envelope"></span>
        </span>
        <input type="email" class="recherche form-control" placeholder="Email" formControlName="emailContact" />
      </div>
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Anniversaire">
          <span class="fa fa-fw fa-cake-candles"></span>
        </span>
        <input type="date" class="recherche form-control" formControlName="anniversaireContact"/>
      </div>
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Fonction">
          <span class="fa fa-fw fa-briefcase"></span>
        </span>
        <input type="text" class="recherche form-control" placeholder="Fonction" formControlName="fonctionContact" />
      </div>
      <div class="input-group mb-2">
        <span class="btn btn-selection p-2" title="Remarque">
          <span class="fa fa-fw fa-comment"></span>
        </span>
        <textarea class="recherche form-control" placeholder="Remarque" formControlName="remarqueContact" rows="3"></textarea>
      </div>
      <div class="d-flex gap-2 mt-2">
        <input id="destFacture" class="form-check-input mt-0" type="checkbox"
        [formControl]="formContact.controls.destFacture">
        <label for="destFacture">Destinaire factures</label>
      </div>
      <div class="d-flex gap-2 mt-2">
        <input id="destReleve" class="form-check-input mt-0" type="checkbox"
        [formControl]="formContact.controls.destReleve">
        <label for="destReleve">Destinaire relevés</label>
      </div>
      <div class="d-flex gap-2 mt-2">
        <input id="destAvisExpe" class="form-check-input mt-0" type="checkbox"
        [formControl]="formContact.controls.destAvisExpe">
        <label for="destAvisExpe">Destinaire avis expédition</label>
      </div>
      <div class="d-flex gap-2 mt-2">
        <input id="destAccuseLiv" class="form-check-input mt-0" type="checkbox"
        [formControl]="formContact.controls.destAccuseLiv">
        <label for="destAccuseLiv">Destinaire accusés livraison</label>
      </div>
      <div *ngIf="formContact.get('emailContact')?.hasError('invalidEmailFormat')">
        <p class="fa-fade text-danger text-end flex-grow-1">Le format de l'e-mail n'est pas correct. (Exemple : xxxx&#64;xxx.xx)</p>
      </div>
      <div *ngIf="formContact.get('telContact')?.hasError('chiffresSeulement')">
        <p class="fa-fade text-danger text-end flex-grow-1">Le format du téléphone n'est pas correct. (Exemple :
          038420XXXX)</p>
      </div>
      <div *ngIf="formContact.get('telMobileContact')?.hasError('chiffresSeulement')">
        <p class="fa-fade text-danger text-end flex-grow-1">Le format du téléphone mobile n'est pas correct. (Exemple :
          065020XXXX)</p>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div>
        <button class="btn btn-secondary text-light mr-auto" type="button"
          (click)="reinitiliaser()">
          <span class="visible-desktop">Réinitialiser</span>
          <span class="fa fa-backward visible-mobile"></span>
        </button>
        <button *ngIf="contactInput && parametresCrm?.suppressionContact"
          class="btn btn-danger text-light mr-auto espacement" type="button" (click)="supprimerContact()">
          <span class="visible-desktop">Supprimer</span>
          <span class="fa fa-trash visible-mobile"></span>
        </button>
      </div>
      <div>
        <button class="btn btn-droite btn-danger" type="button" (click)="fermer()">
          <span class="visible-mobile fa fa-fw fa-xmark"></span>
          <span class="visible-desktop">Annuler</span>
        </button>
        <button class="btn btn-primary espacement" type="submit" [disabled]="!formContact.valid">
          <span class="visible-mobile fa fa-fw fa-check"></span>
          <span class="visible-desktop">Valider</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #ajoutModifTache>
  <div class="modal-header bg-primary text-light">
    <h4 *ngIf="!tache" class="modal-title">Ajouter un évènement</h4>
    <h4 *ngIf="tache" class="modal-title">Modifier un évènement</h4>
  </div>
  <form [formGroup]="formEvenement" (ngSubmit)="valider()">
    <div class="modal-body">
      <div class="input-group d-flex mb-2">
        <button type="button" class="btn flex-grow-1"
          [ngClass]="{'bg-primary': estActif(0), 'bg-light': !estActif(0), 'text-white': estActif(0)}"
          (click)="changerTypeEvenement(0)">Tâche
        </button>
        <button type="button" class="btn flex-grow-1"
          [ngClass]="{'bg-primary': estActif(1), 'bg-light': !estActif(1), 'text-white': estActif(1)}"
          (click)="changerTypeEvenement(1)">
          Rendez-vous
        </button>
      </div>
      <div class="input-group mb-2">
        <input type="text" class="form-control w-75" placeholder="Libellé de l'évènement"
          formControlName="libelleEvenement">
        <select class="form-control w-25" formControlName="prioriteEvenement" *ngIf="estActif(0)">
          <option hidden disabled selected value>Choisir une priorité</option>
          <option value=1>1 Élevée</option>
          <option value=3>3 Normal</option>
          <option value=5>5 Faible</option>
          <option value=7>7 Très faible</option>
          <option value=9>9 Aucune</option>
        </select>
      </div>
      <div class="input-group mb-2">
        <select *ngIf="tache" class="form-control w-25" formControlName="statusEvenement">
          <option [value]="listeStatus['RDV_A_CONFIRMER'].valeur" *ngIf="estActif(1)">
            {{listeStatus["RDV_A_CONFIRMER"].libelle}}</option>
          <option [value]="listeStatus['RDV_CONFIRMER'].valeur" *ngIf="estActif(1)">
            {{listeStatus["RDV_CONFIRMER"].libelle}}</option>
          <option [value]="listeStatus['TACHE_A_REALISER'].valeur" *ngIf="estActif(0)">
            {{listeStatus["TACHE_A_REALISER"].libelle}}</option>
          <option [value]="listeStatus['TACHE_REALISER'].valeur" *ngIf="estActif(0)">
            {{listeStatus["TACHE_REALISER"].libelle}}</option>
          <option [value]="listeStatus['AUTRE'].valeur">{{listeStatus["AUTRE"].libelle}}</option>
        </select>
      </div>
      <div class="mb-2">
        <span class="me-2">Jour entier</span>
        <input class="form-check-input mt-0" type="checkbox" formControlName="journeeEntiere">
      </div>
      <div class="input-group mb-2">
        <input type="datetime-local" class="form-control" placeholder="Date de début" formControlName="dateDebut"
          [min]="minDateDebut" (input)="saisieDateDebut()">
        <input type="datetime-local" class="form-control" placeholder="Date de fin" formControlName="dateFin"
          [min]="minDateFin" (input)="saisieDateFin()">
      </div>

      <app-recherche-client placeholder="Rechercher un client" [client]="client.value"
        (clientChange)="mettreAJourClient($event, 'evenement')" />

      <div class="quill-editor-wrapper my-2">
        <quill-editor class="w-100" [modules]="modules" [formControl]="texteDescriptionModel"
          [placeholder]="'Description'"></quill-editor>
      </div>

      <h6 class="text-danger text-center" *ngIf="formEvenement.hasError('requiredClient')">
        Veuillez renseigner un client avant de valider.
      </h6>
    </div>
    <div class="modal-footer justify-content-between">
      <div class="d-flex gap-2">
        <button class="btn btn-secondary text-light" type="button" (click)="reinitiliaser()">
          <span class="visible-mobile fa-solid fa-rotate-right"></span>
          <span class="visible-desktop">Réinitialiser</span>
        </button>
        <button *ngIf="tache && tache.indicationAnnule === 2" class="btn btn-info text-light" type="button"
          (click)="annulationEvenement(1)">
          <span class="visible-mobile fa-solid fa-trash-arrow-up"></span>
          <span class="visible-desktop">Restaurer</span>
        </button>
        <button *ngIf="tache && tache.indicationAnnule === 1" class="btn btn-danger" type="button"
          (click)="annulationEvenement(2)">
          <span class="visible-mobile fa fa-fw fa-trash"></span>
          <span class="visible-desktop">Supprimer</span>
        </button>
      </div>
      <div *ngIf="!tache" class="d-flex gap-2">
        <button class="btn btn-danger" type="button" (click)="fermer()">
          <span class="visible-mobile fa-solid fa-xmark"></span>
          <span class="visible-desktop">Annuler</span>
        </button>
        <button class="btn btn-primary" type="submit" [disabled]="!formEvenement.valid">
          <span class="visible-mobile fa fa-fw fa-check"></span>
          <span class="visible-desktop">Valider</span>
        </button>
      </div>
      <div *ngIf="tache" class="d-flex gap-2">
        <button class="btn btn-danger" type="button" (click)="fermer()">
          <span class="visible-mobile fa-solid fa-xmark"></span>
          <span class="visible-desktop">Annuler</span>
        </button>
        <button class="btn btn-primary" type="button" (click)="enregistrer()">
          <span class="visible-mobile fa-solid fa-floppy-disk"></span>
          <span class="visible-desktop">Enregistrer</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
