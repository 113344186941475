import { SynchronisationContactClient, } from "../synchronisation/synchronisation";

export class ContactApp {
  public idIndexedDB!: number;
  public id?: number;
  public nom!: string;
  public prenom!: string;
  public telephone!: string;
  public telephoneMobile!: string;
  public email!: string;
  public tiers!: string;
  public nomTiers?: string;

  public utilisateurCreation?: string;
  public utilisateurModif?: string;
  public action?: string;
  public dateCreation!: string;
  public dateModif!: string;

  public contact!: string;

  public anniversaire!: string;
  public fonction!: string;
  public destFacture!: string;
  public destReleve!: string;
  public destAvisExpe!: string;
  public destAccuseLiv!: string;
  public remarque!: string;

  constructor(
    action: string,
    contact: string,
    tiers: string,
    nom: string,
    prenom: string,
    email: string,
    telephone: string,
    telephoneMobile: string,
    anniversaire: string,
    fonction: string,
    destFacture: string,
		destReleve: string,
		destAvisExpe: string,
		destAccuseLiv: string,
    remarque: string,
  ) {
    this.action = action;
    this.contact = contact;
    this.tiers = tiers;
    this.nom = nom;
    this.prenom = prenom;
    this.email = email;
    this.telephone = telephone;
    this.telephoneMobile = telephoneMobile;
    this.anniversaire = anniversaire;
    this.fonction = fonction;
    this.destFacture = destFacture;
    this.destReleve = destReleve;
    this.destAvisExpe = destAvisExpe;
    this.destAccuseLiv = destAccuseLiv;
    this.remarque = remarque;
  }

  public static fromContactsSynchro(contact: SynchronisationContactClient, action: string): ContactApp {
    const cls = new ContactApp(
      action,
      contact.contact,
      contact.tiers,
      contact.nom,
      contact.prenom,
      contact.email,
      contact.telephone,
      contact.telephoneMobile,
      contact.anniversaire,
      contact.libelleContact,
      contact.fonction1,
      contact.fonction2,
      contact.fonction8,
      contact.fonction9,
      contact.remarque
    );

    return cls;
  }
}

export class ContactServeur {
  public id?: number;
  public action!: string;
  public contatc!: string;
  public tiers!: string;
  public nom!: string;
  public prenom!: string;
  public mail!: string;
  public telephone!: string;
  public telephoneMobile!: string;
}

export class SynchronisationContactAppDTO {
  public contacts!: ContactApp[];
}

export class SynchronisationContactServeurDTO {
  public contacts!: ContactServeur[];
}
