import { Injectable } from '@angular/core';
import { SynchronisationService } from '../synchronisation/synchronisation.service';
import { SynchronisationQuotidienneService } from '../synchronisation-quotidienne/synchronisation-quotidienne.service';
import { UtilitaireCrmService } from '../utilitaire/utilitaire-crm.service';
import { CrmPanierService } from '../crm/crm-panier/crm-panier.service';
import { lastValueFrom } from 'rxjs';
import { EvenementsService } from '../evenements/evenements.service';
import { ContactsService } from '../contacts/contacts.service';

@Injectable({
  providedIn: 'root'
})
export class DonneesService {

  constructor(
    private crmPanierService: CrmPanierService,
    private contactsService: ContactsService,
    private evenementsService: EvenementsService,
    private utilitaireCrmService: UtilitaireCrmService,
    private synchronisationService: SynchronisationService,
    private synchronisationQuotidienneService: SynchronisationQuotidienneService,
  ) { }

  public async synchroniser(): Promise<void> {
    console.time('synchronisation');
    this.synchronisationService.synchronisationEnCours = true;
    try {
      await Promise.all([
        // appel en double à la synchro incrémentielle, a voir si celà ne pose pas de pb de performance
        this.contactsService.synchroniserErp(),
        this.evenementsService.synchroniserErp(),
        this.utilitaireCrmService.synchroniserAppli(),
        this.synchronisationService.synchroniser(),
        lastValueFrom(this.crmPanierService.synchroniserPaniers())
      ])
    } catch (e) {
      return;
    } finally {
      this.synchronisationService.synchronisationEnCours = false;
      console.timeEnd('synchronisation');
    }
  }

  public async synchroniserQtd(): Promise<void> {
    console.time('synchronisationQuotidienne');
    this.synchronisationService.synchronisationEnCours = true;

    try {
      await this.synchronisationQuotidienneService.synchroniser()
    } catch (e) {
      return;
    } finally {
      this.synchronisationService.synchronisationEnCours = false;
      console.timeEnd('synchronisationQuotidienne');
    }
  }
}
