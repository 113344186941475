import { NgModule } from '@angular/core';
import { ClientFinalDirective } from './client-final.directive';
import { CommercialDirective } from './commercial.directive';

@NgModule({
  imports: [],
  declarations: [ClientFinalDirective, CommercialDirective],
  exports: [ClientFinalDirective, CommercialDirective]
})
export class PartitionnementModule { }
