import { Injectable } from '@angular/core';

export type ObjetIndexable = { [index: string]: unknown };

@Injectable({
  providedIn: 'root'
})
export class UtilitaireGeneralService {

  public strNormaliser(str: string): string {
    if (!str) return "";
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();
  }

  public strEgalSouple(str1: string, str2: string): boolean {
    return this.strNormaliser(str1) === this.strNormaliser(str2);
  }

  public strContientSouple(str1: string, recherche: string): boolean {
    const zoneRecherchee: string = this.strNormaliser(str1);
    const motsRecherche: string[] = recherche.split(" ").map(this.strNormaliser);

    if (!zoneRecherchee || motsRecherche.length === 0) return false;
    for (const motRecherche of motsRecherche) {
        if (!zoneRecherchee.includes(motRecherche)) {
          return false;
        }
    }
    return true;
  }

  public strContientSoupleBidirectionnel(str1: string, str2: string): boolean {
    const str1Norm = this.strNormaliser(str1);
    const str2Norm = this.strNormaliser(str2);

    if (!str1Norm && !str2Norm) return false;
    if (!str1Norm && str2Norm) return false;
    if (str1Norm && !str2Norm) return false;

    return str1Norm.includes(str2Norm) || str2Norm.includes(str1Norm);
  }

  public transformerStringEnBlob(data: string): Blob {
    return new Blob([data], { type: 'application/json' });
  }

  public convertirTailleOctetsEnString(tailleOctets: number): string {
    if (!tailleOctets) return "";
    const unite: string[] = [" octets", "Ko", "Mo", "Go", "To"];
    let index: number = 0;
    let octets: number = tailleOctets;

    while (octets >= 1024 && index < unite.length - 1) {
      octets /= 1024;
      index++;
    }

    return octets.toFixed(2) + unite[index];
  }

  public groupBy<T, X>(tab: T[], methode: (obj: T) => string, methodeMap?: (obj: T) => X): Map<string, X[]> {
    const map: Map<string, X[]> = new Map();
    for (let i = 0; i < tab.length; i++) {
      const tabElement: T = tab[i];
      const key: string = methode(tabElement);
      if (!map.has(key)) {
        map.set(key, []);
      } else {
        if (methodeMap) map.get(key)?.push(methodeMap(tabElement));
        else map.get(key)?.push(tabElement as unknown as X);
      }
    }
    return map;
  }

  public joursJavascriptVersJoursSemaine(jour: number): number {
    return jour === 0 ? 7 : jour;
  }

  public decalerJours(date: Date, nbJours: number): Date {
    const nouvelleDate: Date = new Date(date.getTime());
    nouvelleDate.setDate(nouvelleDate.getDate() + nbJours);
    return nouvelleDate;
  }

  public formatDateStr(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toDateString();
  }

  public formatHeureStr(date: string): string {
    const heure = date.substring(0, 2);
    const minute = date.substring(2, 4);
    return heure + ':' + minute;
  }

  public formatHeureStrEvtNonSynchronise(date: string): string {
    const heure = date.substring(11, 16);
    return heure;
  }

  public formatHeure(date: Date): string {
    const heures = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const secondes = date.getSeconds().toString().padStart(2, '0');
    return `${heures}${minutes}${secondes}`;
  }

  public formatDate(date: Date): string {
    const annee = date.getFullYear();
    const mois = (date.getMonth() + 1).toString().padStart(2, '0');
    const jour = date.getDate().toString().padStart(2, '0');
    return `${annee}-${mois}-${jour}`;
  }

}
