<div class="modal-header bg-primary text-light">
  <h4 class="text-light">Chargement</h4>
</div>

<div class="modal-body">
  <div class="progress-bar-container">
    <div class="progress-label mb-2">
      <span>Synchronisation quotidienne en cours...</span>
      <span style="font-style: italic; font-weight: bold;">
        {{ "(Étape " + synchronisationQuotidienneService.etapeActuelleSynchroQtd + "/" + synchronisationQuotidienneService.nombreEtapesTotalSynchroQtd + " - " + synchronisationQuotidienneService.etapeEnCoursQtd + ")"}}
      </span>
    </div>
    <div class="progress-bar">
      <div class="progress-bar-inner" [style.width]="avanceeSynchro(synchronisationQuotidienneService.etapeActuelleSynchroQtd, synchronisationQuotidienneService.nombreEtapesTotalSynchroQtd)"></div>
    </div>
  </div>

  <div class="progress-bar-container">
    <div class="progress-label mb-2">
      <span>Synchronisation des données en cours...</span>
      <span style="font-style: italic; font-weight: bold;">
        {{ "(Étape " + synchronisationService.etapeActuelleSynchro + "/" + synchronisationService.nombreEtapesTotalSynchro + " - " + synchronisationService.etapeEnCours + ")"}}
      </span>
    </div>
    <div class="progress-bar">
      <div class="progress-bar-inner" [style.width]="avanceeSynchro(synchronisationService.etapeActuelleSynchro, synchronisationService.nombreEtapesTotalSynchro)"></div>
    </div>
  </div>
</div>
