import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { LoggerService } from '../log/logger.service';
import type { ConfigPlateforme, Plateforme } from './specifique';
import { HttpService } from '../http/http.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { recupererJetonFirebase } from './outils/notifications-push';
import {
  CRMEnregistrementAppareilDTO,
  recupererIdentifiantAppareil,
  recupererInformationsComplementairesAppareil,
} from './outils/appareil';
import packageJson from 'package.json';
import { Identifiants } from '../login/dto/identifiants';
import { AppLauncher } from '@capacitor/app-launcher';
import { OutilsNatifsKalico } from './outils/natif-kalico';
import { firstValueFrom } from 'rxjs';

export type FichierATelecharger = FichierLocalATelecharger | FichierDistantATelecharger;

export interface FichierDistantATelecharger {
  nom: string;
  url: string;
  type?: string;
  params?: HttpParams;
  chargeUtile?: unknown;
}

export interface FichierLocalATelecharger {
  nom: string;
  donnees: Blob;
  type?: string;
}

const LISTE_BLANCHE_PROTOCOLE_URL = ['https'];

@Injectable({
  providedIn: 'root',
})
export class PlateformeService {
  private plateforme!: Plateforme;
  private initialisation: Promise<void>;
  private config: ConfigPlateforme;

  constructor(private loggerService: LoggerService, private httpService: HttpService) {
    this.initialisation = this.init();
    this.config = {};
  }

  private async init(): Promise<void> {
    OutilsNatifsKalico.ping({ ping: 'TEST' }).then((res) => console.log(res));

    switch (Capacitor.getPlatform()) {
      case 'ios':
        this.plateforme = await import('./specifique/ios').then((mod) => new mod.PlateformeIOS(this.loggerService));
        break;
      case 'android':
        this.plateforme = await import('./specifique/android').then(
          (mod) => new mod.PlateformeAndroid(this.loggerService)
        );
        break;
      default:
        this.plateforme = await import('./specifique/web').then((mod) => new mod.PlateformeWeb(this.loggerService));
        break;
    }
    this.loggerService.info(`Utilisation des services plateforme ${this.plateforme.nom}`);
  }

  public async estNatif(): Promise<boolean> {
    await this.initialisation;
    return this.plateforme.estNative;
  }

  public async configurer(): Promise<void> {
    await this.initialisation;
    try {
      this.config = await this.plateforme.configurer();
    } catch (e) {
      this.loggerService.error(`Erreur lors de la configuration des services plateforme: ${e}`);
    }
  }

  public async telechargerEtOuvrir(fichier: FichierATelecharger): Promise<boolean> {
    return await this.telecharger(fichier, (n, t, b) => this.plateforme.enregistrerEtOuvrir(n, t, b));
  }

  public async telechargerSansOuvrir(fichier: FichierATelecharger): Promise<boolean> {
    return await this.telecharger(fichier, (n, t, b) => this.plateforme.enregistrerSeulement(n, t, b));
  }

  private async telecharger(
    fichier: FichierATelecharger,
    rappelOuverture: (nom: string, type: string | undefined, body: Blob) => Promise<void>
  ): Promise<boolean> {
    await this.initialisation;
    if ('donnees' in fichier) {
      await rappelOuverture(fichier.nom, fichier.type, fichier.donnees);
      return true;
    } else {
      let appelApi;
      if (fichier.chargeUtile === undefined) {
        appelApi = this.httpService.getBlobFromApi(fichier.url, fichier.params);
      } else {
        appelApi = this.httpService.postBlobFromApi(fichier.url, fichier.chargeUtile, fichier.params);
      }
      const reponse = await firstValueFrom(appelApi);
      if (!reponse || !reponse.body || reponse.body.size === 0) {
        return false;
      } else {
        await rappelOuverture(fichier.nom, fichier.type, reponse.body);
        return true;
      }
    }
  }

  public async enregistrerSeulement(nom: string, blob: Blob, type?: string): Promise<void> {
    await this.initialisation;
    await this.plateforme.enregistrerSeulement(nom, type, blob);
  }

  private recupererJetonFirebase(): Promise<string> {
    return recupererJetonFirebase(this.config.optionsEnregistrementFirebase);
  }

  public async enregistrerAppareil(): Promise<void> {
    await this.initialisation;
    let jetonFirebaseMessaging;
    if (await this.plateforme.verifierPermissionsNotifications()) {
      jetonFirebaseMessaging = await this.recupererJetonFirebase();
      this.loggerService.verbose(`Notifications OK, jeton firebase messaging: [${jetonFirebaseMessaging}]`);
    } else {
      jetonFirebaseMessaging = 'KAL-DESACTIVE';
      this.loggerService.verbose(`Notifications desactivées`);
    }

    const identifiantAppareil = await recupererIdentifiantAppareil();
    this.loggerService.info(`L'identifiant de cet appareil est [${identifiantAppareil}]`);

    const infosComplementaires = await recupererInformationsComplementairesAppareil();
    this.loggerService.info(`Informations de l'appareil: ${JSON.stringify(infosComplementaires)}`);

    const appareil: CRMEnregistrementAppareilDTO = {
      identifiantAppareil,
      plateforme: this.plateforme.codePlateforme,
      infosComplementaires,
      versionCRM: packageJson.version,
      jetonFirebaseMessaging,
    };
    console.log(JSON.stringify(appareil));

    try {
      await this.httpService.postFromApiDiscret('business/crm/enregistrement-appareil', appareil);
      this.loggerService.info('Appareil enregistré avec succès');
    } catch (err) {
      this.loggerService.error(`Erreur lors de l'enregistrement de l'appareil: ${JSON.stringify(err)}`);
    }
  }

  public async inviterEnregistrerMotDePasse(identifiants: Identifiants): Promise<void> {
    await this.initialisation;
    this.plateforme.inviterAEnregistrerMotDePasse(identifiants);
  }

  public async ourvirUrlExterne(url: string | null | undefined): Promise<boolean> {
    if (!url) return false;
    if (LISTE_BLANCHE_PROTOCOLE_URL.every((protocole) => !url.startsWith(protocole + '://'))) {
      this.loggerService.warn(`Ouverture de l'URL "${url}" bloquée car ce protocole n'est pas autorisé.`);
      return false;
    }
    const resultat = await AppLauncher.openUrl({ url });
    return resultat.completed;
  }

  public async getNomPlateforme(): Promise<string> {
    await this.initialisation;
    return this.plateforme.nom;
  }
}
