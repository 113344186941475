import { ArticlesManquantsDTO } from './../../modules/crm/clients/detail-client/detail-client-carte/detail-client-carte.component';
import { Injectable } from '@angular/core';
import { SynchronisationClient } from '../synchronisation/synchronisation';
import { ReplaySubject } from 'rxjs';
import { DbService } from '../db/db.service';
import { liveQuery } from 'dexie';
import { HttpService } from '../http/http.service';
import { ToastService } from '../toast/toast.service';
import { UtilitaireGeneralService } from '../utilitaire/utilitaire-general.service';
import { Toast } from '../toast/toast';
import { PlateformeService } from '../plateforme/platforme.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  public clients: SynchronisationClient[] = [];
  public clientsMap: Map<string, SynchronisationClient> = new Map();
  public clientsChange: ReplaySubject<SynchronisationClient[]> = new ReplaySubject<SynchronisationClient[]>(1);

  private listeClient: SynchronisationClient[] = [];
  private client?: SynchronisationClient;

  public telechargementImpossible: boolean = false;
  public chargementPDF: boolean = false;

  constructor(
    private dbService: DbService,
    private httpService: HttpService,
    private toastsService: ToastService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    private plateformeService: PlateformeService,
  ) {
    this.init();
  }

  private async init(): Promise<void> {
    liveQuery(() => this.dbService.dexie.synchronisation_clients.toArray()).subscribe(
      (clients: SynchronisationClient[]) => {
        this.clients = clients;
        this.clientsMap.clear();
        for (const client of clients) {
          this.clientsMap.set(client.tiers, client);
        }
        this.clientsChange.next(this.clients);
      }
    );
  }

  public setClients(clients: SynchronisationClient[]): void {
    this.listeClient = clients;
  }

  public getClients(): SynchronisationClient[] {
    return this.listeClient;
  }

  public setClient(client: SynchronisationClient): void {
    this.client = client;
  }

  public getClient(): SynchronisationClient | undefined {
    return this.client;
  }

  public async exportSituationCompte(tiers: string): Promise<void> {
    const numeroClient: string | undefined = tiers;
    if (numeroClient === undefined) return;

    this.chargementPDF = true;

    try {
      const ok = await this.plateformeService.telechargerEtOuvrir({
        url: `business/crm/edition-situation-compte-pdf/${numeroClient}?ruptures=-MNS`,
        type: 'application/pdf',
        nom: `Situation_de_compte_${numeroClient}.pdf`,
      });

      if (!ok) {
        this.telechargementImpossible = true;
        this.toastsService.afficher(new Toast('Aucune données', 'Le document de situation de compte est vide.'));
      }
    } catch {
      this.toastsService.afficher(new Toast('Connexion internet requise', 'Vérifiez votre connexion Internet.'));
    }
    
    this.chargementPDF = false;
  }

  public async telechargerArticlesManquants(dto: ArticlesManquantsDTO): Promise<void> {
    const numeroClient: string | undefined = dto.client;
    if (numeroClient === undefined) return;
    const chemin = "business/crm/exporter-ruptures";

    try {
      const ok = await this.plateformeService.telechargerEtOuvrir({
        url: chemin,
        type: 'application/pdf',
        nom: `Export_manquants_${numeroClient}.pdf`,
        chargeUtile: dto
      });

      if (!ok) {
        this.toastsService.afficher(new Toast('Aucune données', "Aucun manquant disponible."));
      }
    } catch {
      this.toastsService.afficher(new Toast('Connexion internet requise', 'Vérifiez votre connexion Internet.'));
    }
    
    this.chargementPDF = false;
  }

}
