import Dexie, { Table } from "dexie";
import { SynchronisationClient, SynchronisationContactClient, SynchronisationEvenement, SynchronisationFamilleStat, SynchronisationArticle, SynchronisationAdresseClient, SynchronisationUnite, TarifSynchronise, LibelleTarifSynchronise, SynchronisationExceptionTarifaire, SynchronisationRemisesClient, SynchronisationArticleMedia } from "../synchronisation/synchronisation";
import { SynchronisationEncoursVente, SynchronisationEncoursFacture, SynchronisationIndicateursStat, SynchronisationIndicateursStatProgression, SynchronisationStockArticle, SynchronisationInfoDepot, SynchronisationInfoCommercial, SynchronisationStatSimple, SynchronisationReferencement, SynchronisationPlanning, SynchronisationTournee, SynchronisationMotifsGratuite, SynchronisationHistoriqueArticle, SynchronisationFraisSupplementaires, SynchronisationFichierJoint, SynchronisationClasseRemise, SynchronisationMiseEnAvant, SynchronisationDeconsignesAttente } from "../synchronisation-quotidienne/synchronisation-quotidienne";
import { ParametreApplication } from "./parametre-application";
import { PanierEnteteApp, PanierLigneApp, PanierCommentaireApp } from "../crm/crm-panier/crm-panier";
import { EvenementApp } from "../evenements/evenements";
import { FavorisArticle, FavorisClient, FavorisContactClient } from "../favoris/favoris";
import { ContactApp } from "../contacts/contacts";

export const VERSION_BDD = 100;

export type SchemaBDD = Dexie & {
    parametres_application: Table<ParametreApplication<unknown>, number>;
    synchronisation_clients: Table<SynchronisationClient, string>;
    synchronisation_contacts_clients: Table<SynchronisationContactClient, [string, string]>;
    synchronisation_evenements: Table<SynchronisationEvenement, number>;
    synchronisation_familles_stat_1: Table<SynchronisationFamilleStat, string>;
    synchronisation_familles_stat_2: Table<SynchronisationFamilleStat, string>;
    synchronisation_familles_stat_3: Table<SynchronisationFamilleStat, string>;
    synchronisation_articles: Table<SynchronisationArticle, string>;
    synchronisation_article_medias: Table<SynchronisationArticleMedia, string>;
    synchronisation_adresses: Table<SynchronisationAdresseClient, string>;
    synchronisation_unites: Table<SynchronisationUnite, string>;
    synchronisation_encours_vente: Table<SynchronisationEncoursVente, string>;
    synchronisation_encours_facture: Table<SynchronisationEncoursFacture, string>;
    synchronisation_indicateurs_stat: Table<SynchronisationIndicateursStat, number>;
    synchronisation_indicateurs_stat_progression: Table<SynchronisationIndicateursStatProgression, string>;
    synchronisation_stock_articles: Table<SynchronisationStockArticle, [string, string]>;
    synchronisation_info_depots: Table<SynchronisationInfoDepot, string>;
    synchronisation_info_depots_visu: Table<SynchronisationInfoDepot, string>;
    synchronisation_info_commerciaux: Table<SynchronisationInfoCommercial, string>;
    synchronisation_ficjoint_articles: Table<SynchronisationFichierJoint, number>;
    synchronisation_ficjoint_clients: Table<SynchronisationFichierJoint, number>;
    synchronisation_stat_simple: Table<SynchronisationStatSimple, string>;
    synchronisation_tarifs: Table<TarifSynchronise, string>;
    synchronisation_tarifs_speciaux: Table<TarifSynchronise, string>;
    synchronisation_exceptions_tarifaires: Table<SynchronisationExceptionTarifaire, string>;
    synchronisation_remises: Table<SynchronisationRemisesClient, string>;
    synchronisation_remises_quantite: Table<SynchronisationRemisesClient, string>;
    synchronisation_ristournes: Table<SynchronisationRemisesClient, string>;
    synchronisation_odrd: Table<SynchronisationRemisesClient, string>;
    synchronisation_libelles_tarifs: Table<LibelleTarifSynchronise, string>;
    synchronisation_referencement: Table<SynchronisationReferencement, string>;
    synchronisation_planning: Table<SynchronisationPlanning, [string, string]>;
    synchronisation_tournee: Table<SynchronisationTournee, string>;
    synchronisation_motifs_gratuite: Table<SynchronisationMotifsGratuite, string>;
    synchronisation_historique_article: Table<SynchronisationHistoriqueArticle, string>;
    synchronisation_frais_supplementaires: Table<SynchronisationFraisSupplementaires, string>;
    synchronisation_classe_remise: Table<SynchronisationClasseRemise, string>;
    synchronisation_deconsignes_attente: Table<SynchronisationDeconsignesAttente, string>;
    synchronisation_mise_en_avant: Table<SynchronisationMiseEnAvant, number>;
    favoris_contacts_clients: Table<FavorisContactClient, [string, string]>;
    favoris_clients: Table<FavorisClient, string>;
    favoris_articles: Table<FavorisArticle, string>;
    paniers_entetes: Table<PanierEnteteApp, number>;
    paniers_lignes: Table<PanierLigneApp, number>;
    paniers_commentaires: Table<PanierCommentaireApp, number>;
    evenements: Table<EvenementApp, number>;
    contacts: Table<ContactApp, number>;
}

export const db = new Dexie('kalico-crm') as SchemaBDD;

db.version(99).stores({
    parametres_application: 'id',
    synchronisation_clients: 'tiers, nomClient, codePostal, ville, rue, titreRef, nomAbrege',
    synchronisation_contacts_clients: '[tiers+contact], email, tiers',
    synchronisation_evenements: 'numeroEvenement, dateDebut, tiersRef',
    synchronisation_familles_stat_1: 'familleStatRef',
    synchronisation_familles_stat_2: 'familleStatRef',
    synchronisation_familles_stat_3: 'familleStatRef',
    synchronisation_articles: 'id, articleRef, designation, dateCreation, fournisseurHabituelRef, familleStat1Ref, classeRemise',
    synchronisation_article_medias: 'id, articleRef',
    synchronisation_adresses: 'id, codeAdresse, tiers',
    synchronisation_unites: 'uniteVente',
    synchronisation_encours_vente: 'clientRef',
    synchronisation_encours_facture: 'clientRef',
    synchronisation_indicateurs_stat: '++idIndexedDB, client',
    synchronisation_indicateurs_stat_progression: '++idIndexedDB, client',
    synchronisation_stock_articles: '[depotRef+articleRef], articleRef, depotRef',
    synchronisation_info_depots: 'depotRef',
    synchronisation_info_depots_visu: 'depotRef',
    synchronisation_info_commerciaux: 'commercialRef',
    synchronisation_ficjoint_articles: '++idIndexedDB, reference',
    synchronisation_ficjoint_clients: 'id, reference',
    synchronisation_stat_simple: 'clientRef',
    synchronisation_tarifs: 'id, articleRef, [codeTarif+articleRef]',
    synchronisation_tarifs_speciaux: 'id, clientRef, articleRef, [clientRef+articleRef]',
    synchronisation_exceptions_tarifaires: 'id, codeTarif, tiers, famille, [famille+tiers]',
    synchronisation_remises: 'id, codeTarif, tiers, famille',
    synchronisation_remises_quantite: 'id, codeTarif, tiers, famille',
    synchronisation_ristournes: 'id, codeTarif, tiers, famille',
    synchronisation_odrd: 'id, codeTarif, tiers, famille',
    synchronisation_libelles_tarifs: 'id, codeTarif',
    synchronisation_referencement: 'clientRef',
    synchronisation_planning: 'planningId',
    synchronisation_tournee: 'tourneeId, numero',
    synchronisation_motifs_gratuite: 'motifId, motif, libelle, motifType',
    synchronisation_historique_article: '[tiers+articleRef]',
    synchronisation_frais_supplementaires: 'codeFraisFacturation',
    synchronisation_classe_remise: 'id, classeRef',
    synchronisation_deconsignes_attente: 'tiers',
    synchronisation_mise_en_avant: null,
    favoris_contacts_clients: '[tiers+contact], tiers',
    favoris_clients: 'tiers',
    favoris_articles: 'articleRef',
    paniers_entetes: '++idIndexedDB, [commercialRef+clientRef+dateCreation+utilisateurCreation], clientRef, commercialRef, id',
    paniers_lignes: '++idIndexedDB, [panierIdIndexedDB+articleRef+uniteVente], [panierId+articleRef+uniteVente+dateCreation+utilisateurCreation], id, panierId, panierIdIndexedDB',
    paniers_commentaires: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    evenements: '++idIndexedDB, id, tiersRef',
    contacts: '++idIndexedDB, id, tiers',
});

db.version(VERSION_BDD).stores({
    parametres_application: 'id',
    synchronisation_clients: 'tiers, nomClient, codePostal, ville, rue, titreRef, nomAbrege',
    synchronisation_contacts_clients: '[tiers+contact], email, tiers',
    synchronisation_evenements: 'numeroEvenement, dateDebut, tiersRef',
    synchronisation_familles_stat_1: 'familleStatRef',
    synchronisation_familles_stat_2: 'familleStatRef',
    synchronisation_familles_stat_3: 'familleStatRef',
    synchronisation_articles: 'id, articleRef, designation, dateCreation, fournisseurHabituelRef, familleStat1Ref, classeRemise',
    synchronisation_article_medias: 'id, articleRef',
    synchronisation_adresses: 'id, codeAdresse, tiers',
    synchronisation_unites: 'uniteVente',
    synchronisation_encours_vente: 'clientRef',
    synchronisation_encours_facture: 'clientRef',
    synchronisation_indicateurs_stat: '++idIndexedDB, client',
    synchronisation_indicateurs_stat_progression: '++idIndexedDB, client',
    synchronisation_stock_articles: '[depotRef+articleRef], articleRef, depotRef',
    synchronisation_info_depots: 'depotRef',
    synchronisation_info_depots_visu: 'depotRef',
    synchronisation_info_commerciaux: 'commercialRef',
    synchronisation_ficjoint_articles: '++idIndexedDB, reference',
    synchronisation_ficjoint_clients: 'id, reference',
    synchronisation_stat_simple: 'clientRef',
    synchronisation_tarifs: 'id, articleRef, [codeTarif+articleRef]',
    synchronisation_tarifs_speciaux: 'id, clientRef, articleRef, [clientRef+articleRef]',
    synchronisation_exceptions_tarifaires: 'id, codeTarif, tiers, famille, [famille+tiers]',
    synchronisation_remises: 'id, codeTarif, tiers, famille',
    synchronisation_remises_quantite: 'id, codeTarif, tiers, famille',
    synchronisation_ristournes: 'id, codeTarif, tiers, famille',
    synchronisation_odrd: 'id, codeTarif, tiers, famille',
    synchronisation_libelles_tarifs: 'id, codeTarif',
    synchronisation_referencement: 'clientRef',
    synchronisation_planning: 'planningId',
    synchronisation_tournee: 'tourneeId, numero',
    synchronisation_motifs_gratuite: 'motifId, motif, libelle, motifType',
    synchronisation_historique_article: '[tiers+articleRef]',
    synchronisation_frais_supplementaires: 'codeFraisFacturation',
    synchronisation_classe_remise: 'id, classeRef',
    synchronisation_deconsignes_attente: 'tiers',
    synchronisation_mise_en_avant: 'id, type',
    favoris_contacts_clients: '[tiers+contact], tiers',
    favoris_clients: 'tiers',
    favoris_articles: 'articleRef',
    paniers_entetes: '++idIndexedDB, [commercialRef+clientRef+dateCreation+utilisateurCreation], clientRef, commercialRef, id',
    paniers_lignes: '++idIndexedDB, [panierIdIndexedDB+articleRef+uniteVente], [panierId+articleRef+uniteVente+dateCreation+utilisateurCreation], id, panierId, panierIdIndexedDB',
    paniers_commentaires: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    evenements: '++idIndexedDB, id, tiersRef',
    contacts: '++idIndexedDB, id, tiers',
});
