import { Injectable } from '@angular/core';
import { Toast } from './toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toasts: Toast[] = [];

  public afficher(toast: Toast): void {
    this.toasts.push(toast);
  }

  public supprimer(toast: Toast): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  
}
