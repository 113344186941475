import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { HttpService } from '../http/http.service';
import { ModalService } from '../modal/modal.service';
import { Identifiants } from './dto/identifiants';
import { LoginComponent } from './login.component';
import { Token } from './dto/token';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { SynchronisationService } from '../synchronisation/synchronisation.service';
import { SynchronisationQuotidienneService } from '../synchronisation-quotidienne/synchronisation-quotidienne.service';
import { EnvironnementApplicationService } from '../environnement-application/environnement-application.service';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { PanierEnteteApp, PanierStatut } from '../crm/crm-panier/crm-panier';
import { LoggerService } from '../log/logger.service';
import { Router } from '@angular/router';
import { LoginChargementSynchrosComponent } from './login-chargement-synchros/login-chargement-synchros.component';
import { ParametresUtilisateurCRM } from '../crm/crm';
import { CrmPanierService } from '../crm/crm-panier/crm-panier.service';
import { PlateformeService } from '../plateforme/platforme.service';
import { AppConfigService } from '../configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public authentificationEnCours = false;

  public utilisateurConnecte: ParametreApplication<boolean> | undefined;
  private dejaAffiche: boolean = false;

  public parametresCrm: ParametreApplication<ParametresUtilisateurCRM> | undefined;

  private urlConnexion: string = "business/crm/authenticate"

  constructor(
    private http: HttpService,
    private authService: AuthService,
    private modalService: ModalService,
    private dbService: DbService,
    private synchronisationService: SynchronisationService,
    private synchronisationQuotidienneService: SynchronisationQuotidienneService,
    private environnementApplicationService: EnvironnementApplicationService,
    private confirmationService: ConfirmationService,
    private loggerService: LoggerService,
    private router: Router,
    private crmPanierService: CrmPanierService,
    private appConfigService: AppConfigService,
    private plateformeService: PlateformeService,
  ) {
    if (this.appConfigService.modeClientFinal) {
      this.urlConnexion = "business/crm/client-final/authenticate"
    }
  }

  public ouvrirModale() {
    if (this.authentificationEnCours) return;
    this.authService.enregistrerToken(null);
    this.modalService.open(LoginComponent, { fullscreen: true });
  }

  public testVie(): Observable<HttpResponse<boolean>> {
    return this.http.getFromApi<boolean>("business/crm/test-vie");
  }

  public async login(identifiants: Identifiants): Promise<HttpResponse<Token>> {
    // On envoie la requête d'authentification
    const reponse: HttpResponse<Token> = await lastValueFrom(this.http.postFromApi<Token>(this.urlConnexion, identifiants));
    if (!reponse.body) throw new Error("Le serveur n'a pas renvoyé de token.");

    this.plateformeService.inviterEnregistrerMotDePasse(identifiants);

    const lancerSynchroDonnees = async () => {
      this.synchronisationService.etapeActuelleSynchro = 0;
      this.synchronisationService.etapeEnCours = "";
      this.synchronisationQuotidienneService.etapeActuelleSynchroQtd = 0
      this.synchronisationQuotidienneService.etapeEnCoursQtd = "";
      const modal = this.modalService.open(LoginChargementSynchrosComponent, { size: "xl" });
      await Promise.all([
        this.synchronisationService.synchroniser(),
        this.synchronisationQuotidienneService.synchroniser(),
        this.crmPanierService.synchroniserPaniers().toPromise(),
      ]).then(async () => {
        this.parametresCrm = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as ParametreApplication<ParametresUtilisateurCRM> | undefined;
        this.environnementApplicationService.setParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT, this.parametresCrm?.valeur.depotParDefautRef);
        this.authentificationEnCours = false;
      });
      modal.close();
      this.router.navigate([""]).then(() => window.location.reload());
    }

    const utilisateurParametre: ParametreApplication<string | undefined> | undefined = await firstValueFrom(this.environnementApplicationService.getParametreReactifBrut(ParametreApplicationId.UTILISATEUR));
    const utilisateur: string | undefined = utilisateurParametre?.valeur;
    this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.UTILISATEUR_CONNECTE, valeur: true as never });

    if (!utilisateur) {
      this.loggerService.info(`Première connexion utilisateur [${identifiants.username}]`);
      /* A ce stade, le serveur nous à envoyé un token mais il est possible
      que ce soit la première fois qu'on se connecte à l'application, donc
      on va lancer une synchronisation des données si il n'y a pas d'utilisateur
      enregistré dans les paramètres d'environnement */
      this.authService.enregistrerToken(reponse.body.id_token);
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.UTILISATEUR, valeur: identifiants.username as never });
      lancerSynchroDonnees();
    } else if (utilisateur !== identifiants.username) {
      this.loggerService.info(`Changement d'utilisateur [${utilisateur}] => [${identifiants.username}]`);
      /* Si l'utilisateur est différent de celui qui est enregistré dans les paramètres
      d'environnement, on va supprimer la base (seulement si l'utilisateur confirme qu'il
      veut se connecter en perdant les données) */

      // On ne prend que les paniers qui on été validé mais non envoyés
      // C'est la seule valeur sûre pour savoir qu'on a pas envoyé toute les données
      const nbPaniersNonEnvoyes: number = await this.dbService.dexie.paniers_entetes
        .filter((p: PanierEnteteApp) => {
          return p.statut === PanierStatut.MARQUE_VALIDE_NS;
        }).count();
      // On compte aussi les évènements qui sont dans la pile temporaire d'envoi
      const nbEvenementsNonEnvoyes: number = await this.dbService.dexie.evenements.count();

      if ((nbPaniersNonEnvoyes > 0 || nbEvenementsNonEnvoyes > 0) && !this.dejaAffiche) {
        this.loggerService.info(`Données non synchronisées : [${nbPaniersNonEnvoyes} paniers, ${nbEvenementsNonEnvoyes} évènements]`);
        // Ici on arrête quoi qu'il arrive, l'utilisateur doit se connecter sur le
        // compte déjà enregistré dans l'application pour envoyer les données
        // et ensuite se connecter sur le nouveau compte
        await this.confirmationService.afficher({
          titre: "Données non synchronisées",
          corps: "Cet appareil contient les données non synchronisée d'un autre utilisateur. Veuillez vous connecter sur le compte qui a été utilisé pour envoyer les données.",
          texteNon: "OK",
          iconeBoutonNon: "fa fa-fw fa-sign-out-alt",
          afficherOui: false,
        });
      } else {
        this.loggerService.info(`Demande de confirmation pour supprimer les données`);
        // Si on annule, la confirmation déclenche une exception pour ne pas executer
        // le code suivant, sinon l'éxécution continue et on supprime la base
        await this.confirmationService.afficher({
          titre: "Données non synchronisées",
          corps: "Cet appareil contient les données d'un autre utilisateur, se connecter supprimera toute les données. Voulez-vous continuer ?",
          texteOui: "Supprimer les données",
          texteNon: "Se déconnecter",
          iconeBoutonNon: "fa fa-fw fa-sign-out-alt",
          iconeBoutonOui: "fa fa-fw fa-sign-in-alt",
        });
      }


      // On réinstancie la base puis on lance la synchronisation
      await this.dbService.vider();
      this.authService.enregistrerToken(reponse.body.id_token);
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.UTILISATEUR, valeur: identifiants.username as never });
      lancerSynchroDonnees();
    } else {
      this.authService.enregistrerToken(reponse.body.id_token);
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.UTILISATEUR, valeur: identifiants.username as never });
      this.router.navigate([""]).then(() => window.location.reload());
      this.parametresCrm = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as ParametreApplication<ParametresUtilisateurCRM> | undefined;
      this.environnementApplicationService.setParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT, this.parametresCrm?.valeur.depotParDefautRef);
    }
    // On enregistre le token dans le service d'authentification et l'utilisateur dans les paramètres d'environnement
    return reponse;
  }

  public async deconnexion() {
    this.authService.enregistrerToken(null);
  }

  public supprimerToken() {
    localStorage.removeItem('token');
  }

}
