<div class="modal-header bg-primary text-light">
  <h4 class="modal-title">Kalico Informatique</h4>
</div>
<div class="modal-body">
  <div class="text-center">
    <span class="flex-grow-1 text-end me-2">
      <img src="./assets/logo/logo_200_par_120.png" class="entete__logo" style="max-height: 80px;">
    </span>
  </div>
  <hr>
  <!-- PC -->
  <div class="row p-2 visible-desktop" style="border-top-style: double;">
    <div class="col-3 d-flex align-items-center">
      <span><strong>Standard</strong></span>
    </div>
    <div class="container col-9">
      <a href="tel:0363664172" style="padding-bottom: 0.6rem;">
        <span class="fas fa-phone"></span>
        <span> 03 63 66 41 72</span>
      </a>
      <br>
      <br>
      <a [href]="mailObjetStandard" style="padding-bottom: 0.6rem;">
        <span class="fas fa-paper-plane"></span>
        <span> contact@kalico-informatique.fr</span>
      </a>
    </div>
  </div>
  <!-- MOBILE -->
  <div class="visible-mobile">
    <div class="text-center">
      <span>
        <strong>Standard</strong>
      </span>
    </div>
    <br>
    <div>
      <a href="tel:0363664172" style="padding-bottom: 0.6rem;">
        <span class="fas fa-phone"></span>
        <span> 03 63 66 41 72</span>
      </a>
      <br>
      <br>
      <a [href]="mailObjetStandard" style="padding-bottom: 0.6rem;">
        <span class="fas fa-paper-plane"></span>
        <span> contact@kalico-informatique.fr</span>
      </a>
    </div>
  </div>
  <hr>
  <!-- PC -->
  <div class="row p-2 visible-desktop" style="border-bottom-style: double; border-top-style: double;">
    <div class="col-3 d-flex align-items-center">
      <span><strong>Assistance</strong></span>
    </div>
    <div class="container col-9">
      <a href="tel:0339310071" style="padding-bottom: 0.6rem;">
        <span class="fas fa-phone"></span>
        <span> 03 39 31 00 71</span>
      </a>
      <br>
      <br>
      <a [href]="mailObjetAssistance" style="padding-bottom: 0.6rem;">
        <span class="fas fa-paper-plane"></span>
        <span> assistance@kalico-informatique.fr</span>
      </a>
    </div>
  </div>
  <!-- MOBILE -->
  <div class="visible-mobile">
    <div class="text-center">
      <span>
        <strong>Assistance</strong>
      </span>
    </div>
    <br>
    <div>
      <a href="tel:0339310071" style="padding-bottom: 0.6rem;">
        <span class="fas fa-phone"></span>
        <span> 03 39 31 00 71</span>
      </a>
      <br>
      <br>
      <a [href]="mailObjetAssistance" style="padding-bottom: 0.6rem;">
        <span class="fas fa-paper-plane"></span>
        <span> assistance@kalico-informatique.fr</span>
      </a>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-6 d-flex align-items-center">
      <p class="text-center">
        <strong>Télé-assistance TeamViewer</strong>
      </p>
    </div>
    <div class="col-6 text-center">
      <a href="https://get.teamviewer.com/kalico" target="_blank">
        <p><img src="./assets/logo/TeamViewer_Logo.png" alt="logo Teamviewer" /></p>
      </a>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div>
    <button class="btn btn-primary espacement" (click)="fermer()">
      <span class="visible-mobile fa fa-fw fa-times"></span>
      <span class="visible-desktop">Fermer</span>
    </button>
  </div>
</div>
