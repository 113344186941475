import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SynchronisationClient } from 'src/app/services/synchronisation/synchronisation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, firstValueFrom } from 'rxjs';
import { ClientService } from 'src/app/services/clients/clients.service';
import { EnvironnementApplicationService } from 'src/app/services/environnement-application/environnement-application.service';
import { UtilitaireGeneralService } from 'src/app/services/utilitaire/utilitaire-general.service';
import { ParametreApplicationId } from 'src/app/services/db/parametre-application';

@Component({
  selector: 'app-recherche-client-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './recherche-client-modal.component.html',
  styleUrls: ['./recherche-client-modal.component.scss']
})
export class RechercheClientModalComponent implements OnInit {
  @Input() rechercheComposant: string = "";
  @Output() public clientChange: EventEmitter<SynchronisationClient | null> = new EventEmitter<SynchronisationClient | null>();


  public _client: SynchronisationClient | null = null;
  @Input() public set client(client: SynchronisationClient | null) {
    this._client = client;
    if (client) this.recherche.setValue(client.nomClient, { emitEvent: false });
    this.clientChange.emit(client);
  }
  public get client(): SynchronisationClient | null {
    return this._client;
  }

  public chargement: boolean = false;
  public recherche: FormControl<string | null> = new FormControl("");
  public rechercheChange = this.recherche.valueChanges.pipe(debounceTime(500));

  public elementsTrouvees: SynchronisationClient[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private clientsService: ClientService,
    private environnementApplicationService: EnvironnementApplicationService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    ) {  }

  ngOnInit(): void {
    this.rechercheChange.subscribe((recherche: string | null) => {
      const valeurRecherche: string | undefined = recherche?.trim();

      this.chargement = true;
      setTimeout(async () => {
        this.elementsTrouvees = [];
        const [clients, commercialActif] = await Promise.all([
          firstValueFrom(this.clientsService.clientsChange),
          firstValueFrom(this.environnementApplicationService.getParametreReactifBrut(ParametreApplicationId.ENVIRONNEMENT_COMMERCIAL)),
        ]);
        this.elementsTrouvees = clients.filter((client: SynchronisationClient) => {
          return (
            (valeurRecherche ? (
              this.utilitaireGeneralService.strContientSouple(client.nomClient, valeurRecherche) ||
              this.utilitaireGeneralService.strContientSouple(client.nomAbrege, valeurRecherche) ||
              this.utilitaireGeneralService.strContientSouple(client.enseigne, valeurRecherche) ||
              this.utilitaireGeneralService.strContientSouple(client.tiers, valeurRecherche)
            ) : true)
            && (
              commercialActif?.valeur ? (
                client.commercial1Ref === commercialActif?.valeur ||
                client.commercial2Ref === commercialActif?.valeur ||
                client.commercial3Ref === commercialActif?.valeur
              ) : true) &&
              !client.dateFin && client.feu !== 3
          )
        }).sort((a, b) => {
          return `${a.titreRef} ${a.nomClient}`.localeCompare(`${b.titreRef} ${b.nomClient}`);
        });

        if (this.elementsTrouvees.length === 1) this.client = this.elementsTrouvees[0];
        else this.client = null;
        this.chargement = false;
      }, 0);
    });
    this.recherche.setValue(this.rechercheComposant);
  }

  public viderRecherche(): void {
    this.recherche.setValue("");
  }

  public fermer(client?: SynchronisationClient): void {
    this.activeModal?.close(client);
  }
}
