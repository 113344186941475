import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadComponent: () => import("./modules/general/home/home.component").then(mod => mod.HomeComponent), },

  { path: 'crm', loadChildren: () => import('./modules/crm/crm.module').then(mod => mod.CrmModule) },

  { path: 'parametres', loadComponent: () => import('./modules/general/parametres/parametres.component').then(mod => mod.ParametresComponent) },

  { path: 'dev', loadChildren: () => import('./modules/dev/dev.module').then(mod => mod.DevModule) },

  { path: '**', loadComponent: () => import("./modules/general/not-found/not-found.component").then(mod => mod.NotFoundComponent) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
