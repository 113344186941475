<div class="modal-header bg-primary text-white">
    <h4 class="modal-title">Recherche de client</h4>
</div>
<div class="modal-body p-0 barre-navigation">
    <div class="input-group p-2">
        <input type="text" class="form-control" [formControl]="recherche" />
        <button class="input-group-text">
            <span class="fa fa-fw fa-search"></span>
        </button>
        <button class="btn btn-dark btn-sm" [disabled]="!recherche.value || chargement" (click)="viderRecherche()">
            <span *ngIf="!chargement" class="fa fa-fw fa-eraser"></span>
            <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin"></span>
        </button>
    </div>

    <div *ngIf="chargement" class="alert alert-info border-none m-0 text-center">
        <span class="fa fa-fw fa-spinner fa-spin"></span>
        Chargement en cours, veuillez patienter..
    </div>

    <ng-container *ngIf="!chargement">
        <div *ngIf="!recherche.value?.trim() && !elementsTrouvees.length"
            class="alert alert-warning border-none m-0 text-center">
            <span class="fa fa-fw fa-exclamation-triangle"></span>
            Aucune recherche n'a été effectuée.
        </div>

        <div *ngIf="recherche.value?.trim() && !elementsTrouvees.length"
            class="alert alert-warning border-none m-0 text-center">
            <span class="fa fa-fw fa-exclamation-triangle"></span>
            Aucun client n'a été trouvé.
        </div>

        <table *ngIf="elementsTrouvees.length" class="table table-striped table-hover m-0">
            <tbody>
                <tr *ngFor="let client of elementsTrouvees" role="button" (click)="fermer(client)">
                    <td>
                        <div>
                            <strong>{{client.nomClient}} {{client.enseigne ? " - " + client.enseigne : ""}} ({{client.tiers}})</strong>
                        </div>
                        <div>
                            {{client.rue}} {{client.ville}} {{client.codePostal}}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="fermer()">Fermer</button>
</div>
