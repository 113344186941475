import { EventEmitter, Injectable } from '@angular/core';
import { ArticleEtendu } from 'src/app/modules/crm/catalogue/liste-articles/liste-articles.component';
import { SynchronisationArticle, SynchronisationClient, SynchronisationExceptionTarifaire } from '../synchronisation/synchronisation';
import { ReplaySubject } from 'rxjs';
import { DbService } from '../db/db.service';
import { liveQuery } from 'dexie';
import { EnvironnementApplicationService } from '../environnement-application/environnement-application.service';
import { SynchronisationInfoDepot } from '../synchronisation-quotidienne/synchronisation-quotidienne';
import { ParametresUtilisateurCRM } from '../crm/crm';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { TarifLigneEtendu } from '../crm/crm-panier/crm-panier';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  public listeArticles: SynchronisationArticle[] = [];
  public listeArticlesMap: Map<string, SynchronisationArticle> = new Map<string, SynchronisationArticle>();
  public listeArticleChange: ReplaySubject<SynchronisationArticle[]> = new ReplaySubject(1);

  private articlesCharges: EventEmitter<void> = new EventEmitter<void>();
  private _articles: ArticleEtendu[] = [];

  private _afficherDetailListeArticle: boolean = true;
  private _dernierArticle: string = "";

  public afficherTarif: boolean = true;
  public _modeTactile: boolean = true;

  // Dans la liste des articles, affiche le panier en cours à la place du détail de l'article
  private _afficherPanierDansListe: boolean = false;

  public get articles(): ArticleEtendu[] {
    return this._articles;
  }

  public set articles(value: ArticleEtendu[]) {
    this._articles = value;
    this.articlesCharges.emit();
  }

  public get afficherPanierDansListe(): boolean {
    return this._afficherPanierDansListe;
  }
  public set afficherPanierDansListe(value: boolean) {
    this._afficherPanierDansListe = value;
  }

  public get afficherTarifDansListe(): boolean {
    return this.afficherTarif;
  }

  public set afficherTarifDansListe(afficher: boolean) {
    this.afficherTarif = afficher;
  }

  public get afficherDetailListeArticle(): boolean {
    return this._afficherDetailListeArticle;
  }

  public set afficherDetailListeArticle(afficherDetail: boolean) {
    this._afficherDetailListeArticle = afficherDetail;
  }

  public get dernierArticle(): string {
    return this._dernierArticle;
  }

  public set dernierArticle(dernierArticle: string) {
    this._dernierArticle = dernierArticle;
  }

  public get modeTactile(): boolean {
    return this._modeTactile;
  }

  public set modeTactile(modeTactile: boolean) {
    this._modeTactile = modeTactile;
  }

  constructor(
    private dbService: DbService,
    private environnementApplicationService: EnvironnementApplicationService,
  ) {
    this.init();
  }

  private async init() {
    const afficherTarifs = localStorage.getItem('afficherTarifs');
    this.afficherTarif = (afficherTarifs && afficherTarifs === 'non') ? false : true;
    liveQuery(() => this.dbService.dexie.synchronisation_articles.toArray())
      .subscribe((articles: SynchronisationArticle[]) => {
        this.listeArticles = articles;
        const mapArticles: Map<string, SynchronisationArticle> = new Map<string, SynchronisationArticle>();
        for (const article of articles) {
          mapArticles.set(article.articleRef, article);
        }
        this.listeArticlesMap = mapArticles;
        this.listeArticleChange.next(articles);
      });
      const paramTactile = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.MODE_TACTILE) as ParametreApplication<boolean> | undefined;
      this._modeTactile = paramTactile?.valeur ?? false;
  }

  public trouverCoefficientCorrespondant(article: ArticleEtendu): number {
    let coefUniteVendu = 1;
    switch (article.panierUnite) {
      case article.uniteAchat:
        coefUniteVendu = article.uniteAchatCoefficient ?? 1;
        break;
      case article.uniteReference:
        coefUniteVendu = article.uniteReferenceCoefficient ?? 1;
        break;
      case article.uniteStockage:
        coefUniteVendu = article.uniteStockageCoefficient ?? 1;
        break;
      case article.uniteVente:
        coefUniteVendu = article.uniteVenteCoefficient ?? 1;
        break;
      case article.uniteDivers1:
        coefUniteVendu = article.uniteDivers1Coefficient ?? 1;
        break;
      case article.uniteDivers2:
        coefUniteVendu = article.uniteDivers2Coefficient ?? 1;
        break;
      case article.unitePalette:
        coefUniteVendu = article.unitePaletteCoefficient ?? 1;
        break;
      default:
        coefUniteVendu = 1;
    }
    return coefUniteVendu;
  }

  public trouverCoefficientCorrespondantPanierLigne(article: ArticleEtendu | SynchronisationArticle | undefined, unite: string): number {
    if(article === undefined) return 1;
    let coefUniteVendu = 1;
    if(article) {
      switch (unite) {
        case article.uniteAchat:
          coefUniteVendu = article.uniteAchatCoefficient ?? 1;
          break;
        case article.uniteReference:
          coefUniteVendu = article.uniteReferenceCoefficient ?? 1;
          break;
        case article.uniteStockage:
          coefUniteVendu = article.uniteStockageCoefficient ?? 1;
          break;
        case article.uniteVente:
          coefUniteVendu = article.uniteVenteCoefficient ?? 1;
          break;
        case article.uniteDivers1:
          coefUniteVendu = article.uniteDivers1Coefficient ?? 1;
          break;
        case article.uniteDivers2:
          coefUniteVendu = article.uniteDivers2Coefficient ?? 1;
          break;
        case article.unitePalette:
          coefUniteVendu = article.unitePaletteCoefficient ?? 1;
          break;
        default:
          coefUniteVendu = 1;
      }
    }
    return coefUniteVendu;
  }


  public async obtenirCodeTarifAvecArticleEtClientActif(article:ArticleEtendu | SynchronisationArticle | undefined, client: SynchronisationClient | undefined, filtreCodeTarif: string | undefined): Promise<TarifLigneEtendu> {
    let codeTarifApplique: string = "";
    let exceptionTarifaire: SynchronisationExceptionTarifaire | undefined;
    exceptionTarifaire = undefined;

    const tarifRetour: TarifLigneEtendu = new TarifLigneEtendu();
    tarifRetour.tarif = undefined;
    tarifRetour.exceptionTarifairePresente = false;

    if(client && article) {
      exceptionTarifaire = await this.dbService.dexie.synchronisation_exceptions_tarifaires
      .where('[famille+tiers]')
      .equals([article.familleStat1Ref,client.tiers])
      .first();
      if(!exceptionTarifaire) {
        exceptionTarifaire = await this.dbService.dexie.synchronisation_exceptions_tarifaires
        .where('[famille+tiers]')
        .equals([article.familleStat1N1Ref,client.tiers])
        .first();
      }
      if(exceptionTarifaire) {
        codeTarifApplique = exceptionTarifaire.codeTarif;
        tarifRetour.exceptionTarifairePresente = true;
      }
      else if(client) {
        codeTarifApplique = client.codeTarif;
      }
    }
    // Sinon on prend le code tarif du dépot sélectionné, sinon le dépot par défault
    else {
      if(filtreCodeTarif) {
        codeTarifApplique = filtreCodeTarif;
      }
    }

    if(codeTarifApplique) {
      let tarifs = await this.dbService.dexie.synchronisation_tarifs
                  .where('[codeTarif+articleRef]')
                  .equals([codeTarifApplique, article?.articleRef ?? ""])
                  .toArray();

      if(!tarifs.length) {
        const codeTarifDepotApplique = await this.recupererCodeTarifDepot();
        if(codeTarifDepotApplique) {
          tarifs = await this.dbService.dexie.synchronisation_tarifs
                    .where('[codeTarif+articleRef]')
                    .equals([codeTarifDepotApplique, article?.articleRef ?? ""])
                    .toArray();
        }
      }
      if (tarifs) {
        if (tarifs.length === 1) {
          //si un seul tarif alors on prend le premier
          tarifRetour.tarif =  tarifs[0];

          return  tarifRetour  ;
        }
        else if (tarifs.length > 1) {
          // Filtrer les tarifs avec dateEffet inférieure ou égale à aujourd'hui
          const tarifsFiltres = tarifs.filter(t => new Date(t.dateEffet) <= new Date()).sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

          // Si des tarifs correspondent aux critères, trier les tarifs filtrés par dateEffet en ordre décroissant
          if (tarifsFiltres.length > 0) {
            tarifRetour.tarif = tarifsFiltres.find(t => !t.dateFinValidite || new Date(t.dateFinValidite) >= new Date());
            return  tarifRetour ;
          }
        }
      }
      return tarifRetour;
    }
    else {
      return tarifRetour;
    }
  }
  // public async obtenirCodeTarifAvecArticleEtClient(article:ArticleEtendu | SynchronisationArticle | undefined, client: SynchronisationClient | undefined, filtreCodeTarif: string | undefined): Promise<TarifSynchronise[] | undefined> {
  //   let codeTarifApplique: string = "";
  //   let exceptionTarifaire: SynchronisationExceptionTarifaire | undefined;
  //   exceptionTarifaire = undefined;

  //   if(client && article) {
  //     exceptionTarifaire = await this.dbService.dexie.synchronisation_exceptions_tarifaires
  //     .where('[famille+tiers]')
  //     .equals([article.familleStat1Ref,client.tiers])
  //     .first();
  //     if(!exceptionTarifaire) {
  //       exceptionTarifaire = await this.dbService.dexie.synchronisation_exceptions_tarifaires
  //       .where('[famille+tiers]')
  //       .equals([article.familleStat1N1Ref,client.tiers])
  //       .first();
  //     }
  //     if(exceptionTarifaire) {
  //       codeTarifApplique = exceptionTarifaire.codeTarif;
  //     }
  //     else if(client) {
  //       codeTarifApplique = client.codeTarif;
  //     }
  //   }
  //   // Sinon on prend le code tarif du dépot sélectionné, sinon le dépot par défault
  //   else {
  //     if(filtreCodeTarif) {
  //       codeTarifApplique = filtreCodeTarif;
  //     }
  //   }

  //   if(codeTarifApplique) {
  //     let tarifs = await this.dbService.dexie.synchronisation_tarifs
  //                 .where('[codeTarif+articleRef]')
  //                 .equals([codeTarifApplique, article?.articleRef ?? ""])
  //                 .toArray();

  //     if(!tarifs.length) {
  //       const codeTarifDepotApplique = await this.recupererCodeTarifDepot();
  //       if(codeTarifDepotApplique) {
  //         tarifs = await this.dbService.dexie.synchronisation_tarifs
  //                   .where('[codeTarif+articleRef]')
  //                   .equals([codeTarifDepotApplique, article?.articleRef ?? ""])
  //                   .toArray();
  //       }
  //     }
  //     return tarifs;
  //   }
  //   else {
  //     return undefined;
  //   }
  // }

  private async recupererCodeTarifDepot(): Promise<string> {
    let codeTarifDepotApplique = "";
    const depotActif: string = this.environnementApplicationService.getParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT) ?? "";
    const depotParDefaut: string | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM)?.depotParDefautRef;
    const depot: SynchronisationInfoDepot | undefined = await this.dbService.dexie.synchronisation_info_depots.get(depotActif || depotParDefaut || "");
    codeTarifDepotApplique = depot?.codeTarif ? depot?.codeTarif : "";
    return codeTarifDepotApplique;
  }
}
