<div class="input-group">
    <button type="button" class="btn btn-primary btn-sm" (click)="ouvrirSelectionClient()" title="Rechercher un client">
        <span class="fa fa-fw fa-user" [class.text-warning]="!client"></span>
    </button>
    <input type="text" class="form-control" [placeholder]="placeholder" [formControl]="recherche">
    <button type="button" class="btn btn-dark btn-sm" [disabled]="!recherche.value || chargement"
        (click)="viderRecherche()" title="Effacer le client">
        <span *ngIf="!chargement" class="fa fa-fw fa-eraser"></span>
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin"></span>
    </button>
</div>
