import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { toucheClavierDirective } from 'src/app/tools/directives/touches-clavier/touches-clavier.directive';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aide-modal',
  standalone: true,
  imports: [CommonModule, toucheClavierDirective],
  templateUrl: './aide-modal.component.html',
  styleUrls: ['./aide-modal.component.scss']
})
export class AideModalComponent implements OnInit {

  public provenance: string = "";
  public globalOuvert: boolean = false;
  public catalogueOuvert: boolean = false;
  public clientsOuvert: boolean = false;
  public paniersOuvert: boolean = false;

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    const url = this.router.url;

    if (url.includes('catalogue') || url.includes('produits')) {
      this.provenance = "catalogue";
      this.catalogueOuvert = true; // Ouvrir l'onglet catalogue
    }
    else if (url.includes('clients')) {
      this.provenance = "clients";
      this.clientsOuvert = true; // Ouvrir l'onglet clients
    }
    else if (url.includes('paniers')) {
      this.provenance = "paniers";
      this.paniersOuvert = true; // Ouvrir l'onglet paniers
    }
    else {
      this.provenance = "accueil";
      this.globalOuvert = true; // Ouvrir l'onglet accueil
    }
  }

  toggleSection(section: string) {
    if (section === 'accueil') {
      this.globalOuvert = !this.globalOuvert;
      this.catalogueOuvert = false;
      this.clientsOuvert = false;
      this.paniersOuvert = false;
    } else if (section === 'catalogue') {
      this.catalogueOuvert = !this.catalogueOuvert;
      this.globalOuvert = false;
      this.clientsOuvert = false;
      this.paniersOuvert = false;
    } else if (section === 'clients') {
      this.clientsOuvert = !this.clientsOuvert;
      this.globalOuvert = false;
      this.catalogueOuvert = false;
      this.paniersOuvert = false;
    } else if (section === 'paniers') {
      this.paniersOuvert = !this.paniersOuvert;
      this.globalOuvert = false;
      this.catalogueOuvert = false;
      this.clientsOuvert = false;
    }
  }


  public fermer(): void {
    this.activeModal.dismiss();
  }

}
