<div appToucheClavier (toucheF1)="fermer()" class="modal-header bg-primary text-light">
  <h4 class="modal-title">Aide</h4>
</div>

<div class="modal-body">
  <div class="accordion" id="accordionAide">

    <!-- Section 1: Accueil -->
    <div class="accordion-item">
      <div class="accordion-header cliquable" (click)="toggleSection('accueil')">
        <h5 [ngClass]="{'enteteSelectionnee': globalOuvert}" class="d-flex justify-content-between p-2">
          <span>Global</span>
          <span *ngIf="globalOuvert" class="fa fa-fw fa-chevron-up"></span>
          <span *ngIf="!globalOuvert" class="fa fa-fw fa-chevron-down"></span>
        </h5>
      </div>
      <div *ngIf="globalOuvert" class="accordion-collapse" [ngClass]="{'bg-light': globalOuvert}">
        <div class="accordion-body">
          <div class="raccourci mb-2">
            <span class="touche me-2">F1</span>
            <span class="explication">Ouvrir / Fermer aide</span>
          </div>
          <div class="raccourci">
            <span class="touche me-2">F2</span>
            <span class="explication">Flouter les informations</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 2: Catalogue -->
    <div class="accordion-item">
      <div class="accordion-header cliquable" (click)="toggleSection('catalogue')">
        <h5 [ngClass]="{'enteteSelectionnee': catalogueOuvert}" class="d-flex justify-content-between p-2">
          <span>Catalogue</span>
          <span *ngIf="catalogueOuvert" class="fa fa-fw fa-chevron-up"></span>
          <span *ngIf="!catalogueOuvert" class="fa fa-fw fa-chevron-down"></span>
        </h5>
      </div>
      <div *ngIf="catalogueOuvert" class="accordion-collapse" [ngClass]="{'bg-light': catalogueOuvert}">
        <div class="accordion-body">
          <div class="raccourci mb-2">
            <span class="touche me-2">F7</span>
            <span class="explication">Créer un nouveau panier</span>
          </div>
          <div class="raccourci mb-2">
            <span class="touche me-2">F8</span>
            <span class="explication">Ouvrir / Fermer l'historique de l'article</span>
          </div>
          <div class="raccourci">
            <span class="touche me-2">F9</span>
            <span class="explication">Ouvrir / Fermer le détail</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 4: Paniers -->
    <div class="accordion-item">
      <div class="accordion-header cliquable" (click)="toggleSection('paniers')">
        <h5 [ngClass]="{'enteteSelectionnee': paniersOuvert}" class="d-flex justify-content-between p-2">
          <span>Paniers</span>
          <span *ngIf="paniersOuvert" class="fa fa-fw fa-chevron-up"></span>
          <span *ngIf="!paniersOuvert" class="fa fa-fw fa-chevron-down"></span>
        </h5>
      </div>
      <div *ngIf="paniersOuvert" class="accordion-collapse" [ngClass]="{'bg-light': paniersOuvert}">
        <div class="accordion-body">
          <div class="raccourci mb-2">
            <span class="touche">Ctrl</span>
            <span class="mx-1">+</span>
            <span class="touche me-2">Suppr.</span>
            <span class="explication">Supprimer le panier</span>
          </div>
          <div class="raccourci">
            <span class="touche">Ctrl</span>
            <span class="mx-1">+</span>
            <span class="touche me-2">
              <i class="fa fa-arrow-turn-down" style="transform: rotate(90deg);"></i>
            </span>
            <span class="explication">Valider le panier</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="fermer()">OK</button>
</div>
