<div class="modal-header bg-primary text-light">
  <h4 class="modal-title">Sélection d'un article gratuit</h4>
</div>

<div class="modal-body px-0">
  <div class="container">
    <div class="row">
      <div *ngFor="let article of articlesEtendus" class="col-12 mb-2">
        <div class="card shadow-sm" (click)="selectionnerArticle(article)"
          [ngClass]="{'bg-grisclair': article.articleRef === articleRefGratuitChoisi}">
          <div class="card-body d-flex justify-content-between align-items-center p-2">
            <div>
              <h5 class="card-title mb-1">{{ article.designation }}</h5>
              <p class="card-text text-muted">{{ article.articleRef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ngClass="mx-2" *ngIf="articlesEtendus.length === 0"
    class="alert alert-warning text-center">
    <span class="fa fa-fw fa-exclamation-triangle me-1"></span>
    <span>Aucun article n'est disponible à la sélection.</span>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="fermer()">Fermer</button>
</div>
